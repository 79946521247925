import { useCallback, useState } from 'react'

import { RootStackScreenComponentProps } from 'navigation/types'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard } from 'react-native'
import {
  useRegisterMutation,
  RegisterPayload,
  RegisterData
} from 'src/integration/resources/register'

type RegisterScreenProps = RootStackScreenComponentProps<
  'RegisterName' | 'RegisterEmail' | 'RegisterPassword' | 'RegisterSuccess'
>

export const useRegisterScreen = ({ navigation, route }: RegisterScreenProps) => {
  const { control, handleSubmit, watch, getValues } = useForm<RegisterPayload>({})

  const [error, setError] = useState<string>()

  const { isLoading, mutate } = useRegisterMutation()

  const navigateToEmail = handleSubmit(
    useCallback<SubmitHandler<RegisterPayload>>(
      (formData) => {
        Keyboard.dismiss()

        navigation.navigate('RegisterEmail', {
          name: formData.name
        })
      },
      [navigation]
    )
  )

  const navigateToPassword = handleSubmit(
    useCallback<SubmitHandler<RegisterPayload>>(
      (formData) => {
        Keyboard.dismiss()

        navigation.navigate('RegisterPassword', {
          name: route.params?.name,
          email: formData.email
        })
      },
      [navigation, route.params]
    )
  )

  const navigateToSuccess = handleSubmit(
    useCallback<SubmitHandler<RegisterPayload>>(() => {
      Keyboard.dismiss()

      navigation.reset({
        index: 0,
        routes: [{ name: 'Entry' }]
      })
    }, [navigation])
  )

  const submit = handleSubmit(
    useCallback<SubmitHandler<RegisterPayload>>(
      (formData) => {
        Keyboard.dismiss()

        const data: RegisterData = {
          name: route.params?.name as string,
          email: route.params?.email as string,
          password: formData.password as string
        }

        mutate(data, {
          onError: () => setError('Você inseriu informações incorretas.'),
          onSuccess: () => navigation.navigate('RegisterSuccess')
        })
      },
      [mutate, navigation, route.params]
    )
  )

  return {
    error,
    control,
    watch,
    getValues,
    isLoading,
    navigateToEmail,
    navigateToPassword,
    navigateToSuccess,
    submit
  }
}

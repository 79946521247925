import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'

import { Icons } from 'atoms'
import { GetAllRatingParams, Rating, useGetAllRatingQuery } from 'integration/resources/rating'
import { RatingItem } from 'molecules'
import {
  Box,
  Button,
  FlatList,
  HStack,
  Select,
  Spinner,
  Text,
  VStack,
  useBreakpointValue
} from 'native-base'

export type RatingListProps = {
  identifier: string
}

export const RatingList: FunctionComponent<RatingListProps> = ({ identifier }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [page, setPage] = useState(1)

  const [ordering, setOrdering] = useState<GetAllRatingParams['ordering']>('')

  const [ratings, setRatings] = useState<Rating[]>([])

  const { data, refetch, isRefetching } = useGetAllRatingQuery({
    identifier,
    page,
    page_size: 5,
    ordering
  })

  const newRatings = useMemo(() => data?.data.results ?? [], [data])

  const next = useMemo(() => data?.data.next ?? null, [data])

  useEffect(() => {
    if (newRatings.length > 0 && !isRefetching) {
      setRatings((prevRatings) => {
        return [...prevRatings, ...newRatings]
      })
    }
  }, [newRatings, isRefetching])

  useEffect(() => {
    const newPage = next !== null ? Number(next.match(/(?:[?&])page=([^&]+)/)?.[1]) : 0

    if (newPage) setPage(newPage)
  }, [next])

  const onNext = useCallback(() => {
    refetch()
  }, [refetch])

  const onChangeOrdering = useCallback((ordering: GetAllRatingParams['ordering']) => {
    setPage(1)

    setRatings([])

    setOrdering(ordering)
  }, [])

  useEffect(() => {
    refetch()
  }, [ordering, refetch])

  const renderRatings = () => (
    <FlatList
      data={ratings}
      keyExtractor={(item) => `${item.id}`}
      w="full"
      renderItem={({ item }) => {
        return (
          <Box mb={isMobile ? 6 : 12}>
            <RatingItem {...item} />
          </Box>
        )
      }}
      showsVerticalScrollIndicator={false}
    />
  )

  return (
    <>
      <VStack space={6} w="full" my={6}>
        <HStack justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
          <Select
            selectedValue={ordering}
            h={10}
            w="246px"
            bg="white"
            borderColor="red.500"
            fontSize="16px"
            fontWeight={600}
            lineHeight="21px"
            color="red.500"
            dropdownIcon={
              <Box mr={4}>
                <Icons.Arrow direction="down" color="red.500" size={8} />
              </Box>
            }
            onValueChange={(itemValue) =>
              onChangeOrdering(itemValue as GetAllRatingParams['ordering'])
            }>
            <Select.Item label="Mais recentes" value="" />
            <Select.Item label="Classificação mais alta" value="quality_desc" />
            <Select.Item label="Mais relevantes" value="average_score_desc" />
          </Select>
        </HStack>
      </VStack>

      {renderRatings()}

      {isRefetching && (
        <HStack w="full" justifyContent="center" alignItems="center">
          <Spinner size="lg" />
        </HStack>
      )}

      <VStack space={6} w="full">
        <HStack justifyContent={isMobile ? 'flex-start' : 'flex-end'}>
          <Button size="sm" variant="outline" onPress={onNext} isDisabled={next === null}>
            <Text
              color="red.500"
              fontFamily="heading"
              fontSize="16px"
              lineHeight="20px"
              fontWeight={600}>
              Ver mais avaliações
            </Text>
          </Button>
        </HStack>
      </VStack>
    </>
  )
}

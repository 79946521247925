import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'
import { theme } from 'src/theme'

type MaisVezesIconProps = IIconProps & {
  useDefaultColor?: boolean
}

export const MaisVezesIcon: FunctionComponent<MaisVezesIconProps> = ({
  useDefaultColor,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3477 0C15.9004 0 16.3477 0.447754 16.3477 1V6.65234C16.3477 6.91357 16.4482 7.15137 16.6123 7.32959C16.7949 7.52783 17.0566 7.65234 17.3477 7.65234H23C23.5527 7.65234 24 8.1001 24 8.65234V15C24 15.5522 23.5527 16 23 16H17.3477C16.7959 16 16.3477 16.4478 16.3477 17V23C16.3477 23.5522 15.9004 24 15.3477 24H9C8.44727 24 8 23.5522 8 23V17C8 16.4478 7.55273 16 7 16H1C0.447266 16 0 15.5522 0 15V8.65234C0 8.22314 0.270508 7.85693 0.650391 7.71484C0.758789 7.67432 0.876953 7.65234 1 7.65234H7C7.55273 7.65234 8 7.20459 8 6.65234V1C8 0.447754 8.44727 0 9 0H15.3477ZM13.293 8.70703C13.6836 8.31641 14.3164 8.31641 14.707 8.70703L15.4141 9.41406C15.8047 9.80469 15.8047 10.438 15.4141 10.8286L14.2422 12L15.0713 12.8286C15.4619 13.2188 15.4619 13.8521 15.0713 14.2427L14.3643 14.9497C13.9736 15.3403 13.3398 15.3403 12.9502 14.9497L12.1211 14.1211L11.1719 15.0713C10.7812 15.4614 10.1475 15.4614 9.75781 15.0713L9.0498 14.3638C8.66016 13.9736 8.66016 13.3403 9.0498 12.9497L10 12L8.70703 10.707C8.31641 10.3164 8.31641 9.68359 8.70703 9.29297L9.41406 8.58594C9.80469 8.19531 10.4375 8.19531 10.8281 8.58594L12.1211 9.87891L13.293 8.70703Z"
      fill={useDefaultColor ? theme.colors.red[500] : 'currentColor'}
    />
  </Icon>
)

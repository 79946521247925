import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import * as requests from './requests'
import { RegisterPayload } from './types'
import { ResponseError } from '../../types'

export const useRegisterMutation = () =>
  useMutation<AxiosResponse<RegisterPayload>, AxiosError<ResponseError>, RegisterPayload>(
    requests.register
  )

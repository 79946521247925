import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CloseIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6485 2.04853C24.1172 1.5799 24.1172 0.820101 23.6485 0.351472C23.1799 -0.117157 22.4201 -0.117157 21.9515 0.351472L12 10.3029L2.04853 0.351472C1.5799 -0.117157 0.820101 -0.117157 0.351472 0.351472C-0.117157 0.820101 -0.117157 1.5799 0.351472 2.04853L10.3029 12L0.351472 21.9515C-0.117157 22.4201 -0.117157 23.1799 0.351472 23.6485C0.820101 24.1172 1.5799 24.1172 2.04853 23.6485L12 13.6971L21.9515 23.6485C22.4201 24.1172 23.1799 24.1172 23.6485 23.6485C24.1172 23.1799 24.1172 22.4201 23.6485 21.9515L13.6971 12L23.6485 2.04853Z"
      fill="currentColor"
    />
  </Icon>
)

import AsyncStorage from '@react-native-async-storage/async-storage'
import * as SecureStore from 'expo-secure-store'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { Platform } from 'react-native'
import client from 'src/integration/client'
import { AuthData } from 'src/integration/resources/auth'

type Storage = {
  getItem: (key: string) => Promise<string | null>
  removeItem: (key: string) => Promise<void>
  setItem: (key: string, newValue: string) => Promise<void>
}

export const AuthKey = 'auth'

export const storage = Platform.select<Storage>({
  native: {
    getItem: (key) => SecureStore.getItemAsync(key),
    removeItem: (key) => SecureStore.deleteItemAsync(key),
    setItem: (key, value) => SecureStore.setItemAsync(key, JSON.stringify(value), {})
  },
  web: {
    getItem: (key) => AsyncStorage.getItem(key),
    removeItem: (key) => AsyncStorage.removeItem(key),
    setItem: (key, value) => AsyncStorage.setItem(key, JSON.stringify(value))
  }
}) as Storage

const authAtom = atomWithStorage<AuthData | null | undefined>(AuthKey, undefined, {
  delayInit: true,
  removeItem: storage.removeItem,
  getItem: async (key) => {
    const item = await storage.getItem(key)

    const itemParsed = item ? (JSON.parse(JSON.parse(item)) as AuthData) : null

    setHeaderAuthorization(itemParsed)

    return itemParsed
  },
  setItem: (key, value) => {
    setHeaderAuthorization(value)

    if (value) {
      return storage.setItem(key, JSON.stringify(value))
    }

    return storage.removeItem(key)
  }
})

const setHeaderAuthorization = (auth: AuthData | null | undefined) => {
  if (auth) {
    client.defaults.headers.common['Authorization'] = `Bearer ${auth.access}`

    return
  }

  delete client.defaults.headers.common['Authorization']
}

export const useAuthAtom = () => useAtom(authAtom)

export const useAuthAtomValue = () => useAtomValue(authAtom)

export const useSetAuthAtom = () => useSetAtom(authAtom)

import { GetAllOrdersParams, ChangeOrder, OrdersData } from './types'
import client from '../../client'

export const getDeliveredOrders = async (params: GetAllOrdersParams) => {
  const response = await client.get<OrdersData>(`v1/order-history/`, {
    params: {
      ...params
    }
  })

  return response
}

export const getNotDeliveredOrders = () =>
  client.get<OrdersData>('v1/order-history/?finished=false&page_size=1000')

export const pathChangeOrder = ({ id, status }: ChangeOrder) =>
  client.patch<ChangeOrder>(`v1/change-order/${id}/`, { status })

import { yupResolver } from '@hookform/resolvers/yup'
import { useSendEmailRecoveryPassword } from 'integration/resources/users'
import { useBreakpointValue, useToast } from 'native-base'
import { useForm } from 'react-hook-form'
import validationFormFields from 'src/common/validationFormFields'
import * as Yup from 'yup'

import { TFormData, UseRecoveryPasswordEmailScreen } from './RecoveryPasswordEmailScreen.types'

const schema = Yup.object()
  .shape({
    email: Yup.string()
      .email(validationFormFields.email.invalid)
      .required(validationFormFields.required)
  })
  .required()

export const useRecoveryPasswordEmailScreen: UseRecoveryPasswordEmailScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { mutateAsync } = useSendEmailRecoveryPassword()

  const toast = useToast()

  const { control, handleSubmit } = useForm<TFormData>({
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  })

  const handleSubmitForm = handleSubmit(async ({ email }) => {
    try {
      await mutateAsync({ email })

      navigation.navigate('RecoveryPasswordSentEmailSuccesfully', { email })
    } catch (error) {
      toast.show({
        title: 'Atenção',
        description: 'Não foi possível enviar o e-mail, tente novamente mais tarde!',
        _web: {
          background: 'red.500',
          borderRadius: 10,
          textAlign: 'center',
          fontSize: '20px',
          lineHeight: '22px'
        }
      })
    }
  })

  return {
    handleSubmitForm,
    isMobile,
    control
  }
}

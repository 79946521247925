import { useLinkTo } from '@react-navigation/native'

import { UseNotificationsButton } from './NotificationsButton.types'
import { useGetNotificationsCountQuery } from '../../../integration/resources/notifications'

export const useNotificationsButton: UseNotificationsButton = () => {
  const { data, isLoading } = useGetNotificationsCountQuery()

  const linkTo = useLinkTo()

  const handleOpenNotifications = () => linkTo('/notificacoes')

  return {
    notificationsCount: data?.data,
    isLoading,
    handleOpenNotifications
  }
}

import { FunctionComponent } from 'react'

import Svg, { Path, Defs } from 'react-native-svg'
import { resize } from 'src/utils'

type Props = {
  size?: number
}

export const StoreGradientLogo: FunctionComponent<Props> = ({ size = 113 }) => {
  const { height, width } = resize({ height: 32, size, width: 113 })

  return (
    <Svg {...{ viewBox: '0 0 113 32', width, height }}>
      <Path
        d="M42.9413 21.9524H46.7072V22.4095H42.9413V21.9524ZM43.0009 25.4799H42.4941V18.5243H47.1445V18.9814H43.0009V25.4799Z"
        fill="#777E90"
      />
      <Path d="M49.5497 25.4799V18.5243H50.0565V25.4799H49.5497Z" fill="#777E90" />
      <Path
        d="M53.2672 25.4799V18.5243H53.6945L58.6528 24.8539H58.4243V18.5243H58.9311V25.4799H58.5137L53.5454 19.1503H53.774V25.4799H53.2672Z"
        fill="#777E90"
      />
      <Path
        d="M60.9845 25.4799L64.1841 18.5243H64.6908L67.8904 25.4799H67.3439L64.3232 18.8423H64.5418L61.531 25.4799H60.9845ZM62.157 23.5224L62.3259 23.0951H66.4893L66.6583 23.5224H62.157Z"
        fill="#777E90"
      />
      <Path
        d="M69.9401 25.4799V18.5243H70.3674L75.3257 24.8539H75.0972V18.5243H75.604V25.4799H75.1866L70.2183 19.1503H70.4469V25.4799H69.9401Z"
        fill="#777E90"
      />
      <Path
        d="M81.7612 25.5296C81.2445 25.5296 80.7642 25.4434 80.3204 25.2712C79.8832 25.0924 79.5023 24.8439 79.1777 24.526C78.8531 24.208 78.6014 23.837 78.4225 23.4131C78.2437 22.9825 78.1542 22.5122 78.1542 22.0021C78.1542 21.492 78.2437 21.025 78.4225 20.601C78.6014 20.1704 78.8531 19.7962 79.1777 19.4782C79.5089 19.1602 79.8931 18.9151 80.3303 18.7429C80.7675 18.564 81.2445 18.4746 81.7612 18.4746C82.2514 18.4746 82.7085 18.5541 83.1324 18.7131C83.563 18.8721 83.9307 19.1139 84.2354 19.4385L83.9174 19.7664C83.6193 19.4749 83.2881 19.2662 82.9238 19.1404C82.5661 19.0079 82.1852 18.9416 81.7811 18.9416C81.3372 18.9416 80.9232 19.0178 80.539 19.1702C80.1614 19.3225 79.8302 19.5378 79.5453 19.816C79.2671 20.0943 79.0485 20.4189 78.8895 20.7898C78.7372 21.1608 78.661 21.5649 78.661 22.0021C78.661 22.4393 78.7372 22.8434 78.8895 23.2143C79.0485 23.5853 79.2671 23.9099 79.5453 24.1881C79.8302 24.4664 80.1614 24.6816 80.539 24.834C80.9232 24.9864 81.3372 25.0625 81.7811 25.0625C82.1852 25.0625 82.5661 24.9996 82.9238 24.8738C83.2881 24.7413 83.6193 24.526 83.9174 24.2279L84.2354 24.5558C83.9307 24.8804 83.563 25.1255 83.1324 25.2911C82.7085 25.4501 82.2514 25.5296 81.7612 25.5296Z"
        fill="#777E90"
      />
      <Path
        d="M87.1669 21.7239H90.9329V22.171H87.1669V21.7239ZM87.2265 25.0228H91.5191V25.4799H86.7198V18.5243H91.3701V18.9814H87.2265V25.0228Z"
        fill="#777E90"
      />
      <Path d="M94.1538 25.4799V18.5243H94.6605V25.4799H94.1538Z" fill="#777E90" />
      <Path
        d="M97.8713 25.4799V18.5243H100.365C100.948 18.5243 101.448 18.617 101.866 18.8025C102.283 18.988 102.604 19.2563 102.83 19.6074C103.055 19.9518 103.167 20.3725 103.167 20.8693C103.167 21.3529 103.055 21.7702 102.83 22.1213C102.604 22.4658 102.283 22.7341 101.866 22.9262C101.448 23.1117 100.948 23.2044 100.365 23.2044H98.1495L98.378 22.9659V25.4799H97.8713ZM102.7 25.4799L100.892 22.956H101.468L103.277 25.4799H102.7ZM98.378 22.9957L98.1495 22.7573H100.365C101.114 22.7573 101.68 22.5917 102.065 22.2604C102.455 21.9292 102.651 21.4655 102.651 20.8693C102.651 20.2665 102.455 19.8028 102.065 19.4782C101.68 19.147 101.114 18.9814 100.365 18.9814H98.1495L98.378 18.733V22.9957Z"
        fill="#777E90"
      />
      <Path
        d="M104.647 25.4799L107.847 18.5243H108.354L111.553 25.4799H111.007L107.986 18.8423H108.205L105.194 25.4799H104.647ZM105.82 23.5224L105.989 23.0951H110.152L110.321 23.5224H105.82Z"
        fill="#777E90"
      />
      <Path
        d="M32.5926 16C32.5926 24.8366 25.2965 32 16.2963 32C7.2961 32 0 24.8366 0 16C0 7.16344 7.2961 0 16.2963 0C25.2965 0 32.5926 7.16344 32.5926 16Z"
        fill="url(#paint0_linear_15865_7714)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6737 10.2362H11.431C9.75223 10.2362 8.35977 11.5355 8.24367 13.2102L7.71191 20.8805C7.58385 22.7276 9.04772 24.2965 10.8993 24.2965H21.6941C23.5457 24.2965 25.0095 22.7276 24.8815 20.8805L24.3497 13.2102C24.2336 11.5355 22.8412 10.2362 21.1623 10.2362H19.9633V10.8991C19.9633 11.5251 19.4518 12.0326 18.8208 12.0326C18.1899 12.0326 17.6784 11.5251 17.6784 10.8991V10.2362H14.9586V10.8991C14.9586 11.5251 14.4471 12.0326 13.8162 12.0326C13.1852 12.0326 12.6737 11.5251 12.6737 10.8991V10.2362ZM18.8154 16.9319C18.7986 16.5713 18.6881 16.213 18.4863 15.8904L16.6938 13.0351C16.5569 12.817 16.4631 12.5833 16.4103 12.3452L16.3358 12.4654C15.8913 13.1708 15.8913 14.0432 16.3358 14.7487L17.7678 17.032C18.2123 17.7397 18.2123 18.6099 17.7678 19.3153L17.6934 19.4355C17.6405 19.1952 17.5468 18.9637 17.4098 18.7456L15.2594 15.3206C15.1224 15.1025 15.0287 14.8689 14.9758 14.6307L14.9014 14.7509C14.4592 15.4542 14.4568 16.3221 14.8965 17.0276L16.3334 19.3176C16.7779 20.0253 16.7779 20.8954 16.3334 21.6009L16.2589 21.7211C16.206 21.4807 16.1123 21.2493 15.9754 21.0312L14.1829 18.1759C13.9427 17.7931 13.8321 17.3614 13.8538 16.9341C11.9388 17.3948 10.6148 18.374 10.6148 19.5067C10.6148 21.0824 13.1762 22.362 16.3358 22.362C19.4954 22.362 22.0568 21.0846 22.0568 19.5067C22.0568 18.374 20.7328 17.3948 18.8154 16.9319Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2638 10.1426C13.2638 8.46847 14.6316 7.11133 16.3189 7.11133C18.0062 7.11133 19.3741 8.46847 19.3741 10.1426V10.8607C19.3741 11.1636 19.1266 11.4092 18.8213 11.4092C18.516 11.4092 18.2685 11.1636 18.2685 10.8607V10.1426C18.2685 9.0743 17.3956 8.20828 16.3189 8.20828C15.2422 8.20828 14.3694 9.0743 14.3694 10.1426V10.8607C14.3694 11.1636 14.1219 11.4092 13.8166 11.4092C13.5113 11.4092 13.2638 11.1636 13.2638 10.8607V10.1426Z"
        fill="white"
      />
      <Path
        d="M42.7168 10.9084V0.221036H44.7016V9.22895H50.2896V10.9084H42.7168Z"
        fill="url(#paint1_linear_15865_7714)"
      />
      <Path
        d="M56.6705 11.0611C55.8358 11.0611 55.0674 10.9237 54.3651 10.6488C53.6627 10.374 53.052 9.99233 52.5329 9.50377C52.0138 9.00502 51.6118 8.42485 51.3268 7.76325C51.0418 7.09148 50.8993 6.35863 50.8993 5.56471C50.8993 4.77079 51.0418 4.04304 51.3268 3.38144C51.6118 2.70966 52.0138 2.12949 52.5329 1.64093C53.052 1.14218 53.6627 0.755404 54.3651 0.480586C55.0674 0.205768 55.8308 0.0683594 56.6552 0.0683594C57.4898 0.0683594 58.2532 0.205768 58.9454 0.480586C59.6477 0.755404 60.2584 1.14218 60.7775 1.64093C61.2966 2.12949 61.6986 2.70966 61.9836 3.38144C62.2686 4.04304 62.4111 4.77079 62.4111 5.56471C62.4111 6.35863 62.2686 7.09148 61.9836 7.76325C61.6986 8.43503 61.2966 9.0152 60.7775 9.50377C60.2584 9.99233 59.6477 10.374 58.9454 10.6488C58.2532 10.9237 57.4949 11.0611 56.6705 11.0611ZM56.6552 9.32055C57.1947 9.32055 57.6934 9.22895 58.1514 9.04574C58.6095 8.86252 59.0064 8.60297 59.3423 8.26709C59.6782 7.92102 59.9378 7.52406 60.121 7.07621C60.3144 6.61818 60.411 6.11435 60.411 5.56471C60.411 5.01508 60.3144 4.51633 60.121 4.06848C59.9378 3.61045 59.6782 3.2135 59.3423 2.87761C59.0064 2.53154 58.6095 2.2669 58.1514 2.08369C57.6934 1.90048 57.1947 1.80887 56.6552 1.80887C56.1158 1.80887 55.617 1.90048 55.159 2.08369C54.7111 2.2669 54.3142 2.53154 53.9681 2.87761C53.6322 3.2135 53.3676 3.61045 53.1742 4.06848C52.991 4.51633 52.8994 5.01508 52.8994 5.56471C52.8994 6.10417 52.991 6.60291 53.1742 7.06094C53.3676 7.51897 53.6322 7.92102 53.9681 8.26709C54.304 8.60297 54.7009 8.86252 55.159 9.04574C55.617 9.22895 56.1158 9.32055 56.6552 9.32055Z"
        fill="url(#paint2_linear_15865_7714)"
      />
      <Path
        d="M65.9555 11.0611C65.2735 11.0611 64.6425 10.9287 64.0623 10.6641C63.4923 10.3995 63.0241 10.0178 62.6577 9.51903L63.7875 8.16021C64.0826 8.56735 64.4033 8.8727 64.7493 9.07627C65.1056 9.27984 65.4924 9.38163 65.9097 9.38163C67.0191 9.38163 67.5738 8.72511 67.5738 7.4121V1.88521H63.7722V0.221036H69.5586V7.30522C69.5586 8.56735 69.2482 9.50885 68.6273 10.1297C68.0166 10.7506 67.126 11.0611 65.9555 11.0611Z"
        fill="url(#paint3_linear_15865_7714)"
      />
      <Path
        d="M72.3426 10.9084V0.221036H74.3274V10.9084H72.3426Z"
        fill="url(#paint4_linear_15865_7714)"
      />
      <Path
        d="M77.2181 10.9084V0.221036H78.8517L85.5542 8.4503H84.7451V0.221036H86.7146V10.9084H85.0809L78.3784 2.67913H79.1876V10.9084H77.2181Z"
        fill="url(#paint5_linear_15865_7714)"
      />
      <Path
        d="M97.09 0.221036H99.0748V10.9084H97.09V0.221036ZM91.5631 10.9084H89.5783V0.221036H91.5631V10.9084ZM97.2427 6.3281H91.3952V4.63339H97.2427V6.3281Z"
        fill="url(#paint6_linear_15865_7714)"
      />
      <Path
        d="M100.427 10.9084L105.236 0.221036H107.191L112.015 10.9084H109.939L105.801 1.2745H106.595L102.473 10.9084H100.427ZM102.641 8.43503L103.175 6.87773H108.946L109.481 8.43503H102.641Z"
        fill="url(#paint7_linear_15865_7714)"
      />
      <Defs>
        <linearGradient
          id="paint0_linear_15865_7714"
          x1="16.2963"
          y1="0"
          x2="16.2963"
          y2="32"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15865_7714"
          x1="77.366"
          y1="0.0683594"
          x2="77.366"
          y2="11.0611"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15865_7714"
          x1="77.366"
          y1="0.0683594"
          x2="77.366"
          y2="11.0611"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15865_7714"
          x1="77.366"
          y1="0.0683594"
          x2="77.366"
          y2="11.0611"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15865_7714"
          x1="77.366"
          y1="0.0683594"
          x2="77.366"
          y2="11.0611"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15865_7714"
          x1="77.366"
          y1="0.0683594"
          x2="77.366"
          y2="11.0611"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_15865_7714"
          x1="77.366"
          y1="0.0683594"
          x2="77.366"
          y2="11.0611"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_15865_7714"
          x1="77.366"
          y1="0.0683594"
          x2="77.366"
          y2="11.0611"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E54C6F" />
          <stop offset="1" stopColor="#E84C52" />
        </linearGradient>
      </Defs>
    </Svg>
  )
}

import { LinkingOptions } from '@react-navigation/native'
import * as Linking from 'expo-linking'

import { RootStackParamList } from './types'

export const linking: LinkingOptions<RootStackParamList> = {
  config: {
    initialRouteName: 'Entry',
    screens: {
      Address: 'enderecos',
      AddressForm: 'endereco/salvar/:id?',
      Cart: 'carrinho',
      CartAddress: 'carrinho/endereco',
      CheckoutAddress: 'carrinho/trocar-endereco',
      Entry: 'bem-vindo',
      Login: 'entrar',
      Notifications: 'notificacoes',
      RegisterName: 'cadastrar/nome',
      RegisterEmail: 'cadastrar/email',
      RegisterPassword: 'cadastrar/senha',
      RegisterSuccess: 'cadastrar/sucesso',
      Onboarding: 'intro',
      ProductDetails: 'produto/:idOrSlug',
      ProductEdit: 'produto/:idOrSlug/editar/:productCartId',
      Statement: 'extrato',
      Orders: 'pedidos',
      Tab: {
        screens: {
          Home: 'inicio',
          Products: 'produtos/:categorySlug',
          Profile: 'perfil',
          Orders: 'historico'
        }
      },
      PrivacyPolicy: 'politica-privacidade',
      RecoveryPasswordEmail: 'recuperar-senha/email',
      RecoveryPasswordSentEmailSuccesfully: 'recuperar-senha/email-enviado',
      RecoveryPasswordCreatePassword: '/recuperar-senha/nova-senha',
      RecoveryPasswordCreatePasswordFeedback: 'recuperar-senha/feedback',
      Error: 'erro',
      Disconnected: 'sem-conexao',
      NotFound: '404',
      TrackOrder: 'acompanhar-pedido/:orderId'
    }
  },
  prefixes: [
    Linking.createURL('/'),
    'https://lojinhafinanceira.laborit.com.br',
    'https://lojinhafinanceira.com.br',
    'lojinhafinanceira://'
  ]
}

import { FunctionComponent, useEffect, useRef, useState } from 'react'

import { ProductImage } from 'integration/resources/products'
import { AnimatedSlideDot } from 'molecules'
import { Box, HStack, Image, Pressable } from 'native-base'
import { useWindowDimensions } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

import { FullScreenImages } from '../FullScreenImages/FullScreenImages'

export const RIGHT_BANNER_WIDTH = 493

export type ProductGalleryMobileProps = {
  images: ProductImage[]
  name: string
  description: string
}

export const ProductGalleryMobile: FunctionComponent<ProductGalleryMobileProps> = ({
  images,
  name,
  description
}) => {
  const scrollX = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const { width: SCREEN_WIDTH } = useWindowDimensions()

  const refScrollView = useRef<Animated.ScrollView>(null)

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    refScrollView.current?.scrollTo({
      animated: false,
      x: scrollX.value
    })
  })

  return (
    <>
      <Animated.ScrollView
        horizontal
        onScroll={scrollHandler}
        pagingEnabled
        ref={refScrollView}
        scrollEventThrottle={32}
        showsHorizontalScrollIndicator={false}>
        {images.length ? (
          <>
            {images.map((slide: ProductImage, index: number) => (
              <Pressable key={`${slide}${index}`} onPress={() => setShowModal(true)}>
                <Box w={`${SCREEN_WIDTH}px`}>
                  <Image
                    source={
                      slide?.imageUrl ? { uri: slide.imageUrl } : require('assets/not-found.png')
                    }
                    alt={slide.imageDescription}
                    h={100}
                    w="full"
                  />
                </Box>
              </Pressable>
            ))}
          </>
        ) : (
          <Box w={`${SCREEN_WIDTH}px`}>
            <Image
              source={require('assets/not-found.png')}
              alt="product without image"
              h={100}
              w="full"
            />
          </Box>
        )}
      </Animated.ScrollView>

      <HStack alignItems="center" space={1} mb={8} px={6} mt="-40px">
        {images.map((slide: ProductImage, index: number) => (
          <AnimatedSlideDot
            index={index}
            key={`${slide}${index}`}
            scrollX={scrollX}
            slideWidth={SCREEN_WIDTH}
          />
        ))}
      </HStack>

      <FullScreenImages
        images={images}
        name={name}
        description={description}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        scrollX={scrollX}
      />
    </>
  )
}

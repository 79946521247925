import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ViewIcon: FunctionComponent<IIconProps & { off?: boolean }> = ({ off, ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    {off ? (
      <Path
        key={Math.random()}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1178 4.20586C12.0392 4.19806 11.9599 4.19806 11.8813 4.20586C7.55055 4.63482 3.55679 6.73104 0.74389 10.0506C0.265895 10.5689 0 11.2484 0 11.9544C0 12.6707 0.273727 13.3598 0.764993 13.8808C1.68129 14.8694 3.18998 16.3295 5.09473 17.55C6.99602 18.7683 9.37362 19.8 11.9995 19.8C14.6255 19.8 17.0031 18.7683 18.9044 17.55C20.8091 16.3295 22.3178 14.8694 23.2341 13.8808C23.7254 13.3598 23.9991 12.6707 23.9991 11.9544C23.9991 11.2485 23.7332 10.5689 23.2552 10.0507C20.4423 6.73104 16.4486 4.63482 12.1178 4.20586ZM2.55807 11.6221C4.94357 8.79652 8.32581 7.00097 11.9995 6.60638C15.6733 7.00097 19.0555 8.79652 21.441 11.6221C21.4559 11.6397 21.4713 11.6569 21.4872 11.6737C21.559 11.7495 21.5991 11.85 21.5991 11.9544C21.5991 12.0589 21.559 12.1593 21.4872 12.2351L21.4871 12.2351L21.4776 12.2453C20.6415 13.1478 19.2863 14.4548 17.6095 15.5292C15.9264 16.6077 14.0008 17.4 11.9995 17.4C9.99827 17.4 8.07268 16.6077 6.38957 15.5292C4.71279 14.4548 3.35757 13.1478 2.52145 12.2453L2.52151 12.2452L2.51193 12.2351C2.44006 12.1593 2.4 12.0589 2.4 11.9544C2.4 11.85 2.44006 11.7495 2.51193 11.6737C2.52781 11.6569 2.54319 11.6397 2.55807 11.6221ZM11.9988 7.80002C10.8849 7.80002 9.81663 8.24252 9.02898 9.03017C8.24133 9.81782 7.79883 10.8861 7.79883 12C7.79883 13.1139 8.24133 14.1822 9.02898 14.9699C9.81663 15.7575 10.8849 16.2 11.9988 16.2C13.1127 16.2 14.181 15.7575 14.9687 14.9699C15.7563 14.1822 16.1988 13.1139 16.1988 12C16.1988 10.8861 15.7563 9.81782 14.9687 9.03017C14.181 8.24252 13.1127 7.80002 11.9988 7.80002ZM10.726 10.7272C11.0636 10.3897 11.5214 10.2 11.9988 10.2C12.4762 10.2 12.9341 10.3897 13.2716 10.7272C13.6092 11.0648 13.7988 11.5226 13.7988 12C13.7988 12.4774 13.6092 12.9352 13.2716 13.2728C12.9341 13.6104 12.4762 13.8 11.9988 13.8C11.5214 13.8 11.0636 13.6104 10.726 13.2728C10.3885 12.9352 10.1988 12.4774 10.1988 12C10.1988 11.5226 10.3885 11.0648 10.726 10.7272Z"
        fill="currentColor"
      />
    ) : (
      <Path
        key={Math.random()}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64814 0.951448C2.17951 0.482818 1.41971 0.482818 0.951081 0.951448C0.482452 1.42008 0.482452 2.17987 0.951081 2.6485L5.90506 7.60249C5.92603 7.62514 5.9478 7.6469 5.9703 7.66772L21.3511 23.0485C21.8197 23.5171 22.5795 23.5171 23.0481 23.0485C23.5168 22.5799 23.5168 21.8201 23.0481 21.3514L18.9635 17.2668C19.6485 16.8928 20.4993 16.392 21.1424 15.8843C22.0166 15.194 22.5737 14.5927 23.1562 13.9641L23.2333 13.8809C23.7246 13.3599 23.9983 12.6707 23.9983 11.9544C23.9983 11.2486 23.7325 10.5691 23.2547 10.0509C20.5506 6.85422 17.0587 4.04226 11.9615 4.20059C10.7286 4.23891 9.28925 4.63275 8.23134 4.9773C7.78928 5.12127 7.39335 5.26357 7.07863 5.38194L2.64814 0.951448ZM8.96057 7.26388L17.1881 15.4914C17.3405 15.413 17.5113 15.3236 17.6925 15.2259C18.3755 14.8575 19.1424 14.4054 19.6552 14.0006C20.381 13.4275 20.828 12.9453 21.4174 12.3095L21.4773 12.2449L21.4863 12.2351C21.5582 12.1593 21.5983 12.0589 21.5983 11.9544C21.5983 11.85 21.5583 11.7495 21.4864 11.6737C21.4705 11.6569 21.4551 11.6398 21.4403 11.6221C18.922 8.63933 16.0634 6.47433 12.036 6.59944C11.1635 6.62655 9.99656 6.92646 8.97457 7.25931C8.9699 7.26084 8.96523 7.26236 8.96057 7.26388ZM4.51889 8.2119C4.94483 8.71964 4.87853 9.47654 4.37079 9.90248C3.71808 10.45 3.10048 11.0381 2.52165 11.6632L2.51193 11.6736C2.44007 11.7494 2.4 11.8499 2.4 11.9543C2.4 12.0588 2.44007 12.1593 2.51193 12.2351L2.52151 12.2452L2.52146 12.2452C3.35757 13.1478 4.71279 14.4547 6.38957 15.5292C8.07268 16.6077 9.99827 17.3999 11.9995 17.3999C12.6623 17.3999 13.1995 17.9372 13.1995 18.5999C13.1995 19.2627 12.6623 19.7999 11.9995 19.7999C9.37362 19.7999 6.99602 18.7682 5.09473 17.5499C3.18998 16.3294 1.68128 14.8693 0.764983 13.8807C0.273721 13.3597 0 12.6706 0 11.9543C0 11.2381 0.273679 10.5491 0.76487 10.0281C1.41089 9.33077 2.10006 8.67473 2.82831 8.0638C3.33605 7.63785 4.09295 7.70416 4.51889 8.2119Z"
        fill="currentColor"
      />
    )}
  </Icon>
)

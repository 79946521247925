import { Suspense } from 'react'

import { Spinner } from 'native-base'

import { LoginScreenDesktop } from './LoginScreen.desktop'
import { LoginScreenMobile } from './LoginScreen.mobile'
import { LoginScreenComponent } from './LoginScreen.types'
import { useLoginScreen } from './useLoginScreen'

export const LoginScreen: LoginScreenComponent = ({ navigation }) => {
  const loginScreen = useLoginScreen({ navigation })

  const Screen = loginScreen.isMobile ? LoginScreenMobile : LoginScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...loginScreen} />
    </Suspense>
  )
}

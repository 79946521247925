import { AxiosError, AxiosResponse } from 'axios'
import { ResponseError } from 'integration/types'
import { useMutation } from 'react-query'

import * as requests from './requests'
import { ResetPasswordPayload, SendEmailResetPasswordPayload } from './types'

export const useSendEmailRecoveryPassword = () =>
  useMutation<AxiosResponse, AxiosError<ResponseError>, SendEmailResetPasswordPayload>(
    requests.sendEmailResetPassword
  )

export const useResetPasswordMutation = () =>
  useMutation<AxiosResponse, AxiosError<ResponseError>, ResetPasswordPayload>(
    requests.resetPassword
  )

import { Address, AddressData } from './types'
import client from '../../client'

export const getAddress = () => client.get<AddressData>('v1/address')

export const saveAddress = (data: Address) => {
  if (data.id) {
    return client.put<Address>(`v1/address/${data.id}/`, data)
  }

  return client.post<Address>('v1/address/', data)
}

export const deleteAddress = (data: Address) => {
  return client.delete<Address>(`v1/address/${data.id}/`)
}

export default {
  Address: 'Lista de endereços',
  AddressForm: 'Formulário de endereço',
  Cart: 'Carrinho de compras',
  CartAddress: 'Carrinho endreço',
  CheckoutAddress: 'Carrinho trocar endereço',
  Entry: 'Tela inicial',
  Login: 'Login',
  Notifications: 'Notificações',
  RegisterName: 'Cadastrar nome',
  RegisterEmail: 'Cadastrar email',
  RegisterPassword: 'Cadastrar senha',
  RegisterSuccess: 'Cadastro com sucesso',
  Onboarding: 'Onboarding',
  ProductDetails: 'Produto - Detalhes',
  ProductEdit: 'Produto - Editar',
  ReviewCart: 'Revisar Carrinho',
  Statement: 'Extrato',
  // Tab: NavigatorScreenParams<TabParamList> | undefined
  PrivacyPolicy: 'Políticas de Privacidade',
  RecoveryPasswordEmail: 'Recuperar Senha',
  RecoveryPasswordSentEmailSuccesfully: 'Recuperar Senha - Email enviado',
  RecoveryPasswordCreatePassword: 'Recuperar Senha - Nova senha',
  RecoveryPasswordCreatePasswordFeedback: 'Recuperar Senha - Feedback',
  Error: 'Error',
  NotFound: 'Não encontrado',
  Disconnected: 'Disconectado',
  Products: 'Lista de Produtos',
  Orders: 'Lista de pedidos',
  Home: 'Início',
  Profile: 'Perfil',
  TrackOrder: 'Rastrear Pedido'
}

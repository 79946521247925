import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const LogoutIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.4 21.5786V2.42146C2.4025 2.41789 2.4058 2.41387 2.40983 2.40983C2.41387 2.4058 2.41789 2.4025 2.42146 2.4H13.1786C13.1821 2.4025 13.1861 2.4058 13.1902 2.40983C13.1942 2.41386 13.1975 2.41788 13.2 2.42145V7.0152C13.2 7.67794 13.7373 8.2152 14.4 8.2152C15.0627 8.2152 15.6 7.67794 15.6 7.0152V2.4C15.6 1.10079 14.4993 0 13.2 0H2.4C1.10074 0 0 1.10074 0 2.4V21.6C0 22.8993 1.10079 24 2.4 24H13.2C14.4993 24 15.6 22.8993 15.6 21.6V16.9848C15.6 16.3221 15.0627 15.7848 14.4 15.7848C13.7373 15.7848 13.2 16.3221 13.2 16.9848V21.5786C13.1975 21.5821 13.1942 21.5861 13.1902 21.5902C13.1861 21.5942 13.1821 21.5975 13.1785 21.6H2.42145C2.41789 21.5975 2.41386 21.5942 2.40983 21.5902C2.4058 21.5861 2.4025 21.5821 2.4 21.5786ZM18.3515 7.55148C18.8201 7.08285 19.5799 7.08285 20.0485 7.55148L23.6485 11.1515C24.1172 11.6201 24.1172 12.3799 23.6485 12.8485L20.0485 16.4485C19.5799 16.9172 18.8201 16.9172 18.3515 16.4485C17.8828 15.9799 17.8828 15.2201 18.3515 14.7515L19.903 13.2H8.39922C7.73648 13.2 7.19922 12.6627 7.19922 12C7.19922 11.3372 7.73648 10.8 8.39922 10.8H19.9029L18.3515 9.24854C17.8828 8.77991 17.8828 8.02011 18.3515 7.55148Z"
      fill="currentColor"
    />
  </Icon>
)

const gray = {
  100: '#F5F6F6', // lightest
  150: '#FAFAFA',
  200: '#D2D4DA', // light
  250: '#D9D9D9',
  300: '#B0B4BF',
  400: '#949AA8',
  500: '#777E90', // base
  600: '#606676',
  700: '#454954', // dark
  800: '#2E3138',
  900: '#121316' //darkest
}

const cyan = {
  50: '#E3F5F8', // lightest
  100: '#cffafe',
  200: '#92D8E2', // light
  300: '#67e8f9',
  400: '#22d3ee',
  500: '#2E9FAF', // base
  600: '#0891b2',
  700: '#0e7490',
  800: '#1E6771', //dark
  900: '#154951' // darkest
}

const tertiary = {
  50: '#FBEDDA', // lightest
  100: '#F7D9B3',
  200: '#F5CF9E', // light
  300: '#F3C68C',
  400: '#EFB366',
  500: '#EBA040', // base
  600: '#E28918', //dark
  700: '#B46D13', // darkest
  900: '#8E6126',
  800: '#60411A'
}

const red = {
  50: '#fef2f2',
  100: '#fee2e2',
  200: '#FDD3DB', // lightest
  300: '#fca5a5',
  400: '#F8728B', // light
  500: '#F3123C', // base
  600: '#C70A2D', // dark
  700: '#b91c1c',
  800: '#991b1b',
  900: '#88071F' //darkest
}

const emerald = {
  50: '#ecfdf5', // lightest
  100: '#d1fae5',
  200: '#9BD4B4', // light
  300: '#6ee7b7',
  400: '#34d399',
  500: '#409565', // base
  600: '#059669',
  700: '#31724D', // dark
  800: '#065f46',
  900: '#224E35' //darkest
}

export const colors = {
  background: {
    base: '#FAFAFA',
    light: '#FCFCFC'
  },
  overlay: 'rgba(18, 19, 22, 0.7)',
  gray,
  cyan,
  red,
  emerald,
  primary: red,
  secondary: cyan,
  tertiary,
  sucess: emerald,
  error: '#D86F6F',
  'header-gradient': {
    50: '#494848',
    100: '#393939',
    200: '#070707'
  }
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const SearchIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2 0C7.49479 0 4.90038 1.07464 2.98751 2.98751C1.07464 4.90038 0 7.49479 0 10.2C0 12.9052 1.07464 15.4996 2.98751 17.4125C4.90038 19.3254 7.49479 20.4 10.2 20.4C12.5022 20.4 14.7242 19.6217 16.5141 18.2108L21.9519 23.6485C22.4205 24.1172 23.1803 24.1172 23.649 23.6485C24.1176 23.1799 24.1176 22.4201 23.649 21.9515L18.2111 16.5137C19.6218 14.7238 20.4 12.502 20.4 10.2C20.4 7.49479 19.3254 4.90038 17.4125 2.98751C15.4996 1.07464 12.9052 0 10.2 0ZM4.68457 4.68457C6.14735 3.22178 8.13131 2.4 10.2 2.4C12.2687 2.4 14.2526 3.22178 15.7154 4.68457C17.1782 6.14735 18 8.13131 18 10.2C18 12.2687 17.1782 14.2526 15.7154 15.7154C14.2526 17.1782 12.2687 18 10.2 18C8.13131 18 6.14735 17.1782 4.68457 15.7154C3.22178 14.2526 2.4 12.2687 2.4 10.2C2.4 8.13131 3.22178 6.14735 4.68457 4.68457Z"
      fill="currentColor"
    />
  </Icon>
)

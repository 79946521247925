export const Badge = {
  defaultProps: {
    size: 'md',
    variant: 'solid'
  },
  sizes: {
    sm: {},
    md: {
      px: 2,
      h: 6,
      borderRadius: 'full',
      variant: 'solid',
      _text: {
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '16px',
        fontFamily: 'heading',
        color: 'white'
      }
    },
    lg: {
      px: 4,
      h: 7,
      borderRadius: 'full',
      variant: 'solid',
      _text: {
        fontSize: '12px',
        fontWeight: 'normal',
        lineHeight: '16px',
        fontFamily: 'heading',
        color: 'white'
      }
    }
  },
  variants: {
    solid: () => {
      return {
        bg: 'cyan.500',
        _text: {
          color: 'white'
        }
      }
    },
    outline: () => {
      return {
        bg: 'white',
        borderWidth: 1,
        borderColor: 'cyan.500',
        _text: {
          color: 'cyan.500'
        }
      }
    }
  }
}

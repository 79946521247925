import React from 'react'

import { Box, Text } from 'native-base'

export const SessionExpiredFeedback = React.memo(() => (
  <Box bg="primary.500" px="2" py="1" rounded="sm" mb={5}>
    <Text color="white" fontSize="14px">
      Sua sessão expirou, realize o login novamente
    </Text>
  </Box>
))

import { forwardRef } from 'react'

import { Icons } from 'atoms'
import { IconButton, IInputProps, Input, useDisclose } from 'native-base'
import { TextInput } from 'react-native'

export const InputPassword = forwardRef<TextInput, IInputProps>((props, ref) => {
  const { isOpen, onToggle } = useDisclose(false)

  return (
    <Input
      // @ts-ignore
      ref={ref}
      rightElement={
        <IconButton
          borderRadius="full"
          colorScheme="blueGray"
          position="absolute"
          right={2}
          _hover={{ bg: 'background.base' }}
          icon={<Icons.View off={isOpen} color="gray.700" size={6} mr={2} />}
          onPress={onToggle}
        />
      }
      type={isOpen ? 'text' : 'password'}
      {...props}
    />
  )
})

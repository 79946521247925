import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, LinearGradient, Stop, Defs } from 'react-native-svg'
import { theme } from 'src/theme'

export const StoreIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 133 100', ...props }}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.2468 18.1836H38.0151C28.2461 18.1836 20.1435 25.744 19.4678 35.4895L16.3735 80.1226C15.6284 90.8709 24.1466 100 34.9208 100H97.7355C108.51 100 117.028 90.8709 116.283 80.1226L113.188 35.4895C112.513 25.744 104.41 18.1836 94.6412 18.1836H87.6647V22.0409C87.6647 25.6837 84.6884 28.6368 81.0168 28.6368C77.3453 28.6368 74.3689 25.6837 74.3689 22.0409V18.1836H58.5426V22.0409C58.5426 25.6837 55.5663 28.6368 51.8947 28.6368C48.2232 28.6368 45.2468 25.6837 45.2468 22.0409V18.1836ZM80.9833 57.1455C80.8854 55.0476 80.2423 52.9626 79.0678 51.0849L68.6376 34.4702C67.8407 33.2011 67.2954 31.8413 66.9878 30.4557L66.5544 31.155C63.9678 35.2601 63.9678 40.3365 66.5544 44.4416L74.8874 57.7282C77.4739 61.8463 77.4739 66.9097 74.8874 71.0148L74.4539 71.7141C74.1463 70.3155 73.601 68.9687 72.8041 67.6997L60.2906 47.7697C59.4937 46.5006 58.9484 45.1409 58.6408 43.7553L58.2074 44.4546C55.6348 48.5467 55.6208 53.5972 58.1794 57.7023L66.5404 71.0278C69.127 75.1459 69.127 80.2093 66.5404 84.3144L66.1069 85.0137C65.7993 83.6151 65.2541 82.2683 64.4571 80.9992L54.0269 64.3845C52.6287 62.1571 51.9856 59.6448 52.1114 57.1584C40.9681 59.8391 33.2643 65.537 33.2643 72.1285C33.2643 81.2971 48.1686 88.7433 66.5544 88.7433C84.9401 88.7433 99.8444 81.31 99.8444 72.1285C99.8444 65.537 92.1406 59.8391 80.9833 57.1455Z"
      fill={props.color === '#454954' ? 'currentColor' : 'url(#paint0_linear_15025_17021)'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.6797 17.6387C48.6797 7.89714 56.6391 0 66.4575 0C76.2758 0 84.2352 7.89714 84.2352 17.6387V21.8175C84.2352 23.5802 82.7951 25.0091 81.0185 25.0091C79.242 25.0091 77.8018 23.5802 77.8018 21.8175V17.6387C77.8018 11.4224 72.7227 6.38312 66.4575 6.38312C60.1922 6.38312 55.1131 11.4224 55.1131 17.6387V21.8175C55.1131 23.5802 53.673 25.0091 51.8964 25.0091C50.1199 25.0091 48.6797 23.5802 48.6797 21.8175V17.6387Z"
      fill={props.color === '#454954' ? 'currentColor' : 'url(#paint1_linear_15025_17021)'}
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_15025_17021"
        x1={23.1281}
        y1={25.3834}
        x2={34.2921}
        y2={121.327}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor={theme.colors.red[500]} />
        <Stop offset={1} stopColor="#B30000" />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_15025_17021"
        x1={51.0975}
        y1={2.2008}
        x2={54.0415}
        y2={31.6308}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor={theme.colors.red[500]} />
        <Stop offset={1} stopColor="#B30000" />
      </LinearGradient>
    </Defs>
  </Icon>
)

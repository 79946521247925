import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react'

import { Icons } from 'atoms'
import { AnimatedSlideDot } from 'molecules'
import {
  Box,
  Container,
  HStack,
  IconButton,
  Skeleton,
  useBreakpointValue,
  VStack
} from 'native-base'
import { useWindowDimensions } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

const CAROUSEL_INTERVAL = 4000

export const FeaturedProductsPreview: FunctionComponent = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const windowDimensions = useWindowDimensions()

  const SLIDE_WIDTH = useMemo(() => windowDimensions.width, [windowDimensions.width])

  const SLIDE_PADDING_X = useBreakpointValue({ base: 6, lg: 0 })

  const refScrollView = useRef<Animated.ScrollView>(null)

  const scrollX = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const moveSlide = useCallback(
    (direction: 'left' | 'right') => {
      let nextX, x

      if (direction === 'left') {
        nextX = scrollX.value - SLIDE_WIDTH

        x = nextX < 0 ? 3 * SLIDE_WIDTH : nextX
      } else {
        nextX = scrollX.value + SLIDE_WIDTH

        x = nextX > 3 * SLIDE_WIDTH ? 0 : nextX
      }

      refScrollView.current?.scrollTo({
        animated: true,
        x
      })
    },
    [SLIDE_WIDTH, scrollX.value]
  )

  useEffect(() => {
    if (!isMobile) {
      const interval = setInterval(() => moveSlide('right'), CAROUSEL_INTERVAL)

      return () => {
        clearInterval(interval)
      }
    }
  }, [isMobile, moveSlide])

  return (
    <VStack space={{ base: 4, lg: 5 }} rounded={10}>
      <Box position="relative">
        <Animated.ScrollView
          horizontal
          onScroll={scrollHandler}
          pagingEnabled
          ref={refScrollView}
          scrollEventThrottle={32}
          showsHorizontalScrollIndicator={false}>
          {[0, 1, 2, 3].map((item) => (
            <Skeleton
              key={item}
              h={{ base: '426px', lg: '432px' }}
              px={SLIDE_PADDING_X}
              w={`${SLIDE_WIDTH}px`}
              rounded={10}
            />
          ))}
        </Animated.ScrollView>
        {!isMobile && (
          <Container
            flexDirection="row"
            justifyContent="space-between"
            left="50%"
            position="absolute"
            // @ts-ignore
            style={{ transform: [{ translateX: '-50%' }, { translateY: '-50%' }] }}
            top="50%">
            <IconButton
              _hover={{
                _icon: {
                  color: 'white'
                }
              }}
              _icon={{
                color: 'primary.500'
              }}
              alignItems="center"
              borderRadius="full"
              bg="white"
              h={12}
              icon={<Icons.Chevron direction="left" size={5} />}
              justifyContent="center"
              onPress={() => moveSlide('left')}
              variant="solid"
              w={12}
            />
            <IconButton
              _hover={{
                _icon: {
                  color: 'white'
                }
              }}
              _icon={{
                color: 'primary.500'
              }}
              alignItems="center"
              borderRadius="full"
              bg="white"
              h={12}
              icon={<Icons.Chevron direction="right" size={5} />}
              justifyContent="center"
              onPress={() => moveSlide('right')}
              variant="solid"
              w={12}
            />
          </Container>
        )}
      </Box>
      <Box alignItems={{ lg: 'center' }} px={{ base: SLIDE_PADDING_X, lg: 0 }}>
        <HStack alignItems="center" space={1}>
          {[0, 1, 2, 3].map((number, index) => (
            <AnimatedSlideDot
              key={number}
              index={index}
              scrollX={scrollX}
              slideWidth={SLIDE_WIDTH}
            />
          ))}
        </HStack>
      </Box>
    </VStack>
  )
}

import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'

import { Icons } from 'atoms'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import {
  ColorsWithSizes,
  ProductImage,
  ProductSize,
  Rating as RatingType
} from 'integration/resources/products'
import { ColorRadio, ProductAddRem, Rating, SizeRadio } from 'molecules'
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Modal,
  Text,
  useBreakpointValue,
  View,
  VStack
} from 'native-base'
import { RatingList } from 'organisms/RatingList/RatingList'
import { Dimensions } from 'react-native'
import ImageZoom from 'react-native-image-pan-zoom'

export type ProductSelectProps = {
  productSlug: string
  productId: string
  name: string
  description: string
  price: number
  sizeTableImage: string
  colorsWithSizes: ColorsWithSizes[]
  productImage: ProductImage
  rating?: RatingType
  onSelectColor: Dispatch<SetStateAction<string | undefined>>
}

export const ProductSelect: FunctionComponent<ProductSelectProps> = ({
  productSlug,
  productId,
  name,
  description,
  price,
  sizeTableImage,
  colorsWithSizes,
  productImage,
  rating,
  onSelectColor
}) => {
  const [showModal, setShowModal] = useState(false)

  const [colorWithSizes, setColorWithSizes] = useState<ColorsWithSizes | undefined>()

  const [size, setSize] = useState<ProductSize | undefined>()

  const [amount, setAmount] = useState(1)

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const bottomSheetContext = useBottomSheetContext()

  const handleChangeColor = (_colorWithSizes: ColorsWithSizes) => {
    setColorWithSizes(_colorWithSizes)

    onSelectColor(_colorWithSizes.colorId)

    setSize(undefined)

    setAmount(1)
  }

  const renderQuantityError = (limitQuantity: number) =>
    bottomSheetContext.open({
      description: `Há somente ${limitQuantity} ${
        limitQuantity === 1 ? 'disponível' : 'disponíveis'
      } para o tamanho escolhido.`,
      title: 'Erro ao selecionar o produto!'
    })

  const handleRemoveQuantity = (amount: number, quantity: number) => {
    const newAmount = Number(amount) - 1

    if (newAmount > quantity) {
      renderQuantityError(quantity)
    }

    setAmount(amount <= 0 ? 0 : newAmount)
  }

  const handleChangeQuantity = (amount: string, quantity: number) => {
    if (Number(amount) > quantity) {
      renderQuantityError(quantity)
    }

    setAmount(Number(amount))
  }

  const handleAddQuantity = (amount: number, limitQuantity: number) => {
    const newAmount = Number(amount) + 1

    if (newAmount > limitQuantity) {
      renderQuantityError(limitQuantity)
    }

    setAmount(newAmount)
  }

  return (
    <>
      <VStack
        bg="white"
        borderTopLeftRadius={isDesktop ? '10px' : '12px'}
        borderTopRightRadius={isDesktop ? '10px' : '12px'}
        borderBottomRadius={isDesktop ? '10px' : 0}
        mt={isDesktop ? 0 : '-10px'}
        style={
          isDesktop
            ? {
                shadowColor: 'black',
                shadowOffset: {
                  width: 0,
                  height: 2
                },
                shadowOpacity: 0.05,
                shadowRadius: 8.88,
                elevation: 20
              }
            : {
                shadowColor: 'black',
                shadowOffset: {
                  width: 0,
                  height: 1
                },
                shadowOpacity: 0.39,
                shadowRadius: 8.3,
                elevation: 13
              }
        }>
        <VStack p={6} pt={5}>
          {!isDesktop && (
            <Box
              alignSelf="center"
              width={12}
              height={1}
              bg="gray.250"
              borderRadius="6"
              mb="10px"
            />
          )}

          <Heading
            color={{ base: 'gray.800', lg: 'gray.900' }}
            fontSize={{ base: '17.8px', lg: 32 }}
            lineHeight={{ base: 20, lg: 48 }}
            mt={{ base: 6, lg: 0 }}
            mb={4}
            fontWeight="700"
            _web={{ fontWeight: '600' }}
            style={{ fontWeight: isDesktop ? '600' : '700' }}>
            {name}
          </Heading>

          {!isDesktop && (
            <Text color="gray.900" fontSize="13.84px" lineHeight="18.11px">
              {description}
            </Text>
          )}

          <Heading
            fontSize={{ base: '17.8px', lg: 24 }}
            lineHeight={{ base: 20, lg: 36 }}
            mt={6}
            mb={4}
            style={{ fontWeight: '700' }}>
            Valor
          </Heading>

          <Text
            color="gray.700"
            fontSize={{ base: '13.84px', lg: 20 }}
            lineHeight={{ base: '18.11px', lg: 30 }}>
            {price} pontos
          </Text>

          <Heading
            fontSize={{ base: '17.8px', lg: 24 }}
            lineHeight={{ base: 20, lg: 36 }}
            mt={6}
            mb={4}
            style={{ fontWeight: '700' }}>
            {`Escolha a cor: `}
            <Text
              fontFamily="Assistant_600SemiBold"
              fontSize={20}
              lineHeight={30}
              color="gray.500"
              style={{ fontWeight: '600' }}>
              {colorWithSizes?.colorName}
            </Text>
          </Heading>

          <HStack flexWrap="wrap">
            {colorsWithSizes.map((_colorWithSizes: ColorsWithSizes, index: number) => (
              <ColorRadio
                key={`color-${index}`}
                active={colorWithSizes?.colorHex === _colorWithSizes.colorHex}
                color={_colorWithSizes.colorHex}
                onPress={() => handleChangeColor(_colorWithSizes)}
              />
            ))}
          </HStack>

          {colorWithSizes && (
            <>
              <Heading
                fontSize={{ base: '17.8px', lg: 24 }}
                lineHeight={{ base: 20, lg: 33 }}
                mt={2}
                mb={4}
                style={{ fontWeight: '600' }}>
                {`Escolha o tamanho: `}
                <Text
                  fontFamily="Assistant_600SemiBold"
                  fontSize={20}
                  lineHeight={30}
                  color="gray.500"
                  style={{ fontWeight: '600' }}>
                  {size?.size}
                </Text>
              </Heading>

              <HStack flexWrap="wrap">
                {colorWithSizes?.sizes
                  .filter((_size) => _size.available)
                  .map((_size: ProductSize, index: number) => (
                    <SizeRadio
                      key={`size-${index}`}
                      active={size?.size === _size.size}
                      onPress={() => setSize(_size)}
                      size={_size.size}
                      amount={_size.amount}
                    />
                  ))}
              </HStack>
            </>
          )}

          <Button
            mt={4}
            size="sm"
            w={46}
            variant="outline"
            onPress={() => setShowModal(true)}
            leftIcon={<Icons.Clothing color="red.500" size="5" />}>
            <Text
              color="red.500"
              fontFamily="heading"
              fontSize="16px"
              lineHeight="20px"
              style={{ fontWeight: '600' }}>
              Tabela de medidas
            </Text>
          </Button>

          {!isDesktop && (
            <VStack>
              <Heading
                fontSize={{ base: '17.8px', lg: 24 }}
                lineHeight={{ base: 20, lg: 33 }}
                mt={6}
                mb={4}
                style={{ fontWeight: '600' }}>
                Avaliações do produto
              </Heading>
              <Center w="full">
                {rating && rating?.total_rating > 0 ? (
                  <>
                    <VStack space={2}>
                      <VStack space={2} alignItems="center">
                        <Text
                          textAlign="center"
                          fontSize={12}
                          fontWeight={400}
                          lineHeight={18}
                          color="gray.900">
                          Qualidade do produto
                        </Text>
                        <Rating viewScore score={rating?.average_quality ?? 0} size={6} />
                      </VStack>
                      <VStack space={2} alignItems="center">
                        <Text
                          textAlign="center"
                          fontSize={12}
                          fontWeight={400}
                          lineHeight={18}
                          color="gray.900">
                          Conforto
                        </Text>
                        <Rating viewScore score={rating?.average_confort ?? 0} size={6} />
                      </VStack>
                      <VStack space={2} alignItems="center">
                        <Text
                          textAlign="center"
                          fontSize={12}
                          fontWeight={400}
                          lineHeight={18}
                          color="gray.900">
                          Tamanho
                        </Text>
                        <Rating viewScore score={rating?.average_size ?? 0} size={6} />
                      </VStack>
                    </VStack>
                    <RatingList identifier={productId} />
                  </>
                ) : (
                  <VStack space={4} alignItems="center">
                    <Text
                      textAlign="center"
                      fontSize={12}
                      fontWeight={400}
                      lineHeight={18}
                      color="gray.900">
                      Esse produto ainda não tem avaliação
                    </Text>
                    <Rating score={0} size={6} />
                  </VStack>
                )}
              </Center>
            </VStack>
          )}
        </VStack>

        <ProductAddRem
          productSlug={productSlug}
          productId={productId}
          productName={name}
          productDescription={description}
          color={colorWithSizes}
          size={size}
          amount={amount}
          limitQuantity={size?.amount ?? 0}
          onAdd={() => handleAddQuantity(amount, size?.amount ?? 0)}
          onChangeText={(amount: string) => handleChangeQuantity(amount, size?.amount ?? 0)}
          onRemove={() => handleRemoveQuantity(amount, size?.amount ?? 0)}
          price={price}
          productImage={productImage}
        />
      </VStack>

      <Modal bg="rgba(18, 19, 22, 0.7)" isOpen={showModal} onClose={() => setShowModal(false)}>
        {isDesktop ? (
          <Box>
            <Modal.CloseButton
              _icon={{
                color: 'white',
                size: 5,
                _web: { color: 'gray.700' }
              }}
              _web={{
                backgroundColor: 'white',
                borderRadius: '50%',
                right: '-20px',
                top: '-20px',
                size: '48px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              position="absolute"
              right="-85px"
              top="-85px"
              style={{
                shadowColor: 'rgba(0, 0, 0, 0.2)',
                shadowOffset: {
                  width: 0,
                  height: 3
                },
                shadowOpacity: 0.29,
                shadowRadius: 4.65,
                elevation: 7
              }}
            />

            <View p={6} bg="white" borderRadius="12px" width={600}>
              <Image
                source={{ uri: sizeTableImage }}
                alt="Tabela de medidas"
                resizeMode="contain"
                h={{ base: '180', lg: '500' }}
              />
            </View>
          </Box>
        ) : (
          <Box flex={1} safeAreaTop>
            <Modal.CloseButton
              _icon={{ color: 'white', size: 6 }}
              _web={{ backgroundColor: 'none' }}
              _android={{ backgroundColor: 'none' }}
              _ios={{ backgroundColor: 'none' }}
              position="absolute"
              right="20px"
              top="70px"
            />

            {/* @ts-ignore */}
            <ImageZoom
              cropWidth={Dimensions.get('window').width}
              cropHeight={Dimensions.get('window').height}
              imageWidth={300}
              imageHeight={300}>
              <Modal.Body bg="white" borderRadius="12px">
                <Image
                  source={{ uri: sizeTableImage }}
                  alt="Tabela de medidas"
                  resizeMode="contain"
                  h={45}
                />
              </Modal.Body>
            </ImageZoom>
          </Box>
        )}
      </Modal>
    </>
  )
}

import { Icons } from 'atoms'
import { InputPassword } from 'molecules'
import { Button, FormControl, Heading, HStack, Input, Switch, Text, VStack } from 'native-base'
import { Controller } from 'react-hook-form'
import Animated, { FadeInDown, FadeOutUp, Layout } from 'react-native-reanimated'

import { ResponsiveLoginScreenComponent } from './LoginScreen.types'
import { handleInputPasswordFocus, refInputPassword } from './useLoginScreen'

export const FormLogin: ResponsiveLoginScreenComponent = ({
  control,
  setRememberEmail,
  submit,
  error,
  handleRedirectToRecoveryPassword,
  isLoading,
  isMobile
}) => {
  return (
    <>
      <Controller
        control={control}
        rules={{
          required: 'Digite seu e-mail'
        }}
        render={({ field: { onChange: onChangeText, onBlur, value }, formState: { errors } }) => (
          <FormControl isInvalid={!!errors.email} isRequired>
            <VStack mb={{ base: 10, lg: 8 }} space={3}>
              <Heading fontSize="24px" fontWeight="bold" lineHeight={{ base: '30px', lg: '28px' }}>
                Digite seu e-mail
              </Heading>
            </VStack>

            <Input
              {...{ onChangeText, onBlur, value }}
              autoCapitalize="none"
              autoCorrect={false}
              keyboardType="email-address"
              autoComplete="off"
              h="auto"
              onSubmitEditing={handleInputPasswordFocus}
              placeholder="email@gmail.com"
              InputLeftElement={<Icons.Mail size={5} color="gray.700" ml={4} />}
            />
            {errors.email && (
              <FormControl.ErrorMessage>{errors.email.message}</FormControl.ErrorMessage>
            )}
          </FormControl>
        )}
        name="email"
      />
      <HStack alignItems="center" justifyContent="space-between" mt={4} mb={10}>
        <Text fontSize={{ base: '12px', lg: '16px' }} lineHeight="18px" fontWeight="normal">
          Lembrar meu e-mail
        </Text>
        <Switch defaultIsChecked onValueChange={setRememberEmail} size="sm" />
      </HStack>
      <Controller
        control={control}
        rules={{
          required: 'Digite sua senha'
        }}
        render={({ field: { onChange: onChangeText, onBlur, value }, formState: { errors } }) => (
          <FormControl isInvalid={!!errors.password} isRequired>
            <Heading
              mb={{ base: 10, lg: 8 }}
              fontSize="24px"
              fontWeight="bold"
              lineHeight={{ base: '30px', lg: '28px' }}>
              Digite sua senha
            </Heading>

            <InputPassword
              {...{ onChangeText, onBlur, value }}
              autoCorrect={false}
              autoComplete="off"
              h="auto"
              enablesReturnKeyAutomatically
              onSubmitEditing={submit}
              ref={refInputPassword}
              returnKeyType="go"
              placeholder="Senha"
              InputLeftElement={<Icons.Key size={5} color="gray.700" ml={4} />}
            />
            {errors.password && (
              <FormControl.ErrorMessage>{errors.password.message}</FormControl.ErrorMessage>
            )}
          </FormControl>
        )}
        name="password"
      />
      {!!error && !isLoading && (
        <Animated.View entering={FadeInDown} exiting={FadeOutUp} layout={Layout.springify()}>
          <Text color="error" fontSize="16px" lineHeight="24px" mb={12} textAlign="center">
            {error}
          </Text>
        </Animated.View>
      )}
      <Animated.View layout={Layout.springify()}>
        <Button mt={8} isLoading={isLoading} onPress={submit} w="full">
          Entrar
        </Button>
      </Animated.View>

      <HStack mt={4} alignItems="center">
        <Text fontSize={{ base: '12px', lg: '16px' }} mr={2}>
          Esqueceu a senha?
        </Text>
        <Button variant="unstyled" p={0} onPress={handleRedirectToRecoveryPassword}>
          <Text fontSize={{ base: '12px', lg: '16px' }} color="secondary.500" bold>
            Redefinir
          </Text>
        </Button>
      </HStack>
    </>
  )
}

import { Rating as RatingType } from 'integration/resources/rating'
import { Rating } from 'molecules/Rating/Rating'
import { VStack, Text, HStack, useBreakpointValue } from 'native-base'
import { normalizeDate } from 'src/utils/normalizeDates'

export const RatingItem = ({
  user_name,
  updated_at,
  quality,
  confort,
  size,
  review
}: RatingType) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <VStack>
      <Text
        fontSize={isMobile ? 16 : 20}
        fontWeight={isMobile ? 400 : 500}
        lineHeight={isMobile ? 24 : 30}
        color="gray.800"
        mb={2}>
        {user_name}
      </Text>
      <Text
        fontSize={isMobile ? 12 : 14}
        fontWeight={400}
        lineHeight={isMobile ? 18 : 24}
        color="gray.600">
        {normalizeDate(updated_at, `dd/MM/yyyy`)}
      </Text>
      <VStack mt={1} ml={3} space={isMobile ? 2 : 4} alignItems={isMobile ? 'center' : 'initial'}>
        <HStack
          space={10}
          flexDir={isMobile ? 'column' : 'row'}
          mb={isMobile ? 3 : 0}
          alignItems={isMobile ? 'center' : 'initial'}>
          <Text
            fontSize={isMobile ? 12 : 16}
            fontWeight={400}
            lineHeight={isMobile ? 18 : 24}
            color="gray.900"
            w={isMobile ? 'full' : '20%'}
            mb={isMobile ? 2 : 0}>
            Qualidade do produto
          </Text>
          <HStack>
            <Rating score={Number(quality)} size={isMobile ? 6 : 8} viewScore={isMobile} />
          </HStack>
        </HStack>
        <HStack
          space={10}
          flexDir={isMobile ? 'column' : 'row'}
          mb={isMobile ? 3 : 0}
          alignItems={isMobile ? 'center' : 'initial'}>
          <Text
            fontSize={isMobile ? 12 : 16}
            fontWeight={400}
            lineHeight={isMobile ? 18 : 24}
            color="gray.900"
            w={isMobile ? 'full' : '20%'}
            mb={isMobile ? 2 : 0}>
            Conforto
          </Text>
          <HStack>
            <Rating score={Number(confort)} size={isMobile ? 6 : 8} viewScore={isMobile} />
          </HStack>
        </HStack>
        <HStack
          space={10}
          flexDir={isMobile ? 'column' : 'row'}
          mb={isMobile ? 3 : 0}
          alignItems={isMobile ? 'center' : 'initial'}>
          <Text
            fontSize={isMobile ? 12 : 16}
            fontWeight={400}
            lineHeight={isMobile ? 18 : 24}
            color="gray.900"
            w={isMobile ? 'full' : '20%'}
            mb={isMobile ? 2 : 0}>
            Tamanho
          </Text>
          <HStack>
            <Rating score={Number(size)} size={isMobile ? 6 : 8} viewScore={isMobile} />
          </HStack>
        </HStack>
      </VStack>

      <Text fontSize={16} fontWeight={400} lineHeight={24} color="gray.800" mt={3} mb={2}>
        Comentário
      </Text>

      <Text
        fontSize={isMobile ? 12 : 16}
        fontWeight={400}
        lineHeight={isMobile ? 18 : 24}
        color="gray.600">
        {review}
      </Text>
    </VStack>
  )
}

import { FunctionComponent } from 'react'

import { Illustrations } from 'atoms'
import { Text, VStack } from 'native-base'

export const OrdersEmpty: FunctionComponent = () => {
  return (
    <VStack textAlign="center" margin="auto" bg="white">
      <Text fontSize="16px" lineHeight="21px" pb="40px" fontWeight="semibold" color="gray.700">
        Você não tem pedidos.
      </Text>
      <Illustrations.EmptyFolder />
    </VStack>
  )
}

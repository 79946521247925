import { FunctionComponent, memo } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const SvgComponent: FunctionComponent<IIconProps> = (props) => (
  <Icon viewBox="0 0 499.4 518.2" {...props}>
    <Path d="M499.4 395.3H0v-50.1c52.9 0 78.4-53.8 87.8-185.8C96.2 42.4 177.9 11.3 222.1 3V1l24.4-1h3.8c1.2-.1 2.6 0 2.5 0l24.2.8v2.1c44.2 8.2 126.1 39.3 134.5 156.4 9.4 131.9 34.9 185.8 87.8 185.8v50.2zM93.2 345.1h313c-35.4-52.5-41.2-134-44.6-182.2C354.6 65.5 282 51.7 251 50.2l-1.4-.1-1.3.1C217.5 51.7 144.8 65.5 137.8 163c-3.4 48.1-9.2 129.6-44.6 182.1zM249.7 518.2c-50.4 0-91.4-41-91.4-91.4h50.1c0 22.8 18.5 41.3 41.3 41.3s41.3-18.5 41.3-41.3h50.1c0 50.4-41 91.4-91.4 91.4z" />
  </Icon>
)

export const BellIcon = memo(SvgComponent)

import { useGetNotificationsQuery } from 'integration/resources/notifications'
import { useBreakpointValue } from 'native-base'

import { UseNotificationsScreen } from './NotificationsScreen.types'

export const useNotificationsScreen: UseNotificationsScreen = () => {
  const { data, isLoading } = useGetNotificationsQuery()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  return { isMobile, notifications: data?.data, isLoading }
}

import { extendTheme } from 'native-base'

import { colors } from './colors'
import { ActionsheetContent } from './components/actionsheet'
import { Badge } from './components/badge'
import { Button } from './components/button'
import { Container } from './components/container'
import { FormControlLabel, FormControlErrorMessage } from './components/form-control'
import { Heading } from './components/heading'
import { Input } from './components/input'
import { Select } from './components/select'
import { Switch } from './components/switch'
import { Text } from './components/text'
import { sizes } from './sizes'
import { spacing } from './space'
import { typography } from './typography'

export const allThemeConfig = {
  colors,
  components: {
    Badge,
    ActionsheetContent,
    Button,
    Container,
    FormControlLabel,
    FormControlErrorMessage,
    Heading,
    Input,
    Select,
    Switch,
    Text
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light'
  },
  sizes,
  space: spacing,
  ...typography
}

export const theme = extendTheme(allThemeConfig)

// Extend the internal NativeBase Theme
type CustomThemeType = typeof theme

declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const KeyIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M1.44922 15.8136C1.44922 17.1286 1.97162 18.3898 2.9015 19.3197C3.83138 20.2496 5.09257 20.772 6.40762 20.772C7.72267 20.772 8.98386 20.2496 9.91374 19.3197C10.8436 18.3898 11.366 17.1286 11.366 15.8136C11.366 14.4985 10.8436 13.2373 9.91374 12.3074C8.98386 11.3776 7.72267 10.8552 6.40762 10.8552C5.09257 10.8552 3.83138 11.3776 2.9015 12.3074C1.97162 13.2373 1.44922 14.4985 1.44922 15.8136V15.8136Z"
      stroke="currentColor"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      key={Math.random()}
      d="M9.91406 12.3072L18.7509 3.47038C18.9061 3.31576 19.1162 3.22894 19.3353 3.22894C19.5543 3.22894 19.7645 3.31576 19.9197 3.47038L22.5597 6.11038"
      stroke="currentColor"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      key={Math.random()}
      d="M15.8301 6.39117L18.4581 9.02157"
      stroke="currentColor"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
)

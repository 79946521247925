import * as serializers from './serializers'
import { ProfileData, ResumeData } from './types'
import client from '../../client'

export const getProfile = async () => {
  const response = await client.get<ProfileData>(`v1/user/profile`)

  return serializers.getProfile(response)
}

export const getResume = () => client.get<ResumeData>('v1/user/resume')

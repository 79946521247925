import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useLinkTo } from '@react-navigation/native'
import { useResetPasswordMutation } from 'integration/resources/users'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'
import validationFormFields from 'src/common/validationFormFields'
import * as Yup from 'yup'

import {
  FeedbackModal,
  TFormData,
  UseRecoveryPasswordCreatePasswordScreen
} from './RecoveryPasswordCreatePasswordScreen.types'

const schema = Yup.object({
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'As senhas não conferem')
    .required('Campo confirmar a senha é obrigatório'),
  password: Yup.string()
    .min(8, 'Senha deve conter no mínimo 8 caracteres')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*@#!+=%^])[0-9a-zA-Z$*@#!+=%^]{9,}$/,
      'Senha não atinge os requisitos mínimos\nSímbolos sugeridos: @#!$%^+='
    )
    .required(validationFormFields.required)
})

export const useRecoveryPasswordCreatePasswordScreen: UseRecoveryPasswordCreatePasswordScreen = ({
  navigation,
  route
}) => {
  const { id, confirm_token } = route.params ?? {}

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { mutateAsync } = useResetPasswordMutation()

  const linkTo = useLinkTo()

  const [feedbackModal, setFeedbackModal] = useState<FeedbackModal>({
    isOpen: false,
    isSuccess: false
  })

  const { control, handleSubmit } = useForm<TFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      password_confirmation: ''
    }
  })

  const handleFeedbackClose = () => {
    setFeedbackModal({ isOpen: false, isSuccess: false })

    linkTo('/entrar')
  }

  const handleSubmitForm = handleSubmit(async (values) => {
    try {
      await mutateAsync({ ...values, id, confirm_token })

      if (isMobile) {
        navigation.navigate('RecoveryPasswordCreatePasswordFeedback', { isSuccess: '1' })
      } else {
        setFeedbackModal({ isOpen: true, isSuccess: true })
      }
    } catch (error) {
      if (isMobile) {
        navigation.navigate('RecoveryPasswordCreatePasswordFeedback', { isSuccess: '0' })
      } else {
        setFeedbackModal({ isOpen: false, isSuccess: false })
      }
    }
  })

  useEffect(() => {
    if (!route.params?.id || !route.params?.confirm_token) {
      navigation.navigate('Login')
    }
  }, [route.params, navigation])

  return {
    handleSubmitForm,
    isMobile,
    control,
    feedbackModal,
    handleFeedbackClose
  }
}

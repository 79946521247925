import { useEffect, useMemo, useRef, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigation } from '@react-navigation/native'
import { Address, useGetAddressQuery, useSaveAddressMutation } from 'integration/resources/address'
import { useGetByCEP } from 'integration/resources/brazil-api'
import { useBreakpointValue } from 'native-base'
import { useForm } from 'react-hook-form'
import { MaskService } from 'react-native-masked-text'
import * as Yup from 'yup'

import { UseAddressFormScreen } from './AddressFormScreen.types'

const states = [
  { id: 'AC', title: 'Acre' },
  { id: 'AL', title: 'Alagoas' },
  { id: 'AP', title: 'Amapá' },
  { id: 'AM', title: 'Amazonas' },
  { id: 'BA', title: 'Bahia' },
  { id: 'CE', title: 'Ceará' },
  { id: 'DF', title: 'Distrito Federal' },
  { id: 'ES', title: 'Espírito Santo' },
  { id: 'GO', title: 'Goiás' },
  { id: 'MA', title: 'Maranhão' },
  { id: 'MT', title: 'Mato Grosso' },
  { id: 'MS', title: 'Mato Grosso do Sul' },
  { id: 'MG', title: 'Minas Gerais' },
  { id: 'PA', title: 'Pará' },
  { id: 'PB', title: 'Paraíba' },
  { id: 'PR', title: 'Paraná' },
  { id: 'PE', title: 'Pernambuco' },
  { id: 'PI', title: 'Piauí' },
  { id: 'RJ', title: 'Rio de Janeiro' },
  { id: 'RN', title: 'Rio Grande do Norte' },
  { id: 'RS', title: 'Rio Grande do Sul' },
  { id: 'RO', title: 'Rondônia' },
  { id: 'RR', title: 'Roraima' },
  { id: 'SC', title: 'Santa Catarina' },
  { id: 'SP', title: 'São Paulo' },
  { id: 'SE', title: 'Sergipe' },
  { id: 'TO', title: 'Tocantins' }
]

const schema = Yup.object({
  postal_code: Yup.string().max(8, '*CEP inválido').required('*CEP inválido'),
  number: Yup.string().max(80, '*Digite no máximo 80 caracteres').required('*Campo obrigatório'),
  street: Yup.string().max(80, '*Digite no máximo 80 caracteres').required('*Campo obrigatório'),
  state: Yup.string().max(80, '*Digite no máximo 80 caracteres').required('*Campo obrigatório'),
  city: Yup.string().max(80, '*Digite no máximo 80 caracteres').required('*Campo obrigatório'),
  district: Yup.string().max(80, '*Digite no máximo 80 caracteres').required('*Campo obrigatório')
})

export const useAddressFormScreen: UseAddressFormScreen = ({ route }) => {
  const navigation = useNavigation()

  const { params } = route

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const firstAddress = !useGetAddressQuery().data?.data.count

  const { data: dataAddress } = useGetAddressQuery()

  const addresses = useMemo(() => dataAddress?.data.results ?? [], [dataAddress])

  const currentAddress = addresses.find((item) => item.id === params?.id)

  const [dataEdit, setDataEdit] = useState<Address | undefined>(
    addresses.find((item) => item.id === params?.id)
  )

  useEffect(() => {
    setDataEdit(currentAddress)
  }, [currentAddress])

  const { setValue, watch, control, handleSubmit, formState, reset, setError, clearErrors } =
    useForm<Address>({
      // @ts-ignore
      resolver: yupResolver(schema),
      defaultValues: dataEdit
    })

  useEffect(() => {
    if (dataEdit && !formState.isDirty) {
      reset({
        street: dataEdit.street ?? '',
        state: states.find((state) => state.id === dataEdit.state)?.id ?? '',
        city: dataEdit.city ?? '',
        district: dataEdit.district ?? '',
        postal_code: dataEdit.postal_code ?? '',
        complement: dataEdit.complement ?? '',
        number: dataEdit.number ?? ''
      })
    }
  }, [dataEdit, formState.isDirty, reset])

  const [withoutNumber, setWithoutNumber] = useState(false)

  const handleSetWithoutNumber = (newValue: boolean) => {
    setWithoutNumber(newValue)

    clearErrors('number')

    return newValue ? setValue('number', 'SN') : setValue('number', '')
  }

  const saveAddressMutation = useSaveAddressMutation()

  const submit = handleSubmit((values) => {
    saveAddressMutation.mutate(
      { ...values, id: params?.id },
      {
        onSuccess: () => {
          navigation.goBack()
        }
      }
    )
  })

  const [invalidCEP, setInvalidCEP] = useState(false)

  const postal_code = MaskService.toRawValue('zip-code', watch('postal_code') ?? '')

  const getByCEP = useGetByCEP(postal_code)

  const fetchDataCep = getByCEP.data?.data

  const refCEPTimeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (postal_code?.length === 8) {
      if (refCEPTimeout.current) {
        clearTimeout(refCEPTimeout.current)
      }

      refCEPTimeout.current = setTimeout(getByCEP.refetch, 300)
    }
  }, [getByCEP.refetch, postal_code])

  useEffect(() => {
    if (!postal_code?.length || getByCEP.isError) {
      setError('postal_code', { type: 'validate' }, { shouldFocus: true })

      setInvalidCEP(true)

      setValue('street', '')

      setValue('number', '')

      setValue('district', '')

      setValue('state', '')

      setValue('city', '')
    }
  }, [postal_code, setValue, getByCEP.isError, setError])

  useEffect(() => {
    if (fetchDataCep?.cep) {
      clearErrors()

      setValue('postal_code', fetchDataCep.cep.replace('-', '') ?? '')

      setValue('street', fetchDataCep.logradouro ?? '')

      setValue('state', states.find((state) => state.id === fetchDataCep.uf)?.id ?? '')

      setValue('number', '')

      setValue('city', fetchDataCep.localidade ?? '')

      setValue('district', fetchDataCep.bairro ?? '')

      setInvalidCEP(false)
    }
  }, [fetchDataCep, clearErrors, setValue])

  const handleGoBack = () => {
    navigation?.goBack()
  }

  return {
    isMobile,
    handleGoBack,
    dataEdit,
    control,
    getByCEP,
    withoutNumber,
    handleSetWithoutNumber,
    firstAddress,
    invalidCEP,
    isLoading: saveAddressMutation.isLoading,
    submit
  }
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const RemoveIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12.2C0 11.5373 0.537258 11 1.2 11H22.8C23.4627 11 24 11.5373 24 12.2C24 12.8627 23.4627 13.4 22.8 13.4H1.2C0.537258 13.4 0 12.8627 0 12.2Z"
      fill="currentColor"
    />
  </Icon>
)

import { FunctionComponent, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { useChangeOrderMutation } from 'integration/resources/orders/hooks'
import { Order } from 'integration/resources/orders/types'
import { Actionsheet, Box, Button, Flex, Heading, HStack, Text, VStack } from 'native-base'
import { ORDER_CANCELED } from 'src/constants/order'
import { normalizeDate } from 'src/utils/normalizeDates'

import { OrdersDetails } from './OrdersDetails'

type Props = {
  order: Order
}

export const OrdersEstimatedDelivery: FunctionComponent<Props> = ({ order }) => {
  const linkTo = useLinkTo()

  const { mutate, isLoading } = useChangeOrderMutation()

  const [modalCancelOrder, setModalCancelOrder] = useState(false)

  const normalizeTotalItems = (total_items: number) => {
    return total_items > 1 ? `${total_items} itens` : `${total_items} item`
  }

  const handleCancelOrder = () => {
    mutate(
      {
        id: order.id,
        status: ORDER_CANCELED
      },
      {
        onError: () => {
          setModalCancelOrder(false)

          linkTo('/erro')
        },
        onSuccess: () => {
          setModalCancelOrder(false)
        }
      }
    )
  }

  return (
    <VStack
      textAlign="center"
      margin="auto"
      bg="white"
      w="full"
      borderRadius={4}
      padding={4}
      shadow={1}
      mb={15}>
      <HStack justifyContent="space-between" pb={2}>
        <Flex>
          <Text
            fontSize={{ base: '12px', lg: '16px' }}
            lineHeight={{ base: '18px', lg: '24px' }}
            pb="4px"
            color="gray.500">
            Previsão de entrega
          </Text>

          <Heading
            fontSize={{ base: '18px', lg: '24px' }}
            lineHeight={{ base: '20px', lg: '36px' }}
            fontWeight="bold"
            color={{ base: 'gray.800', lg: 'gray.700' }}
            textAlign={{ lg: 'left' }}>
            {normalizeDate(order.delivery_forecast, "dd 'de' MMMM'")}
          </Heading>
        </Flex>

        <Flex justifyContent="space-between">
          {/* @ts-ignore */}
          <Flex gap={2} alignItems="center" flexDirection="row">
            <Text
              fontSize={{ base: '12px', lg: '16px' }}
              lineHeight={{ base: '18px', lg: '24px' }}
              color="gray.500"
              mr={2}>
              A caminho
            </Text>

            <Icons.OrderOnTheWay size={4} />
          </Flex>
          <Box />
        </Flex>
      </HStack>

      <HStack justifyContent="space-between" pb={4}>
        <HStack alignItems="center" flexDirection="row" space={2}>
          <Heading
            fontSize={{ base: '14px', lg: '16px' }}
            lineHeight={{ base: '18px', lg: '24px' }}
            fontWeight="semibold"
            color={{ base: 'gray.800', lg: 'gray.700' }}>
            Total do pedido:
          </Heading>

          <Heading
            fontSize={{ base: '14px', lg: '16px' }}
            lineHeight={{ base: '18px', lg: '24px' }}
            fontWeight="semibold"
            color="red.500">
            {order.total} PTS
          </Heading>
        </HStack>

        <Heading
          fontSize={{ base: '14px', lg: '16px' }}
          lineHeight={{ base: '18px', lg: '24px' }}
          color={{ base: 'gray.800', lg: 'gray.700' }}
          fontWeight="semibold">
          {normalizeTotalItems(order.total_items)}
        </Heading>
      </HStack>

      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        // @ts-ignore
        gap={{ lg: 20 }}
        justifyContent={{ lg: 'space-between' }}
        alignItems={{ lg: 'center' }}>
        <OrdersDetails
          products={order.order_products}
          location={order.address}
          orderId={order.order_id}
          status={order.status}
          cancelOrder={() => setModalCancelOrder(true)}
          reviewed={false}
        />
      </Flex>

      <Actionsheet
        isOpen={modalCancelOrder}
        onClose={() => setModalCancelOrder(false)}
        hideDragIndicator>
        <Actionsheet.Content>
          <Box
            bg="white"
            px="24px"
            pt="32px"
            flexDirection="column"
            justifyContent="center"
            w="full"
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px">
            <Heading
              color="gray.700"
              fontSize="28px"
              lineHeight="48px"
              mb={4}
              fontWeight="bold"
              textAlign="center">
              Cancelar pedido
            </Heading>

            <Text color="gray.700" fontSize="16px" lineHeight="24px" textAlign="center" px={12}>
              Você tem certeza que deseja cancelar o pedido?
            </Text>
            <Text color="gray.700" fontSize="16px" lineHeight="24px" mb="40px" textAlign="center">
              Esta ação não poderá ser desfeita.
            </Text>

            <HStack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              space={4}
              mb="60px">
              <Button
                onPress={() => setModalCancelOrder(false)}
                disabled={isLoading}
                colorScheme="gray.500"
                flex="1"
                variant="outline">
                Fechar
              </Button>

              <Button onPress={handleCancelOrder} isLoading={isLoading} flex="1" variant="outline">
                Confirmar
              </Button>
            </HStack>
          </Box>
        </Actionsheet.Content>
      </Actionsheet>
    </VStack>
  )
}

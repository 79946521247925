import { FunctionComponent } from 'react'

import { Box, Button, IButtonProps } from 'native-base'

export const ButtonGradient: FunctionComponent<IButtonProps & { disabledGradient?: boolean }> = ({
  disabledGradient,
  w,
  h,
  flex,
  bg,
  ...props
}) => (
  <Box
    borderRadius="full"
    bgColor={
      disabledGradient
        ? {}
        : {
            linearGradient: {
              colors: ['#F63B78', '#FE393D'],
              start: [0, 0],
              end: [1, 0]
            }
          }
    }
    w={w}
    h={h}
    flex={flex}>
    <Button {...props} h={h} w={w} bg={disabledGradient ? bg : 'transparent'} />
  </Box>
)

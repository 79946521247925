import { FunctionComponent } from 'react'

import { Button, HStack, Text } from 'native-base'

type TCounterProductProps = {
  value: number
  handleAdd(): void
  handleRemove(): void
  total: number
}

export const CounterProduct: FunctionComponent<TCounterProductProps> = ({
  value,
  handleAdd,
  handleRemove,
  total
}) => {
  return (
    <HStack
      borderColor="gray.200"
      rounded="50px"
      flex={1}
      mr={3}
      justifyContent="space-between"
      alignItems="center"
      borderWidth="2px">
      <Button
        variant="ghost"
        _text={{ color: value - 1 > 0 ? 'red.500' : 'gray.300', fontSize: '20px' }}
        borderRadius={0}
        borderTopLeftRadius="50px"
        isDisabled={value === 0}
        onPress={handleRemove}
        _hover={{
          bg: 'gray.100'
        }}
        borderBottomLeftRadius="50px">
        -
      </Button>
      <Text textAlign="center">{`${value}`}</Text>
      <Button
        borderRadius={0}
        borderTopRightRadius="50px"
        borderBottomRightRadius="50px"
        isDisabled={value === total}
        _disabled={{
          _text: {
            color: 'gray.300'
          }
        }}
        onPress={handleAdd}
        _hover={{
          bg: 'gray.100'
        }}
        variant="ghost">
        +
      </Button>
    </HStack>
  )
}

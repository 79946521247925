import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { Box, Button } from 'native-base'
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from 'react-native-reanimated'

import { slides, SLIDE_WIDTH } from '../useOnboardingScreen'

type AnimatedButtonProps = {
  onPress(): void
  scrollX: Animated.SharedValue<number>
  disabled: boolean
}

const AnimatedBox = Animated.createAnimatedComponent(Box)

export const AnimatedIconButton: FunctionComponent<AnimatedButtonProps> = ({
  onPress,
  scrollX,
  disabled
}) => {
  const animatedArrowIconStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollX.value,
      slides.map((_, index) => index * SLIDE_WIDTH),
      slides.map((_, index) => (index === slides.length - 1 ? 0 : 1)),
      Extrapolate.CLAMP
    ),
    transform: [
      // @TODO temporarly, because in new version not working correctly type
      // @ts-ignore
      {
        scale: interpolate(
          scrollX.value,
          slides.map((_, index) => index * SLIDE_WIDTH),
          slides.map((_, index) => (index === slides.length - 1 ? 0 : 1)),
          Extrapolate.CLAMP
        )
      }
    ]
  }))

  const animatedCheckIconStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollX.value,
      slides.map((_, index) => index * SLIDE_WIDTH),
      slides.map((_, index) => (index === slides.length - 1 ? 1 : 0)),
      Extrapolate.CLAMP
    ),
    transform: [
      // @TODO temporarly, because in new version not working correctly type
      // @ts-ignore
      {
        scale: interpolate(
          scrollX.value,
          slides.map((_, index) => index * SLIDE_WIDTH),
          slides.map((_, index) => (index === slides.length - 1 ? 1 : 0)),
          Extrapolate.CLAMP
        )
      }
    ]
  }))

  const colorButton = disabled ? 'gray.200' : 'primary.500'

  return (
    <Button
      alignItems="center"
      variant="solid"
      borderRadius="full"
      h={14}
      bg={colorButton}
      isDisabled={disabled}
      _focus={{ bg: 'primary.500' }}
      startIcon={
        <Box size={6} position="relative">
          <AnimatedBox style={[animatedArrowIconStyle, { position: 'absolute' }]}>
            <Icons.Arrow color="white" direction="right" size={6} />
          </AnimatedBox>
          <AnimatedBox style={[animatedCheckIconStyle, { position: 'absolute' }]}>
            <Icons.Check color="white" size={6} />
          </AnimatedBox>
        </Box>
      }
      justifyContent="center"
      onPress={onPress}
      w={14}
    />
  )
}

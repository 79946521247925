import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const LinkIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.84655 9.95026C7.31586 10.4182 7.31698 11.178 6.84904 11.6473L2.76761 15.7407C2.30611 16.2021 2.30611 16.9972 2.76761 17.4585L2.76914 17.4601L6.54078 21.2437C6.54096 21.2439 6.54061 21.2435 6.54078 21.2437C7.00216 21.7047 7.79742 21.705 8.25855 21.2437L12.3507 17.1515C12.8194 16.6829 13.5792 16.6829 14.0478 17.1515C14.5164 17.6201 14.5164 18.3799 14.0478 18.8486L9.95598 22.9404C9.95592 22.9404 9.95604 22.9403 9.95598 22.9404C8.55731 24.3395 6.24194 24.3396 4.84335 22.9404L1.07092 19.1559C1.07064 19.1557 1.07036 19.1554 1.07008 19.1551C-0.328063 17.7566 -0.328048 15.4426 1.07013 14.0441C1.07039 14.0439 1.07066 14.0436 1.07092 14.0433L5.14949 9.95276C5.61743 9.48344 6.37723 9.48232 6.84655 9.95026Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0437 1.07092C15.4424 -0.327536 17.7571 -0.327269 19.1555 1.07172L22.94 4.8562C24.3465 6.2623 24.3325 8.57682 22.94 9.96876L18.8469 14.0498C18.3776 14.5178 17.6178 14.5167 17.1498 14.0473C16.6819 13.578 16.683 12.8182 17.1523 12.3503L21.2433 8.27135C21.2434 8.27122 21.2431 8.27149 21.2433 8.27135C21.7031 7.8112 21.7065 7.01669 21.2432 6.55352L17.4583 2.76859C16.997 2.30709 16.2016 2.3069 15.7403 2.76841L11.6469 6.84983C11.1776 7.31777 10.4178 7.31666 9.94984 6.84734C9.4819 6.37802 9.48301 5.61823 9.95233 5.15029L14.0429 1.07172C14.0432 1.07145 14.0434 1.07118 14.0437 1.07092Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4477 7.55148C16.9164 8.02011 16.9164 8.77991 16.4477 9.24854L9.24775 16.4485C8.77912 16.9172 8.01932 16.9172 7.55069 16.4485C7.08206 15.9799 7.08206 15.2201 7.55069 14.7515L14.7507 7.55148C15.2193 7.08285 15.9791 7.08285 16.4477 7.55148Z"
      fill="currentColor"
    />
  </Icon>
)

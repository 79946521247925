import { useGetCategoriesQuery } from 'integration/resources/categories'

export const useCategoryList = () => {
  const { data, isLoading } = useGetCategoriesQuery()

  return {
    data,
    isLoading
  }
}

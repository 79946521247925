import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const MailIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M20.3992 2.40002H3.59922C2.27374 2.40002 1.19922 3.47454 1.19922 4.80002V19.2C1.19922 20.5255 2.27374 21.6 3.59922 21.6H20.3992C21.7247 21.6 22.7992 20.5255 22.7992 19.2V4.80002C22.7992 3.47454 21.7247 2.40002 20.3992 2.40002Z"
      stroke="currentColor"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <Path
      key={Math.random()}
      d="M1.19922 4.80005L10.4512 12.6072C10.8842 12.9727 11.4326 13.1732 11.9992 13.1732C12.5659 13.1732 13.1142 12.9727 13.5472 12.6072L22.7992 4.80005"
      stroke="currentColor"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
)

import { Suspense } from 'react'

import { Spinner } from 'native-base'

import { OnboardingScreenDesktop } from './OnboardingScreen.desktop'
import { OnboardingScreenMobile } from './OnboardingScreen.mobile'
import { OnboardingScreenComponent } from './OnboardingScreen.types'
import { useOnboardingScreen } from './useOnboardingScreen'

export const OnboardingScreen: OnboardingScreenComponent = ({ navigation }) => {
  const onboardingScreen = useOnboardingScreen({ navigation })

  const Screen = onboardingScreen.isMobile ? OnboardingScreenMobile : OnboardingScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...onboardingScreen} />
    </Suspense>
  )
}

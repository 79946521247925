import { MaskService } from 'react-native-masked-text'

export const validateAuthCode = (value: string) => value.length === 6 || 'Código inválido'

export const validateCNPJ = (value: string) =>
  (MaskService.isValid('cnpj', value) && value !== '00000000000000') || 'CNPJ inválido'

const validateMin8char = (value: string) => value.length >= 8

const validateMin1charLower = (value: string) => !!value.match(new RegExp('^(?=.*[A-Z]).*$'))

const validateMin1charUpper = (value: string) => !!value.match(new RegExp('^(?=.*[a-z]).*$'))

const validateMin1charSymbol = (value: string) => !!value.match(new RegExp('^(?=.*[@#!$%^&+=]).*$'))

const validateMin1Number = (value: string) => !!value.match(new RegExp('^(?=.*[0-9]).*$'))

export const validatePassword = (value: string) =>
  validateMin8char(value) &&
  validateMin1charLower(value) &&
  validateMin1charUpper(value) &&
  validateMin1charSymbol(value) &&
  validateMin1Number(value)

export const validatePasswordMessage = (value: string) => ({
  'Minimo 8 caracteres': value ? validateMin8char(value) : null,
  'Pelo menos 1 letra maiúscula': value ? validateMin1charLower(value) : null,
  'Pelo menos 1 letra minúscula': value ? validateMin1charUpper(value) : null,
  'Pelo menos 1 símbolo': value ? validateMin1charSymbol(value) : null,
  'Pelo menos 1 número': value ? validateMin1Number(value) : null
})

export const validatePhone = (value: string) =>
  MaskService.toRawValue('cel-phone', value).length === 13 || 'Telefone inválido'

export const validateEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  ) || 'Digite um e-mail válido'

export const maskedEmail = (email: string) => {
  if (!email || !/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return email
  }

  const censorWord = function (str = '') {
    return str[0] + '*'.repeat(str.length - 2) + str.slice(-1)
  }

  const arr = email.split('@')

  return censorWord(arr[0]) + '@' + censorWord(arr[1])
}

export const validateName = (value: string) =>
  // eslint-disable-next-line no-useless-escape
  /^([a-zA-Zà-úÀ-Ú]{2,})+\s+([a-zA-Zà-úÀ-Ú\s]{2,})+$/.test(value) || 'Digite um nome válido'

import { useLinkTo } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'

import { UseNotFoundScreen } from './NotFoundScreen.types'

export const useNotFoundScreen: UseNotFoundScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const handleGoToHome = () => linkTo('/inicio')

  return {
    isMobile,
    handleGoToHome
  }
}

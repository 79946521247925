import { BackButton, Icons } from 'atoms'
import { Box, Button, Container, FormControl, Heading, Input, VStack } from 'native-base'
import { Controller } from 'react-hook-form'
import { Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Animated, { Layout } from 'react-native-reanimated'
import { RootStackScreenComponent } from 'src/navigation'
import { validateName } from 'src/utils'

import { useRegisterScreen } from './useRegisterScreen'

export const RegisterNameScreen: RootStackScreenComponent<'RegisterName'> = ({
  navigation,
  route
}) => {
  const { control, isLoading, watch, navigateToEmail } = useRegisterScreen({ navigation, route })

  const nameValue: string = watch('name') as string

  return (
    <KeyboardAwareScrollView style={{ flex: 1 }}>
      <VStack flex={1} justifyContent="center" pt={Platform.OS === 'ios' ? 0 : 4} p={5}>
        <Box safeArea h="full">
          <Box pt={4} pb={8}>
            <BackButton linkTo="/entrar" />
          </Box>

          <Container py={8} px={1}>
            <VStack w="full">
              <Controller
                control={control}
                rules={{
                  required: 'Digite seu nome',
                  validate: (value) => value && validateName(value)
                }}
                render={({
                  field: { onChange: onChangeText, onBlur, value },
                  formState: { errors }
                }) => (
                  <FormControl isInvalid={!!errors.name} isRequired mb={15}>
                    <Heading
                      fontSize="24px"
                      fontWeight="bold"
                      lineHeight={{ base: '30px', lg: '28px' }}
                      mb={10}>
                      Digite seu nome
                    </Heading>

                    <Input
                      {...{ onChangeText, onBlur, value }}
                      autoCapitalize="none"
                      autoCorrect={false}
                      enablesReturnKeyAutomatically
                      onSubmitEditing={navigateToEmail}
                      placeholder="Nome"
                      InputLeftElement={<Icons.Profile size={5} color="gray.700" ml={4} />}
                    />
                    {errors.name && (
                      <FormControl.ErrorMessage fontSize="16px" lineHeight="24px" mt={15}>
                        {errors.name.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
                name="name"
              />

              <Animated.View layout={Layout.springify()}>
                <Button
                  isLoading={isLoading}
                  isDisabled={!(nameValue?.length > 0)}
                  _disabled={{ opacity: 1 }}
                  onPress={navigateToEmail}
                  w="full">
                  Confirmar nome
                </Button>
              </Animated.View>
            </VStack>
          </Container>
        </Box>
      </VStack>
    </KeyboardAwareScrollView>
  )
}

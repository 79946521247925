import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

type Direction = 'up' | 'down' | 'left' | 'right'

const getPath = (direction: Direction) => {
  switch (direction) {
    case 'up':
      return (
        <Path
          key={Math.random()}
          d="M12.4745 0.536621C12.134 0.537505 11.7976 0.610576 11.4873 0.751025C11.1771 0.891473 10.9 1.09611 10.6745 1.35147L0.835506 12.5421C0.599115 12.8312 0.484063 13.2008 0.514597 13.5732C0.545131 13.9455 0.718865 14.2914 0.999187 14.538C1.27951 14.7846 1.64452 14.9127 2.0173 14.8951C2.39007 14.8776 2.7415 14.7159 2.99751 14.4441L10.5975 5.7981C10.6311 5.76018 10.6754 5.73338 10.7246 5.72123C10.7738 5.70908 10.8254 5.71216 10.8728 5.73007C10.9202 5.74797 10.961 5.77986 10.9899 5.8215C11.0188 5.86315 11.0343 5.91258 11.0345 5.96327L11.0345 23.0951C11.0345 23.4774 11.1862 23.8441 11.4563 24.1144C11.7263 24.3847 12.0926 24.5366 12.4745 24.5366C12.8564 24.5366 13.2227 24.3847 13.4927 24.1144C13.7628 23.8441 13.9145 23.4774 13.9145 23.0951L13.9145 5.96628C13.915 5.91561 13.9308 5.86627 13.9598 5.82474C13.9888 5.78321 14.0296 5.75143 14.077 5.73356C14.1244 5.7157 14.176 5.71259 14.2252 5.72464C14.2744 5.7367 14.3187 5.76335 14.3525 5.8011L21.9525 14.4471C22.0745 14.5983 22.2258 14.7233 22.3973 14.8144C22.5688 14.9056 22.7569 14.9611 22.9504 14.9776C23.1438 14.9942 23.3386 14.9713 23.5231 14.9105C23.7075 14.8498 23.8777 14.7523 24.0236 14.6239C24.1694 14.4956 24.2878 14.339 24.3716 14.1637C24.4554 13.9884 24.503 13.7979 24.5114 13.6037C24.5198 13.4095 24.4889 13.2157 24.4205 13.0337C24.3521 12.8518 24.2477 12.6856 24.1135 12.5451L14.2745 1.35247C14.0491 1.09683 13.7722 0.891937 13.4619 0.751305C13.1516 0.610674 12.8151 0.537503 12.4745 0.536621Z"
          fill="currentColor"
        />
      )

    case 'left':
      return (
        <Path
          key={Math.random()}
          d="M0.511719 12.5733C0.512603 12.9138 0.585674 13.2503 0.726122 13.5605C0.866571 13.8708 1.07121 14.1478 1.32657 14.3733L12.5172 24.2123C12.8063 24.4487 13.1759 24.5638 13.5483 24.5333C13.9206 24.5027 14.2665 24.329 14.5131 24.0487C14.7597 23.7683 14.8878 23.4033 14.8702 23.0306C14.8527 22.6578 14.691 22.3064 14.4192 22.0503L5.7732 14.4503C5.73528 14.4167 5.70848 14.3724 5.69633 14.3233C5.68418 14.2741 5.68726 14.2224 5.70517 14.175C5.72307 14.1277 5.75496 14.0868 5.7966 14.058C5.83824 14.0291 5.88768 14.0135 5.93837 14.0133L23.0702 14.0133C23.4525 14.0133 23.8192 13.8616 24.0895 13.5916C24.3598 13.3215 24.5117 12.9553 24.5117 12.5733C24.5117 12.1914 24.3598 11.8252 24.0895 11.5551C23.8192 11.2851 23.4525 11.1333 23.0702 11.1333L5.94137 11.1333C5.89071 11.1329 5.84137 11.1171 5.79984 11.0881C5.75831 11.0591 5.72652 11.0182 5.70866 10.9708C5.6908 10.9235 5.68769 10.8718 5.69974 10.8226C5.7118 10.7735 5.73845 10.7291 5.7762 10.6953L14.4222 3.09534C14.5734 2.97334 14.6984 2.82205 14.7895 2.65056C14.8807 2.47908 14.9362 2.29095 14.9527 2.09748C14.9693 1.90402 14.9464 1.70921 14.8856 1.52478C14.8249 1.34035 14.7274 1.17011 14.599 1.02429C14.4707 0.878467 14.3141 0.760081 14.1388 0.676249C13.9635 0.592416 13.773 0.544869 13.5788 0.536467C13.3846 0.528065 13.1908 0.558982 13.0088 0.627357C12.8269 0.695733 12.6607 0.800155 12.5202 0.934345L1.32757 10.7733C1.07193 10.9987 0.867034 11.2757 0.726403 11.5859C0.585772 11.8962 0.512601 12.2328 0.511719 12.5733Z"
          fill="currentColor"
        />
      )

    case 'down':
      return (
        <Path
          d="M18.029 10.4364C18.3528 10.0965 18.3399 9.5585 18.0001 9.23462C17.6603 8.91074 17.1223 8.92366 16.7984 9.26348L12.0137 14.2836L7.22897 9.26348C6.90508 8.92366 6.36705 8.91074 6.02723 9.23462C5.68741 9.5585 5.67449 10.0965 5.99837 10.4364L10.9736 15.6564C10.978 15.661 10.9825 15.6656 10.9871 15.6702C11.1218 15.8052 11.2818 15.9124 11.4579 15.9855C11.6341 16.0586 11.8229 16.0962 12.0137 16.0962C12.2044 16.0962 12.3932 16.0586 12.5694 15.9855C12.7456 15.9124 12.9056 15.8052 13.0403 15.6702C13.0448 15.6656 13.0493 15.661 13.0538 15.6564L18.029 10.4364Z"
          fill="currentColor"
        />
      )

    case 'right':
      return (
        <Path
          key={Math.random()}
          d="M24.5117 12.4999C24.5108 12.1594 24.4378 11.8229 24.2973 11.5127C24.1569 11.2024 23.9522 10.9254 23.6969 10.6999L12.5062 0.860897C12.2171 0.624506 11.8475 0.509454 11.4752 0.539987C11.1028 0.570521 10.7569 0.744256 10.5103 1.02458C10.2637 1.3049 10.1357 1.66991 10.1532 2.04269C10.1707 2.41546 10.3324 2.76689 10.6042 3.0229L19.2502 10.6229C19.2882 10.6565 19.315 10.7008 19.3271 10.75C19.3393 10.7991 19.3362 10.8508 19.3183 10.8982C19.3004 10.9456 19.2685 10.9864 19.2268 11.0153C19.1852 11.0442 19.1358 11.0597 19.0851 11.0599H1.95322C1.57091 11.0599 1.20426 11.2116 0.933925 11.4817C0.663591 11.7517 0.511719 12.118 0.511719 12.4999C0.511719 12.8818 0.663591 13.2481 0.933925 13.5181C1.20426 13.7882 1.57091 13.9399 1.95322 13.9399H19.0821C19.1327 13.9404 19.1821 13.9562 19.2236 13.9852C19.2651 14.0142 19.2969 14.055 19.3148 14.1024C19.3326 14.1498 19.3357 14.2014 19.3237 14.2506C19.3116 14.2998 19.285 14.3441 19.2472 14.3779L10.6012 21.9779C10.45 22.0999 10.3251 22.2512 10.2339 22.4227C10.1427 22.5942 10.0872 22.7823 10.0707 22.9758C10.0542 23.1692 10.077 23.364 10.1378 23.5485C10.1986 23.7329 10.2961 23.9031 10.4244 24.049C10.5528 24.1948 10.7093 24.3132 10.8846 24.397C11.0599 24.4808 11.2504 24.5284 11.4446 24.5368C11.6388 24.5452 11.8327 24.5143 12.0146 24.4459C12.1965 24.3775 12.3627 24.2731 12.5032 24.1389L23.6959 14.2999C23.9515 14.0745 24.1564 13.7976 24.297 13.4873C24.4377 13.177 24.5108 12.8405 24.5117 12.4999Z"
          fill="currentColor"
        />
      )
  }
}

export const ArrowIcon: FunctionComponent<
  IIconProps & { direction: 'up' | 'down' | 'left' | 'right' }
> = ({ direction, ...props }) => (
  <Icon {...{ viewBox: '0 0 25 25', ...props }}>{getPath(direction)}</Icon>
)

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AlertIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.4C10.4087 2.4 8.88258 3.03214 7.75736 4.15736C6.63214 5.28258 6 6.8087 6 8.4V16.7976H18V8.4C18 6.8087 17.3679 5.28258 16.2426 4.15736C15.1174 3.03214 13.5913 2.4 12 2.4ZM20.4 16.7976V8.4C20.4 6.17218 19.515 4.03561 17.9397 2.4603C16.3644 0.884997 14.2278 0 12 0C9.77218 0 7.63561 0.884997 6.0603 2.4603C4.485 4.03561 3.6 6.17218 3.6 8.4V16.7976H1.2C0.537258 16.7976 0 17.3349 0 17.9976C0 18.6603 0.537258 19.1976 1.2 19.1976H4.72346C4.74877 19.1992 4.77429 19.2 4.8 19.2H19.2C19.2257 19.2 19.2512 19.1992 19.2765 19.1976H22.8C23.4627 19.1976 24 18.6603 24 17.9976C24 17.3349 23.4627 16.7976 22.8 16.7976H20.4ZM9.6 22.7976C9.6 22.1349 10.1373 21.5976 10.8 21.5976H13.2C13.8627 21.5976 14.4 22.1349 14.4 22.7976C14.4 23.4603 13.8627 23.9976 13.2 23.9976H10.8C10.1373 23.9976 9.6 23.4603 9.6 22.7976Z"
      fill="currentColor"
    />
  </Icon>
)

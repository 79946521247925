import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { CategoriesData } from './types'
import { ResponseError } from '../../types'

export const useGetCategoriesQuery = () =>
  useQuery<AxiosResponse<CategoriesData>, AxiosError<ResponseError>>(
    'getCategories',
    requests.getCategories,
    { onError: () => {} }
  )

import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { useGetCategoriesQuery } from 'integration/resources/categories'
import { NotificationsButton } from 'molecules'
import {
  Box,
  Center,
  HStack,
  Link,
  Text,
  useBreakpointValue,
  Pressable,
  Heading,
  Image,
  Menu
} from 'native-base'
import { Platform } from 'react-native'
import { useCheckoutAtomValue } from 'src/store/checkout'

type THeaderDesktopProps = {
  active?: string
}

export const HeaderDesktop: FunctionComponent<THeaderDesktopProps> = ({ active }) => {
  const isVisible = useBreakpointValue({ base: false, lg: true })

  const checkoutAtomString = useCheckoutAtomValue()

  const { data } = useGetCategoriesQuery()

  const categories = data?.data.results

  const checkoutAtom =
    typeof checkoutAtomString === 'string' ? JSON.parse(checkoutAtomString) : checkoutAtomString

  const linkTo = useLinkTo()

  if (!isVisible) {
    return <></>
  }

  const menuList = [
    {
      id: 'produtos/todos',
      label: 'Produtos'
    },
    {
      id: 'pedidos',
      label: 'Pedidos'
    },
    {
      id: 'extrato',
      label: 'Extrato'
    }
  ]

  return (
    <Center bg="background.light" w="full">
      <HStack flex={1} w="full" justifyContent="space-between" maxW="1280px" m="auto">
        <HStack>
          <Center flexDirection="row">
            <Link
              onPress={() => linkTo(`/inicio`)}
              //@ts-ignore
              cursor="pointer">
              <Image source={require('assets/logo.png')} alt="Lojinha" w={132} minH={51} />
            </Link>

            <Box w="1px" h={12} ml={12} bgColor="#E6E8EC" />
          </Center>
          <Box w="169px">
            <Menu
              w="169px"
              marginTop={-6}
              py={6}
              borderRadius={10}
              bg="background.light"
              borderWidth={0}
              style={{
                shadowOffset: {
                  width: 0,
                  height: -4
                },
                shadowRadius: 4,
                shadowColor: 'rgba(0, 0, 0, 0.02)'
              }}
              top={0}
              trigger={(triggerProps) => (
                <Pressable accessibilityLabel="More options menu" {...triggerProps}>
                  <HStack>
                    <Text
                      color="gray.500"
                      fontWeight={500}
                      fontSize={16}
                      lineHeight={24}
                      pl={10}
                      pr={2}
                      py={6}>
                      Categorias
                    </Text>

                    <Icons.Arrow color="gray.500" direction="down" size={6} my={6} />
                  </HStack>
                </Pressable>
              )}>
              {categories?.map((category) => (
                <Menu.Item
                  key={category.id}
                  onPress={() => linkTo(`/produtos/${category.slug}`)}
                  alignItems="center"
                  py={2}
                  _hover={{
                    backgroundColor: '#F5F6F7'
                  }}
                  _focus={{
                    backgroundColor: '#F5F6F7'
                  }}>
                  <Text
                    color={active === category.slug?.toLowerCase() ? 'primary.500' : 'gray.500'}
                    fontWeight={500}
                    fontSize={16}
                    lineHeight={24}>
                    {category.name}
                  </Text>
                </Menu.Item>
              ))}
            </Menu>
          </Box>

          {menuList.map((item) => (
            <Pressable key={item.id} onPress={() => linkTo(`/${item.id}`)}>
              <Text
                color={active === item.label ? 'primary.500' : 'gray.500'}
                fontWeight={500}
                fontSize={16}
                px={6}
                py={6}>
                {item.label}
              </Text>
            </Pressable>
          ))}
        </HStack>

        <Center flexDirection="row">
          <Pressable
            onPress={() => linkTo(`/perfil`)}
            borderWidth={2}
            borderColor="gray.200"
            rounded="full"
            flexDirection="row"
            alignItems="center"
            py={1}
            px={4}>
            <Icons.Profile color="gray.700" size={4} mr={2} />
            <Text color="gray.700" fontWeight={500} fontSize={16}>
              Meu Perfil
            </Text>
          </Pressable>

          <Box ml={6} mt="1px">
            <NotificationsButton color="gray.500" />
          </Box>

          <Pressable onPress={() => linkTo('/carrinho')} position="relative">
            <Icons.Shopping color="gray.500" size={Platform.OS === 'web' ? 7 : 5} ml={6} mr={2} />
            <Center
              bg="primary.500"
              borderColor="primary.500"
              borderRadius="full"
              borderWidth={1}
              h={5}
              position="absolute"
              top="0"
              right="1px"
              w={5}>
              <Heading
                color="white"
                fontSize="12px"
                fontWeight="bold"
                lineHeight="15px"
                pl="1px"
                position="relative"
                bottom="1px">
                {checkoutAtom?.total?.items ?? 0}
              </Heading>
            </Center>
          </Pressable>
        </Center>
      </HStack>
    </Center>
  )
}

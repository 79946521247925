import Analytics from '@react-native-firebase/analytics'
import { Platform } from 'react-native'

export const LogEvent = (name: string, params?: { [key: string]: any }) => {
  // @ts-ignore
  const { item_id } = params

  if (Platform.OS === 'web') {
    Analytics().logEvent('select_content', {
      content_type: name.toLowerCase(),
      item_id: String(item_id)
    })
  } else {
    Analytics().logSelectContent({
      content_type: name.toLowerCase(),
      item_id: String(item_id)
    })
  }
}

export const LogEventUserId = (id: string | null) => {
  Analytics().setUserId(`${id}`)
}

export const LogEventUserProperties = (params: { [key: string]: any }) => {
  Analytics().setUserProperties(params)
}

export const LogEventScreen = (params: { [key: string]: any }) => {
  if (Platform.OS !== 'web') {
    Analytics().logScreenView(params)
  } else {
    Analytics().logEvent('screen_view', params)
  }
}

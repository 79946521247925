import { FunctionComponent } from 'react'

import Svg, { Path } from 'react-native-svg'

export const ImageMask: FunctionComponent = () => (
  <Svg width="100%" height="100%" viewBox="0 0 331 426" fill="none" preserveAspectRatio="none ">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M331 0H0V426H331V0ZM11.7002 11.7031C2 21.4062 2 37.0234 2 68.2578V355.766C2 389.61 2 406.534 12.7539 416.383C23.5078 426.231 40.3594 424.743 74.0625 421.767L268.589 404.585C297.274 402.052 311.616 400.785 320.308 391.288C329 381.792 329 367.39 329 338.585V68.2578C329 37.0234 329 21.4062 319.3 11.7031C309.6 2 293.987 2 262.763 2H68.2373C37.0127 2 21.4004 2 11.7002 11.7031Z"
      fill="white"
      stroke="white"
      strokeWidth={1}
    />
  </Svg>
)

export const typography = {
  fontConfig: {
    Assistant: {
      200: { normal: 'Assistant_200ExtraLight' },
      300: { normal: 'Assistant_300Light' },
      400: { normal: 'Assistant_400Regular' },
      500: { normal: 'Assistant_500Medium' },
      600: { normal: 'Assistant_600SemiBold' },
      700: { normal: 'Assistant_700Bold' },
      800: { normal: 'Assistant_800ExtraBold' }
    },
    DMSans: {
      400: { normal: 'DMSans_400Regular' },
      500: { normal: 'DMSans_500Medium' },
      700: { normal: 'DMSans_700Bold' }
    }
  },
  // fontWeights: {
  //   hairline: 100,
  //   thin: 200,
  //   light: 300,
  //   normal: 400,
  //   medium: 500,
  //   semibold: 600,
  //   bold: 700,
  //   extrabold: 800,
  //   black: 900,
  //   extraBlack: 950,
  // },
  fonts: {
    heading: 'Assistant',
    body: 'DMSans',
    mono: 'DMSans'
  }
}

import { FunctionComponent, useMemo } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Category } from 'integration/resources/categories'
import { Box, FlatList, Button } from 'native-base'

import { useCategoryList } from './useCategoryFilterList'

type Props = {
  category: string
  isLoading: boolean
}

export const CategoryFilterList: FunctionComponent<Props> = ({ category, isLoading }) => {
  const linkTo = useLinkTo()

  const { data: catories } = useCategoryList()

  const categoryList = useMemo<Category[]>(
    () => [
      {
        id: '0',
        created_at: '',
        updated_at: '',
        name: 'Todos',
        description: '',
        image_url: '',
        image_name: '',
        slug: 'todos'
      },
      ...(catories?.data.results ?? [])
    ],
    [catories?.data.results]
  )

  return (
    <FlatList
      data={categoryList}
      horizontal
      keyExtractor={(item) => `${item.id}`}
      renderItem={({ item }) => {
        return (
          <Box mr={2}>
            <Button
              variant="solid"
              disabled={isLoading}
              bg={item.slug === category ? 'primary.500' : 'background.base'}
              h="30px"
              py={1}
              onPress={() => linkTo(`/produtos/${item.slug}`)}
              textAlign="center"
              _text={{
                color: item.slug === category ? 'white' : 'gray.800',
                fontSize: '12px',
                fontWeight: 'bold',
                lineHeight: '16px'
              }}
              _pressed={{
                _text: { color: 'white' }
              }}>
              {item.name}
            </Button>
          </Box>
        )
      }}
      showsHorizontalScrollIndicator={false}
    />
  )
}

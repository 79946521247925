import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { HomeScreenComponent } from './HomeScreen.types'
import { useHomeScreen } from './useHomeScreen'

const HomeScreenDesktop = lazy(() => import('./HomeScreen.desktop'))

const HomeScreenMobile = lazy(() => import('./HomeScreen.mobile'))

export const HomeScreen: HomeScreenComponent = () => {
  const props = useHomeScreen()

  const Screen = props.isMobile ? HomeScreenMobile : HomeScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...props} />
    </Suspense>
  )
}

import { FunctionComponent, useEffect, useState } from 'react'

import { Button, VStack } from 'native-base'
import { Provider } from 'src/integration/resources/onboarding'

type Props = {
  onSelect(provider: Provider | undefined): void
  userIsConsultant?: boolean
}

const providers: {
  key: Provider
  title: string
}[] = [
  { key: 'Bens e Serviços', title: 'SIM Consumer' },
  { key: 'Consultores Leves e Pesados', title: 'Consultores Veículos Leves e Pesados' },
  { key: 'Veículos Santander', title: 'Veículos Santander' }
]

export const ProviderSelector: FunctionComponent<Props> = ({ onSelect, userIsConsultant }) => {
  const [provider, setProvider] = useState<Provider | undefined>(undefined)

  useEffect(() => {
    onSelect(provider)
  }, [provider, onSelect])

  return (
    <VStack space={4}>
      {(userIsConsultant
        ? providers.filter((providerItem) => providerItem.key !== 'Veículos Santander')
        : providers
      ).map((providerItem) => {
        return (
          <Button
            {...(provider === providerItem.key
              ? {}
              : {
                  disabledGradient: true,
                  bg: 'background.base',
                  _text: { color: 'gray.600' },
                  _hover: { bg: 'gray.200' },
                  _pressed: { bg: 'primary.500', _text: { color: 'white' } }
                })}
            key={providerItem.key}
            onPress={() => setProvider(providerItem.key)}>
            {providerItem.title}
          </Button>
        )
      })}
    </VStack>
  )
}

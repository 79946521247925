import { useEffect, useState } from 'react'

import { BellIcon } from 'atoms/Icons/BellIcon'
import { Box, Center, Heading, Pressable, Skeleton } from 'native-base'

import { useNotificationsButton } from './useNotificationsButton'

export type NotificationsButtonProps = {
  color?: string
}

export const NotificationsButton = ({ color }: NotificationsButtonProps) => {
  const { handleOpenNotifications, notificationsCount, isLoading } = useNotificationsButton()

  const [helperLoading, setHelperLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setHelperLoading(false)
    }, 1000)
  }, [])

  return (
    <Box>
      {helperLoading || isLoading ? (
        <Skeleton h="25px" w="24px" rounded="8px" />
      ) : (
        <Pressable onPress={handleOpenNotifications}>
          {Number(notificationsCount?.unread_notifications) > 0 && (
            <Center
              bg="red.500"
              borderColor="primary.500"
              w={5}
              h={5}
              borderWidth={1}
              borderRadius="full"
              position="absolute"
              right="-8px"
              top="-2px"
              zIndex={1}>
              <Heading
                color="white"
                fontSize="12px"
                fontWeight="bold"
                lineHeight="15px"
                pl="1px"
                position="relative"
                bottom="1px">
                {notificationsCount?.unread_notifications}
              </Heading>
            </Center>
          )}

          <BellIcon color={color} size={6} />
        </Pressable>
      )}
    </Box>
  )
}

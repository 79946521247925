import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { DisconnectedScreenComponent } from './DisconnectedScreen.types'
import { useDisconnectedScreen } from './useDisconnectedScreen'

const DisconnectedScreenDesktop = lazy(() => import('./DisconnectedScreen.desktop'))

const DisconnectedScreenMobile = lazy(() => import('./DisconnectedScreen.mobile'))

export const DisconnectedScreen: DisconnectedScreenComponent = ({ navigation, route }) => {
  const disconnectedScreen = useDisconnectedScreen({ navigation, route })

  const Screen = disconnectedScreen.isMobile ? DisconnectedScreenMobile : DisconnectedScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...disconnectedScreen} />
    </Suspense>
  )
}

import { BackButton } from 'atoms'
import { Box, KeyboardAvoidingView, VStack } from 'native-base'
import { Platform } from 'react-native'

import { FormLogin } from './FormLogin'
import { ResponsiveLoginScreenComponent } from './LoginScreen.types'

export const LoginScreenMobile: ResponsiveLoginScreenComponent = ({
  control,
  setRememberEmail,
  submit,
  error,
  handleRedirectToRecoveryPassword,
  isLoading,
  isMobile
}) => {
  return (
    <KeyboardAvoidingView {...(Platform.OS === 'android' ? {} : { behavior: 'padding' })} flex={1}>
      <VStack flex={1} justifyContent="center" pt={Platform.OS === 'ios' ? 0 : 4} p={5}>
        <Box safeArea h="full">
          <Box pt={4} pb={8}>
            <BackButton linkTo="/bem-vindo" />
          </Box>

          <FormLogin
            control={control}
            setRememberEmail={setRememberEmail}
            submit={submit}
            error={error}
            isLoading={isLoading}
            isMobile={isMobile}
            handleRedirectToRecoveryPassword={handleRedirectToRecoveryPassword}
          />
        </Box>
      </VStack>
    </KeyboardAvoidingView>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CloudUploadIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1944 0.00147936C9.47424 -0.0298565 10.7443 0.231761 11.9076 0.766343C13.0708 1.30093 14.0965 2.09433 14.9063 3.08594C15.4074 3.69956 15.8176 4.37904 16.1265 5.10331C16.9103 4.84592 17.7441 4.75122 18.5761 4.83169C19.9136 4.96105 21.169 5.53569 22.1411 6.46351C23.1132 7.39133 23.7457 8.61862 23.9372 9.9487C24.1287 11.2788 23.8682 12.6347 23.1973 13.799C22.8665 14.3733 22.1328 14.5706 21.5585 14.2398C20.9843 13.9089 20.7869 13.1752 21.1178 12.601C21.5203 11.9023 21.6766 11.0888 21.5617 10.2907C21.4468 9.4927 21.0673 8.75633 20.484 8.19963C19.9008 7.64294 19.1475 7.29816 18.345 7.22054C17.5425 7.14292 16.7371 7.33696 16.058 7.77155C15.7299 7.98152 15.3203 8.01916 14.9594 7.87251C14.5985 7.72585 14.3313 7.41315 14.2426 7.03383C14.0346 6.14336 13.6257 5.31224 13.0473 4.60394C12.469 3.89565 11.7363 3.32894 10.9054 2.94709C10.0745 2.56525 9.16731 2.37838 8.25314 2.40076C7.33897 2.42314 6.442 2.65419 5.63078 3.07624C4.81955 3.49829 4.11553 4.10018 3.5725 4.83593C3.02946 5.57168 2.66179 6.42182 2.49756 7.3214C2.33333 8.22097 2.37689 9.14619 2.62492 10.0264C2.87294 10.9065 3.31887 11.7184 3.92864 12.3998C4.37056 12.8937 4.32843 13.6523 3.83454 14.0943C3.34064 14.5362 2.58202 14.4941 2.14009 14.0002C1.28642 13.0461 0.662122 11.9095 0.314884 10.6773C-0.0323549 9.44508 -0.0933432 8.14978 0.136578 6.89037C0.3665 5.63096 0.881247 4.44076 1.6415 3.41071C2.40174 2.38067 3.38738 1.53802 4.52309 0.947146C5.6588 0.356275 6.91455 0.0328153 8.1944 0.00147936ZM11.1522 12.3515C11.6209 11.8828 12.3807 11.8828 12.8493 12.3515L16.4493 15.9515C16.9179 16.4201 16.9179 17.1799 16.4493 17.6485C15.9807 18.1172 15.2209 18.1172 14.7522 17.6485L13.2008 16.0971V22.8C13.2008 23.4627 12.6635 24 12.0008 24C11.338 24 10.8008 23.4627 10.8008 22.8V16.0971L9.24929 17.6485C8.78066 18.1172 8.02086 18.1172 7.55224 17.6485C7.08361 17.1799 7.08361 16.4201 7.55224 15.9515L11.1522 12.3515Z"
      fill="currentColor"
    />
  </Icon>
)

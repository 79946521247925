import { FunctionComponent, useCallback } from 'react'

import { Box, FlatList, Skeleton, useBreakpointValue, VStack } from 'native-base'

type Props = {
  vertical?: boolean
  listHeaderComponent?: React.ComponentType<any> | React.ReactElement | null | undefined
  listFooterComponent?: React.ComponentType<any> | React.ReactElement | null | undefined
}

export const ProductListPreview: FunctionComponent<Props> = ({
  vertical = false,
  listHeaderComponent,
  listFooterComponent
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const numColumns = isMobile ? 0 : 3

  const renderItem = useCallback(() => {
    return (
      <Box mr={4} mb={10}>
        <VStack w={{ base: 39, lg: '341px' }} position="relative">
          <Skeleton h={{ base: '131px', lg: 55 }} w="full" rounded={{ base: 5, lg: '10px' }} />

          <Skeleton.Text lines={2} py="4" />
        </VStack>
      </Box>
    )
  }, [])

  const keyExtractor = useCallback((item: string) => `key-${item}`, [])

  return (
    <FlatList
      overflowY="inherit"
      overflowX="inherit"
      data={['0', '1', '2', '4']}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      horizontal={vertical ? false : isMobile}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      ListHeaderComponent={listHeaderComponent}
      ListFooterComponent={listFooterComponent}
      numColumns={vertical ? 2 : numColumns}
    />
  )
}

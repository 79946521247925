import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { ChangeOrder, GetAllOrdersParams, OrdersData } from './types'
import { ResponseError } from '../../types'

export const KEY_GET_DELIVERED_ORDERS_QUERY = 'getDeliveredOrders'

export const useDeliveredOrdersQuery = (params: GetAllOrdersParams) =>
  useQuery<AxiosResponse<OrdersData>, AxiosError<ResponseError>>(
    ['getDeliveredOrders', KEY_GET_DELIVERED_ORDERS_QUERY],
    () => requests.getDeliveredOrders(params)
  )

export const useNotDeliveredOrdersQuery = () =>
  useQuery<AxiosResponse<OrdersData>, AxiosError<ResponseError>>(
    'getNotDeliveredOrders',
    requests.getNotDeliveredOrders
  )

export const useChangeOrderMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError<ResponseError>, ChangeOrder>(
    requests.pathChangeOrder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getDeliveredOrders')

        queryClient.invalidateQueries('getNotDeliveredOrders')
      }
    }
  )
}

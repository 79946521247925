import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { TrackOrderScreenComponent } from './TrackOrderScreen.types'
import { useTrackOrderScreen } from './useTrackOrderScreen'

const TrackOrderScreenDesktop = lazy(() => import('./TrackOrderScreen.desktop'))

const TrackOrderScreenMobile = lazy(() => import('./TrackOrderScreen.mobile'))

export const TrackOrderScreen: TrackOrderScreenComponent = ({ navigation, route }) => {
  const screen = useTrackOrderScreen({ navigation, route })

  const Screen = screen.isMobile ? TrackOrderScreenMobile : TrackOrderScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...screen} />
    </Suspense>
  )
}

import { Box, Button, Center, Heading, Text, VStack } from 'native-base'
import { Platform } from 'react-native'
import Animated, { Layout } from 'react-native-reanimated'
import { RootStackScreenComponent } from 'src/navigation'

import { useRegisterScreen } from './useRegisterScreen'

export const RegisterSuccessScreen: RootStackScreenComponent<'RegisterSuccess'> = ({
  navigation,
  route
}) => {
  const { navigateToSuccess } = useRegisterScreen({ navigation, route })

  return (
    <VStack flex={1} pt={Platform.OS === 'ios' ? 0 : 4} p={5}>
      <Center safeArea h="full" w="full">
        <Heading fontSize="28px" fontWeight="bold" lineHeight="40px" textAlign="center" mb={4}>
          Cadastro realizado com sucesso
        </Heading>
        <Text fontSize="16px" lineHeight="24px" textAlign="center" mb={15}>
          Agora o seu cadastro precisa ser aprovado para você realizar o login. Isso pode levar
          alguns dias. Aguarde um e-mail com maiores instruções.
        </Text>

        <Box w="full">
          <Animated.View layout={Layout.springify()}>
            <Button onPress={navigateToSuccess} w="full">
              OK
            </Button>
          </Animated.View>
        </Box>
      </Center>
    </VStack>
  )
}

import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { PrivacyPolicyScreenComponent } from './PrivacyPolicyScreen.types'
import { usePrivacyPolicyScreen } from './usePrivacyPolicyScreen'

const PrivacyPolicyScreenDesktop = lazy(() => import('./PrivacyPolicyScreen.desktop'))

const PrivacyPolicyScreenMobile = lazy(() => import('./PrivacyPolicyScreen.mobile'))

export const PrivacyPolicyScreen: PrivacyPolicyScreenComponent = ({ navigation }) => {
  const privacyPolicyScreen = usePrivacyPolicyScreen({ navigation })

  const Screen = privacyPolicyScreen.isMobile
    ? PrivacyPolicyScreenMobile
    : PrivacyPolicyScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...privacyPolicyScreen} />
    </Suspense>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ClothingIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8381 1.56539C12.961 1.20209 11.9958 1.10704 11.0647 1.29224C10.1336 1.47745 9.27835 1.93461 8.60706 2.6059C7.93577 3.27719 7.47861 4.13247 7.2934 5.06358C7.1082 5.99469 7.20325 6.95981 7.56655 7.83689C7.92985 8.71398 8.54508 9.46364 9.33444 9.99107C9.6374 10.1935 9.96079 10.3597 10.298 10.4877L1.34747 16.4088C1.29931 16.4407 1.25351 16.476 1.21043 16.5144C0.666971 16.9996 0.283484 17.6381 0.110627 18.3458C-0.0622302 19.0535 -0.0163224 19.797 0.242285 20.478C0.500893 21.1591 0.960031 21.7456 1.55904 22.1602C2.15805 22.5748 2.86874 22.7979 3.59723 22.8L3.60075 22.8L20.4031 22.8C21.1319 22.7986 21.8431 22.576 22.4426 22.1617C23.0422 21.7474 23.5019 21.1609 23.761 20.4797C24.02 19.7985 24.0662 19.0547 23.8935 18.3467C23.7207 17.6387 23.3372 16.9998 22.7935 16.5144C22.7504 16.4759 22.7045 16.4406 22.6563 16.4087L13.2012 10.1553V9.60001C13.2012 8.93727 12.6639 8.40001 12.0012 8.40001C11.5265 8.40001 11.0625 8.25926 10.6678 7.99554C10.2731 7.73183 9.96551 7.357 9.78386 6.91845C9.60221 6.47991 9.55468 5.99735 9.64729 5.5318C9.73989 5.06624 9.96847 4.6386 10.3041 4.30296C10.6398 3.96731 11.0674 3.73873 11.533 3.64613C11.9985 3.55353 12.4811 3.60105 12.9196 3.7827C13.3582 3.96435 13.733 4.27197 13.9967 4.66665C14.2604 5.06132 14.4012 5.52534 14.4012 6.00001C14.4012 6.66276 14.9384 7.20001 15.6012 7.20001C16.2639 7.20001 16.8012 6.66276 16.8012 6.00001C16.8012 5.05066 16.5197 4.12263 15.9922 3.33328C15.4648 2.54392 14.7151 1.92869 13.8381 1.56539ZM2.75648 18.3543L12.0008 12.2388L21.2475 18.3544C21.4011 18.509 21.51 18.703 21.5619 18.9156C21.6195 19.1516 21.6041 19.3995 21.5177 19.6266C21.4314 19.8537 21.2781 20.0492 21.0783 20.1873C20.8786 20.3252 20.6419 20.3994 20.3993 20.4H3.60292C3.36057 20.399 3.12419 20.3247 2.92489 20.1868C2.72522 20.0486 2.57217 19.8531 2.48597 19.626C2.39977 19.399 2.38447 19.1512 2.44208 18.9153C2.494 18.7028 2.60292 18.5089 2.75648 18.3543Z"
      fill="currentColor"
    />
  </Icon>
)

import { FunctionComponent, useCallback, useEffect, useState } from 'react'

import { Icons } from 'atoms'
import { ProductOrder } from 'integration/resources/orders/types'
import { usePostRatingMutation } from 'integration/resources/rating'
import { Rating } from 'molecules'
import {
  Actionsheet,
  useBreakpointValue,
  Modal,
  VStack,
  Button,
  Heading,
  ScrollView,
  Box,
  HStack,
  Image,
  Flex,
  Center,
  Text,
  Input,
  useToast,
  Alert,
  KeyboardAvoidingView
} from 'native-base'
import { useIsOpenOrderEvaluateAtomValue, useSetIsOpenOrderEvaluateAtom } from 'src/store/rating'

const OrderEvaluate: FunctionComponent = () => {
  const order = useIsOpenOrderEvaluateAtomValue()

  const [products, setProducts] = useState<ProductOrder[]>(order.products)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const setIsOpenOrderEvaluateAtom = useSetIsOpenOrderEvaluateAtom()

  const postRatingMutation = usePostRatingMutation()

  useEffect(() => {
    setProducts(order.products)
  }, [order.products])

  const handleRatingChange = useCallback(
    (index: number, category: string, newScore: number) => {
      setProducts((prevProducts) => {
        return prevProducts.map((product, idx) => {
          if (idx === index) {
            return {
              ...product,
              [category]: newScore
            }
          }

          return product
        })
      })
    },
    [setProducts]
  )

  const handleReviewChange = useCallback((index: number, review: string) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts]

      updatedProducts[index].review = review

      return updatedProducts
    })
  }, [])

  const handleOnEvaluate = useCallback(() => {
    const ratings = products.map(
      ({ average_quality, average_confort, average_size, review, product_id }) => ({
        quality: average_quality,
        confort: average_confort,
        size: average_size,
        review,
        product_id
      })
    )

    postRatingMutation.mutate(ratings, {
      onError: () =>
        setIsOpenOrderEvaluateAtom({ isOpen: false, products: [], responseType: 'error' }),
      onSuccess: () =>
        setIsOpenOrderEvaluateAtom({ isOpen: false, products: [], responseType: 'success' })
    })
  }, [products, postRatingMutation, setIsOpenOrderEvaluateAtom])

  const disabledButton = products.some(
    (product) =>
      product.average_quality === undefined ||
      product.average_confort === undefined ||
      product.average_size === undefined
  )

  return (
    <VStack space={0} w="full">
      <VStack space={isMobile ? 12 : 8}>
        <Heading
          fontSize={isMobile ? 16 : 24}
          lineHeight={isMobile ? '24px' : '30px'}
          textAlign={isMobile ? 'center' : 'start'}
          color="gray.800">
          Avalie seu pedido
        </Heading>

        <Box borderRadius={10} h={460}>
          <ScrollView showsVerticalScrollIndicator scrollEventThrottle={32} h={460}>
            <VStack space={8}>
              {(products ?? []).map((product, index) => (
                <VStack space={8} key={product.name}>
                  <HStack alignItems="center" space={4} key={index}>
                    <Image
                      w={{ base: '48px', lg: '64px' }}
                      h={{ base: '48px', lg: '64px' }}
                      alt={product.name}
                      source={
                        product.image ? { uri: product.image } : require('assets/not-found.png')
                      }
                      borderRadius="10px"
                    />

                    {isMobile ? (
                      <Flex alignItems="flex-start" ml={4}>
                        <HStack space={2} mb="5px">
                          <Center
                            w="17px"
                            h="17px"
                            borderRadius="full"
                            backgroundColor="gray.300"
                            pl="1px">
                            <Heading
                              fontSize="10px"
                              lineHeight="13px"
                              fontWeight="bold"
                              color="white">
                              {product.amount}
                            </Heading>
                          </Center>

                          <Text fontSize="12px" lineHeight="16px" color="gray.800">
                            {product.name}
                          </Text>
                        </HStack>

                        <Text fontSize="12px" lineHeight="18px" color="gray.500" mb="4px">
                          Tamanho: {product.size}/ Cor: {product.color}
                        </Text>

                        <Text fontSize="12px" lineHeight="18px" color="gray.700">
                          {product.price} Pontos
                        </Text>
                      </Flex>
                    ) : (
                      <HStack space={4} alignItems="center">
                        <Center
                          w="20px"
                          h="20px"
                          borderRadius="full"
                          backgroundColor="gray.300"
                          pl="1px">
                          <Heading
                            fontSize="10px"
                            lineHeight="13px"
                            fontWeight="bold"
                            color="white">
                            {product.amount}
                          </Heading>
                        </Center>

                        <Text fontSize="20px" lineHeight="30px" color="gray.900" fontWeight="bold">
                          {product.name}
                        </Text>

                        <Box borderRightWidth="1px" borderColor="gray.500" h="18px" />

                        <Text fontSize="20px" lineHeight="30px" color="gray.500">
                          Tamanho: {product.size}/ Cor: {product.color}
                        </Text>

                        <Box borderRightWidth="1px" borderColor="gray.500" h="18px" />

                        <Text fontSize="20px" lineHeight="30px" color="gray.700">
                          {product.price} Pontos
                        </Text>
                      </HStack>
                    )}
                  </HStack>

                  <VStack pl={{ lg: '80px' }} alignItems={isMobile ? 'center' : 'initial'}>
                    <HStack
                      space={10}
                      flexDir={isMobile ? 'column' : 'row'}
                      mb={isMobile ? 3 : 4}
                      alignItems={isMobile ? 'center' : 'initial'}>
                      <Text
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={24}
                        color="gray.600"
                        w={isMobile ? 'full' : '33%'}
                        mb={isMobile ? 2 : 0}>
                        Qualidade do produto
                      </Text>
                      <HStack>
                        <Rating
                          score={product.average_quality ?? 0}
                          size={isMobile ? 6 : 8}
                          viewScore={isMobile}
                          onSelectScore={(newScore) =>
                            handleRatingChange(index, 'average_quality', newScore)
                          }
                        />
                      </HStack>
                    </HStack>
                    <HStack
                      space={10}
                      flexDir={isMobile ? 'column' : 'row'}
                      mb={isMobile ? 3 : 4}
                      alignItems={isMobile ? 'center' : 'initial'}>
                      <Text
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={24}
                        color="gray.600"
                        w={isMobile ? 'full' : '33%'}
                        mb={isMobile ? 2 : 0}>
                        Conforto
                      </Text>
                      <HStack>
                        <Rating
                          score={product.average_confort ?? 0}
                          size={isMobile ? 6 : 8}
                          viewScore={isMobile}
                          onSelectScore={(newScore) =>
                            handleRatingChange(index, 'average_confort', newScore)
                          }
                        />
                      </HStack>
                    </HStack>
                    <HStack
                      space={10}
                      flexDir={isMobile ? 'column' : 'row'}
                      mb={isMobile ? 3 : 4}
                      alignItems={isMobile ? 'center' : 'initial'}>
                      <Text
                        fontSize={16}
                        fontWeight={400}
                        lineHeight={24}
                        color="gray.600"
                        w={isMobile ? 'full' : '33%'}
                        mb={isMobile ? 2 : 0}>
                        Tamanho
                      </Text>
                      <HStack>
                        <Rating
                          score={product.average_size ?? 0}
                          size={isMobile ? 6 : 8}
                          viewScore={isMobile}
                          onSelectScore={(newScore) =>
                            handleRatingChange(index, 'average_size', newScore)
                          }
                        />
                      </HStack>
                    </HStack>
                  </VStack>

                  <VStack space={2} px={{ lg: '80px' }}>
                    <Text fontSize={16} fontWeight={400} lineHeight={24} color="gray.600">
                      Gostaria de deixar um comentário?
                    </Text>

                    <Input
                      placeholder="Digite seu comentário aqui..."
                      value={product.review}
                      onChangeText={(review) => handleReviewChange(index, review)}
                    />
                  </VStack>
                </VStack>
              ))}
            </VStack>
          </ScrollView>
        </Box>
      </VStack>

      <VStack w="full" px={6} py={4} alignItems="center">
        <Button
          width={{ base: 'full', lg: '1/2' }}
          isDisabled={disabledButton}
          isLoading={postRatingMutation.isLoading}
          colorScheme="primary"
          onPress={handleOnEvaluate}>
          Avaliar pedido
        </Button>
      </VStack>
    </VStack>
  )
}

export const BottomSheetOrderEvaluate: FunctionComponent = () => {
  const [toastShown, setToastShown] = useState(false)

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const toast = useToast()

  const { isOpen, responseType } = useIsOpenOrderEvaluateAtomValue()

  const setIsOpenOrderEvaluateAtom = useSetIsOpenOrderEvaluateAtom()

  const onClose = useCallback(() => {
    setIsOpenOrderEvaluateAtom({ isOpen: false, products: [] })

    setToastShown(false)
  }, [setIsOpenOrderEvaluateAtom])

  useEffect(() => {
    if (responseType === 'success' && !toastShown) {
      toast.show({
        placement: 'top-right',
        render: () => (
          <Alert
            w="100%"
            status="success"
            px={6}
            py={3}
            borderRadius="10px"
            bg="emerald.500"
            right={5}
            bottom={40}>
            <VStack space={2} flexShrink={1} w="100%">
              <HStack flexShrink={1} space={2} justifyContent="space-between" alignItems="center">
                <HStack space={2} flexShrink={1}>
                  <Icons.Star mt={1} size={4} color="white" filled />
                  <Text fontSize={16} fontWeight={700} lineHeight={24} color="white">
                    Avaliado com sucesso!
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </Alert>
        )
      })

      setToastShown(true)
    } else if (responseType === 'error' && !toastShown) {
      toast.show({
        placement: 'top-right',
        render: () => (
          <Alert
            w="100%"
            status="error"
            px={6}
            py={3}
            borderRadius="10px"
            bg="error"
            right={5}
            bottom={40}>
            <VStack space={2} flexShrink={1} w="100%">
              <HStack flexShrink={1} space={2} justifyContent="space-between" alignItems="center">
                <HStack space={2} flexShrink={1}>
                  <Alert.Icon mt="1" color="white" />
                  <Text fontSize={16} fontWeight={700} lineHeight={24} color="white">
                    Algo deu errado, Tente novamente.
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </Alert>
        )
      })

      setToastShown(true)
    }
  }, [responseType, toast, toastShown])

  useEffect(() => {
    if (!responseType) setToastShown(false)
  }, [isOpen, responseType])

  return isDesktop ? (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content minWidth="900px" minH={700} px={8} py={12} bg="white">
        <Modal.CloseButton />
        <Modal.Body p={0}>
          <OrderEvaluate />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  ) : (
    <Actionsheet isOpen={isOpen} onClose={onClose}>
      <KeyboardAvoidingView behavior="padding" w="full">
        <Actionsheet.Content py={4} px={6} alignItems="flex-start" w="full">
          <OrderEvaluate />
        </Actionsheet.Content>
      </KeyboardAvoidingView>
    </Actionsheet>
  )
}

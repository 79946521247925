import { HStack, Skeleton, VStack } from 'native-base'

export const NotificationsSkeleton = () => {
  return (
    <VStack>
      <VStack p={6} borderBottomWidth={1} borderBottomColor="#F5F6F6">
        <HStack alignItems="center">
          <Skeleton mr={6} h="50px" w="50px" rounded="25px" />

          <HStack justifyContent="space-between" flex={1}>
            <VStack>
              <Skeleton.Text width={40} lines={1} pb={2} />

              <Skeleton.Text width={40} lines={1} pb={1} />

              <Skeleton.Text width={40} lines={1} />
            </VStack>
          </HStack>
        </HStack>
      </VStack>
      <VStack p={6} borderBottomWidth={1} borderBottomColor="#F5F6F6">
        <HStack alignItems="center">
          <Skeleton mr={6} h="50px" w="50px" rounded="25px" />

          <HStack justifyContent="space-between" flex={1}>
            <VStack>
              <Skeleton.Text width={40} lines={1} pb={2} />

              <Skeleton.Text width={40} lines={1} pb={1} />

              <Skeleton.Text width={40} lines={1} />
            </VStack>
          </HStack>
        </HStack>
      </VStack>
      <VStack p={6} borderBottomWidth={1} borderBottomColor="#F5F6F6">
        <HStack alignItems="center">
          <Skeleton mr={6} h="50px" w="50px" rounded="25px" />

          <HStack justifyContent="space-between" flex={1}>
            <VStack>
              <Skeleton.Text width={40} lines={1} pb={2} />

              <Skeleton.Text width={40} lines={1} pb={1} />

              <Skeleton.Text width={40} lines={1} />
            </VStack>
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  )
}

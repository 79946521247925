import { useCallback, useMemo, useRef, useState } from 'react'

import { Illustrations } from 'atoms'
import { useGetProfileQuery } from 'integration/resources/profile'
import { theme, useBreakpointValue } from 'native-base'
import { Dimensions, Platform } from 'react-native'
import { Gender, Provider, useOnboardingMutation } from 'src/integration/resources/onboarding'
import { useAuthAtom } from 'src/store/auth'

import { UseOnboardingScreen } from './OnboardingScreen.types'

export type Slide = {
  id: number
  description?: string
  illustration: keyof typeof Illustrations
  image?: any
  imageDesktop?: any
  title: string
}

const { width: SCREEN_WIDTH } = Dimensions.get('window')

export const RIGHT_BANNER_WIDTH = SCREEN_WIDTH >= theme.sizes.container.lg ? 710 : 493

export const SLIDE_WIDTH =
  (SCREEN_WIDTH > 1680 ? 1680 : SCREEN_WIDTH) -
  (SCREEN_WIDTH >= theme.sizes.container.lg ? RIGHT_BANNER_WIDTH : 0)

export const DESKTOP_SLIDE_CONTAINER_WIDTH = 414

export const INDEX_SLIDE_PROVIDER = 2

export const INDEX_SLIDE_GENDER = 3

export const slides: Slide[] = [
  {
    id: 1,
    description:
      Platform.OS === 'web'
        ? `Um book com itens que já fazem parte seu${'\n'}estilo pessoal.`
        : `Um book pronto para vestir o${'\n'}modelo comercial.`,
    illustration: 'Welcome',
    image: require('assets/onboarding1.png'),
    imageDesktop: require('assets/onboarding1.web.png'),
    title: `Conheça o estilo${Platform.OS === 'web' ? ' ' : '\n'}Santander`
  },
  {
    id: 2,
    description:
      'Monte seu Kit de acordo suas necessidades, é isso mesmo agora você escolhe qual e quantos itens vai resgatar do KIT.',
    illustration: 'GetInspired',
    image: require('assets/onboarding2.png'),
    imageDesktop: require('assets/onboarding2.web.png'),
    title: `Descubra nossas${Platform.OS === 'web' ? ' ' : '\n'}facilidades`
  },
  {
    id: 3,
    illustration: 'LoggedOut',
    title: 'Nos diga, por onde devemos prosseguir?',
    image: require('assets/onboarding3.png'),
    imageDesktop: require('assets/onboarding3.web.png')
  },
  {
    id: 4,
    illustration: 'ABTesting',
    title: 'Antes de começar me diga a qual catálogo de itens quer ter acesso?',
    image: require('assets/onboarding4.png'),
    imageDesktop: require('assets/onboarding4.web.png')
  }
]

export const useOnboardingScreen: UseOnboardingScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [authAtom, setAuthAtom] = useAuthAtom()

  const { data: profile } = useGetProfileQuery()

  const userIsConsultant = useMemo(
    () => profile?.data.email.split('@')[1].toLowerCase().includes('consultor') ?? false,
    [profile?.data]
  )

  const { mutate } = useOnboardingMutation()

  const refGender = useRef<Gender>()

  const refProvider = useRef<Provider>()

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const finishOnboarding = useCallback(() => {
    /**
     * NOTE: RootStackNavigator is listening for authAtom.
     * Once authAtom change its value, the RootStackNavigator will handle the transition to the Home screen.
     */

    const formData = {
      gender: refGender.current,
      segment: refProvider.current
    }

    mutate(formData, {
      onSettled: () => setAuthAtom({ ...authAtom!, first_login: false })
    })
  }, [mutate, setAuthAtom, authAtom])

  const onSelectProvider = useCallback((provider: Provider) => {
    setButtonDisabled(false)

    refProvider.current = provider
  }, [])

  const onSelectGender = useCallback((gender: Gender) => {
    setButtonDisabled(false)

    refGender.current = gender
  }, [])

  return {
    finishOnboarding,
    onSelectProvider,
    onSelectGender,
    buttonDisabled,
    setButtonDisabled,
    refGender,
    refProvider,
    userIsConsultant,
    isMobile
  }
}

import { useMemo } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { useGetAddressQuery } from 'integration/resources/address'
import { useDeviceLogoutMutation } from 'integration/resources/auth'
import { useGetProfileQuery, useGetResumeQuery } from 'integration/resources/profile'
import { useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'
import { registerForPushNotificationsAsync } from 'src/utils/device'

import { UseProfileScreen } from './ProfileScreen.types'

export const useProfileScreen: UseProfileScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const { data: profile, isLoading: profileIsLoading } = useGetProfileQuery()

  const { data: resume, isLoading: resumeIsLoading } = useGetResumeQuery()

  const queryClient = useQueryClient()

  const { data: addressData, isLoading, isRefetching } = useGetAddressQuery()

  const mainAddress = useMemo(
    () => addressData?.data.results.filter((address) => address.main)[0] ?? undefined,
    [addressData]
  )

  const setAuthAtom = useSetAuthAtom()

  const { mutate } = useDeviceLogoutMutation()

  const handleLogout = async () => {
    queryClient.invalidateQueries()

    const expoPushToken = (await registerForPushNotificationsAsync()) ?? ''

    mutate(
      { expo_push_token: expoPushToken },
      {
        onError: () => setAuthAtom(null),
        onSuccess: () => setAuthAtom(null)
      }
    )
  }

  const handleGoToNewAddress = () => linkTo('/endereco/salvar')

  const handleGoToEditAddress = (addressId: string) => linkTo(`/endereco/salvar/${addressId}`)

  const handleGoToHome = () => linkTo('/inicio')

  const handleGoToAddresses = () => linkTo('/enderecos')

  return {
    isMobile,
    profile: profile?.data,
    profileIsLoading,
    resume: resume?.data,
    resumeIsLoading,
    address: mainAddress,
    addressIsLoading: isLoading,
    addressIsRefetching: isRefetching,
    handleLogout,
    handleGoToNewAddress,
    handleGoToEditAddress,
    handleGoToHome,
    handleGoToAddresses
  }
}

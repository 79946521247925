import { FunctionComponent, useEffect, useState } from 'react'

import { Button, HStack } from 'native-base'
import { Gender } from 'src/integration/resources/onboarding'

type Props = {
  onSelect(gender: Gender | undefined): void
}

const genders: {
  key: Gender
  title: string
}[] = [
  { key: 'F', title: 'Feminino' },
  { key: 'M', title: 'Masculino' }
]

export const GenderSelector: FunctionComponent<Props> = ({ onSelect }) => {
  const [gender, setGender] = useState<Gender | undefined>(undefined)

  useEffect(() => {
    onSelect(gender)
  }, [gender, onSelect])

  return (
    <HStack space={3}>
      {genders.map((genderItem) => (
        <Button
          {...(gender === genderItem.key
            ? {}
            : {
                disabledGradient: true,
                bg: 'background.base',
                _text: { color: 'gray.600' },
                _hover: { bg: 'gray.200' },
                _pressed: { bg: 'primary.500', _text: { color: 'white' } }
              })}
          flex={1}
          key={genderItem.key}
          onPress={() => setGender(genderItem.key)}>
          {genderItem.title}
        </Button>
      ))}
    </HStack>
  )
}

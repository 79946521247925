import { ReactNode } from 'react'

import { CanceledOrderIcon } from 'atoms/Icons/CanceledOrderIcon'
import { ConfirmedOrderIcon } from 'atoms/Icons/ConfirmedOrderIcon'
import { NewsIcon } from 'atoms/Icons/NewsIcon'
import { RechargeIcon } from 'atoms/Icons/RecargeIcon'
import { Notification as NotificationType } from 'integration/resources/notifications'
import { Box, HStack, Heading, Text, VStack } from 'native-base'
import { normalizeDate } from 'src/utils/normalizeDates'

export type NotificationProps = {
  isMobile?: boolean
  notification: NotificationType
}

export const Notification = ({ isMobile, notification }: NotificationProps) => {
  const { is_read, notification_id, sent_at } = notification

  const notificationsTypes: {
    [key: string]: {
      icon: ReactNode
      color: string
    }
  } = {
    COMMUNICATION: {
      icon: <NewsIcon />,
      color: '#F5F6F6'
    },
    RECHARGE: {
      icon: <RechargeIcon />,
      color: '#E2F3EA'
    },
    CONFIRMED_ORDER: {
      icon: <ConfirmedOrderIcon />,
      color: '#F5F6F6'
    },
    CANCELED_ORDER: {
      icon: <CanceledOrderIcon />,
      color: '#F5F6F6'
    },
    UNPRODUCTIVE_ORDER: {
      icon: <CanceledOrderIcon />,
      color: '#F5F6F6'
    },
    DELIVERED_ORDER: {
      icon: <ConfirmedOrderIcon />,
      color: '#F5F6F6'
    }
  }

  return (
    <VStack
      bg={is_read ? 'white' : '#E5E5E5'}
      p={6}
      borderBottomWidth={1}
      borderBottomColor="#F5F6F6">
      <HStack alignItems="center">
        <Box
          bg={is_read ? notificationsTypes[notification_id.notification_type_enum].color : '#FFF'}
          size="50px"
          borderRadius="25px"
          mr={6}
          justifyContent="center"
          alignItems="center">
          {notificationsTypes[notification_id.notification_type_enum].icon}
        </Box>
        <HStack justifyContent="space-between" flex={1}>
          {isMobile ? (
            <>
              <VStack flex={1}>
                <Heading fontSize="17px" pb={2}>
                  {notification_id.title}
                </Heading>

                <Text pb={1}>{notification_id.description}</Text>

                <Text color="#B0B4BF" fontSize="12px">
                  {normalizeDate(sent_at, `dd/MM/yyyy 'às' HH:mm'h'`)}
                </Text>
              </VStack>
              {!is_read && (
                <Box
                  size="20px"
                  borderRadius="10px"
                  borderColor="#2F9FAF"
                  borderWidth={1}
                  justifyContent="center"
                  alignItems="center">
                  <Box size="8px" borderRadius="4px" bg="#2C9FAF" />
                </Box>
              )}
            </>
          ) : (
            <>
              <VStack>
                <Heading fontSize="20px" pb={2}>
                  {notification_id.title}
                </Heading>

                <Text fontSize="16px" pb={1}>
                  {notification_id.description}
                </Text>
              </VStack>
              <HStack alignItems="center">
                <Text color="#B0B4BF" fontSize="16px" mr={4}>
                  {normalizeDate(sent_at, `dd/MM/yyyy 'às' HH:mm'h'`)}
                </Text>

                {!is_read && (
                  <Box
                    size="20px"
                    borderRadius="10px"
                    borderColor="#2F9FAF"
                    borderWidth={1}
                    justifyContent="center"
                    alignItems="center">
                    <Box size="8px" borderRadius="4px" bg="#2C9FAF" />
                  </Box>
                )}
              </HStack>
            </>
          )}
        </HStack>
      </HStack>
    </VStack>
  )
}

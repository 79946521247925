import { FunctionComponent, useState } from 'react'

import { Icons } from 'atoms'
import { Order } from 'integration/resources/orders/types'
import { ConfirmDelivery } from 'molecules'
import { Flex, HStack, Text, VStack, Heading, Box } from 'native-base'
import { ORDER_CANCELED, ORDER_DELIVERED, ORDER_UNPRODUCTIVE } from 'src/constants/order'
import { useSetIsOpenOrderEvaluateAtom } from 'src/store/rating'
import { normalizeDate } from 'src/utils/normalizeDates'

import { OrdersDetails } from './OrdersDetails'

type Props = {
  order: Order
}

export const OrdersHistory: FunctionComponent<Props> = ({ order }) => {
  const [modalReceivedOrder, setModalReceivedOrder] = useState(false)

  const setIsOpenOrderEvaluateAtom = useSetIsOpenOrderEvaluateAtom()

  const normalizeTotalItems = (total_items: number) => {
    return total_items > 1 ? `${total_items} itens` : `${total_items} item`
  }

  const reviewed = !order.order_products.every((product) => product.reviewed)

  const flagTextError = order.status.includes(ORDER_UNPRODUCTIVE)
    ? 'Entrega Improdutiva'
    : order.status.includes(ORDER_CANCELED)
    ? 'Pedido Cancelado'
    : undefined

  return (
    <>
      <HStack flexDirection="column">
        <Heading
          fontSize={{ base: '12px', lg: '20px' }}
          lineHeight={{ base: '21px', lg: '30px' }}
          fontWeight="semibold"
          color="gray.500"
          mb={{ base: 6, lg: 10 }}
          textTransform="capitalize">
          {normalizeDate(order.delivered_at, 'cccccc dd MMMM yyyy')}
        </Heading>
      </HStack>

      <VStack
        textAlign="center"
        margin="auto"
        bg="white"
        w="100%"
        borderRadius="10px"
        padding="16px"
        style={{
          shadowColor: 'rgba(0, 0, 0, 0.2)',
          shadowOffset: {
            width: 0,
            height: 3
          },
          shadowOpacity: 0.29,
          shadowRadius: 4.65,
          elevation: 7
        }}
        mb={{ base: 15, lg: 30 }}>
        <HStack justifyContent="space-between" pb="8px">
          <Heading
            fontSize={{ base: '14px', lg: '20px' }}
            lineHeight={{ base: '18px', lg: '30px' }}
            fontWeight="semibold"
            color={{ base: 'gray.800', lg: 'gray.900' }}>
            Pedido N.#{order.order_id}
          </Heading>

          {/* @ts-ignore */}
          <Flex gap="8px" alignItems="center" flexDirection="row">
            {flagTextError && (
              <>
                <Text
                  fontSize={{ base: '12px', lg: '16px' }}
                  lineHeight={{ base: '18px', lg: '24px' }}
                  color="gray.500"
                  mr={2}>
                  {flagTextError}
                </Text>
                <Box bgColor="gray.700" borderRadius={8} p={1}>
                  <Icons.Close color="#fff" size={2} />
                </Box>
              </>
            )}

            {order.status === ORDER_DELIVERED && (
              <>
                <Text
                  fontSize={{ base: '12px', lg: '16px' }}
                  lineHeight={{ base: '18px', lg: '24px' }}
                  color="gray.500"
                  mr={2}>
                  Concluído
                </Text>
                <Icons.Done size={4} />
              </>
            )}
          </Flex>
        </HStack>

        <HStack justifyContent="space-between" pb="16px">
          {/* @ts-ignore */}
          <Flex alignItems="center" gap="4px" flexDirection="row">
            <Heading
              fontSize={{ base: '14px', lg: '20px' }}
              lineHeight={{ base: '18px', lg: '30px' }}
              fontWeight="semibold"
              color={{ base: 'gray.800', lg: 'gray.900' }}
              mr={1}>
              Total do pedido:
            </Heading>

            <Heading
              fontSize={{ base: '14px', lg: '20px' }}
              lineHeight={{ base: '18px', lg: '30px' }}
              fontWeight="semibold"
              color="red.500">
              {order.total} PTS
            </Heading>
          </Flex>

          <Heading
            fontSize={{ base: '14px', lg: '20px' }}
            lineHeight={{ base: '18px', lg: '30px' }}
            color={{ base: 'gray.800', lg: 'gray.900' }}>
            {normalizeTotalItems(order.total_items)}
          </Heading>
        </HStack>

        <OrdersDetails
          products={order.order_products}
          location={order.address}
          orderId={order.order_id}
          status={order.status}
          reviewed={reviewed}
          confirmed_at={order.confirmed_at}
          reviewedOrder={() =>
            setIsOpenOrderEvaluateAtom({ isOpen: true, products: order.order_products })
          }
          receivedOrder={() => setModalReceivedOrder(true)}
        />

        <ConfirmDelivery
          isOpen={modalReceivedOrder}
          onClose={() => setModalReceivedOrder(false)}
          orderId={order.id}
        />
      </VStack>
    </>
  )
}

import { FunctionComponent, memo } from 'react'

import { Text } from 'native-base'

import config from '../../../../app.config'

export type VersionProps = {
  color?: string
}

export const Version: FunctionComponent<VersionProps> = memo(({ color }) => (
  <Text fontSize="14px" lineHeight="22px" fontWeight={400} mt={9} mb={4} color={color}>
    Lojinha
    <Text color={color} fontWeight="bold">
      {' '}
      Financeira v.{config.version} 2023
    </Text>
  </Text>
))

Version.defaultProps = {
  color: 'gray.700'
}

import { FunctionComponent } from 'react'

import { Icons } from 'atoms'
import { Heading, HStack, useBreakpointValue } from 'native-base'

type PointsBalanceMobileProps = {
  points?: number
}

export const PointsBalanceMobile: FunctionComponent<PointsBalanceMobileProps> = ({
  points = 0
}) => {
  const isVisible = useBreakpointValue({ base: true, lg: false })

  if (!isVisible) {
    return <></>
  }

  return (
    <HStack mt={6} mb="6px" space={1} alignItems="center">
      <Icons.Store color="primary.500" size={6} />
      <Heading
        fontSize="18px"
        lineHeight="24px"
        textTransform="uppercase"
        letter-spacing="0.1px"
        color="primary.500">
        {points} PONTOS
      </Heading>
    </HStack>
  )
}

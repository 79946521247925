import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const OrderOnTheWayIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 16 16', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5 8a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0ZM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Z"
      fill="#9BD4B4"
    />
    <Path d="M4 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" fill="#409565" />
  </Icon>
)

import { Suspense, lazy } from 'react'

import { LoadingIcon } from 'atoms/Icons/LoadingIcon'

import { RecoveryPasswordCreatePasswordScreenComponent } from './RecoveryPasswordCreatePasswordScreen.types'
import { useRecoveryPasswordCreatePasswordScreen } from './useRecoveryPasswordCreatePasswordScreen'

const RecoveryPasswordCreatePasswordScreenDesktop = lazy(
  () => import('./RecoveryPasswordCreatePasswordScreen.desktop')
)

const RecoveryPasswordCreatePasswordScreenMobile = lazy(
  () => import('./RecoveryPasswordCreatePasswordScreen.mobile')
)

export const RecoveryPasswordCreatePasswordScreen: RecoveryPasswordCreatePasswordScreenComponent =
  ({ navigation, route }) => {
    const props = useRecoveryPasswordCreatePasswordScreen({ navigation, route })

    const Screen = props.isMobile
      ? RecoveryPasswordCreatePasswordScreenMobile
      : RecoveryPasswordCreatePasswordScreenDesktop

    return (
      <Suspense fallback={<LoadingIcon />}>
        <Screen {...props} />
      </Suspense>
    )
  }

import { typography } from '../typography'

export const Button = {
  defaultProps: {
    variant: 'solid',
    size: 'lg',
    borderRadius: 'full',
    cursor: 'pointer'
  },
  sizes: {
    xs: {
      h: 6,
      _text: {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '18px',
        fontFamily: typography.fontConfig.Assistant[700].normal
      }
    },
    sm: {
      h: 10,
      _text: {
        fontSize: '16px',
        fontWeight: 'semibold',
        lineHeight: '21px',
        fontFamily: typography.fontConfig.Assistant[600].normal
      }
    },
    lg: {
      h: '50px',
      _text: {
        fontSize: '16px',
        fontWeight: 'semibold',
        lineHeight: '21px',
        fontFamily: typography.fontConfig.Assistant[600].normal
      }
    }
  },
  variants: {
    solid: (props: Record<string, any>) =>
      props.colorScheme === 'primary'
        ? {
            bg: 'primary.500',
            _text: {
              color: 'white'
            },
            _disabled: {
              bg: 'gray.200'
            },
            _focus: {
              bg: 'primary.500'
            },
            _pressed: {
              bg: 'primary.500'
            },
            _loading: {
              bg: 'primary.500'
            }
          }
        : {},
    outline: (props: Record<string, any>) =>
      props.colorScheme === 'primary'
        ? {
            bg: 'white',
            borderColor: 'primary.500',
            _text: {
              color: 'primary.500'
            },
            _disabled: {
              _text: {
                color: 'gray.200'
              }
            },
            _hover: {
              borderColor: 'primary.500',
              bg: 'white',
              _text: {
                color: 'primary.500'
              }
            },
            _focus: {
              borderColor: 'primary.500',
              bg: 'white',
              _text: {
                color: 'primary.900'
              }
            },
            _pressed: {
              borderColor: 'primary.500',
              bg: 'white',
              _text: {
                color: 'primary.900'
              }
            }
          }
        : {}
  }
}

import { FC, ReactNode } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Breadcrumb as BreadcrumbNB, Link as LinkNB, Text } from 'native-base'

import { ChevronIcon } from '../Icons/ChevronIcon'

type IComposition = {
  Link: FC<TLinkProps>
  Item: FC<TItemProps>
}

export type BreadcrumbProps = {
  children: ReactNode
}

export const Breadcrumb: FC<BreadcrumbProps> & IComposition = ({ children }) => {
  return (
    <BreadcrumbNB separator={<ChevronIcon direction="right" size={2} pt="7px" px="9px" />}>
      {children}
    </BreadcrumbNB>
  )
}

type TLinkProps = {
  children: ReactNode
  linkTo: string
}

const Link: FC<TLinkProps> = ({ children, linkTo }) => {
  const _linkTo = useLinkTo()

  return (
    <BreadcrumbNB>
      <LinkNB onPress={() => _linkTo(linkTo)}>
        <Text
          fontSize="16px"
          //@ts-ignore
          cursor="pointer">
          {children}
        </Text>
      </LinkNB>
    </BreadcrumbNB>
  )
}

Breadcrumb.Link = Link

type TItemProps = {
  children: ReactNode
}

const Item: FC<TItemProps> = ({ children }) => {
  return (
    <BreadcrumbNB>
      <Text fontWeight={700} color="gray.700" fontSize="16px">
        {children}
      </Text>
    </BreadcrumbNB>
  )
}

Breadcrumb.Item = Item

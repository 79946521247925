import { AxiosError, AxiosResponse } from 'axios'
import { ResponseError } from 'integration/types'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { BannerImageData } from './types'

export const useGetAllBannerImagesQuery = () =>
  useQuery<AxiosResponse<BannerImageData>, AxiosError<ResponseError>>(
    'getBannerImages',
    requests.getBannerImages
  )

export const Input = {
  defaultProps: {
    size: 'lg',
    variant: 'filled',
    borderRadius: 'full',
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: 'body',
    h: 14,
    px: '14px',
    py: 3,
    overflow: 'hidden'
  },
  sizes: {},
  variants: {
    filled: {
      bg: 'background.base',
      _focus: {
        borderColor: 'gray.300'
      },
      _hover: {
        bg: 'background.base'
      },
      _autofill: {
        bg: 'background.base'
      },
      _invalid: {
        borderColor: 'error'
      }
    }
  }
}

import { ReactNode } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { ChevronIcon } from 'atoms/Icons/ChevronIcon'
import { HStack, Link, Text } from 'native-base'

type IBackButton = {
  children?: ReactNode
  linkTo: string
}

export const BackButton = ({ children, linkTo }: IBackButton) => {
  const _linkTo = useLinkTo()

  return (
    <Link onPress={() => _linkTo(linkTo)}>
      {children ? (
        <HStack
          alignItems="center"
          borderWidth="1px"
          borderColor="#767E90"
          h="36px"
          pl={4}
          pr={4}
          borderRadius={20}
          //@ts-ignore
          cursor="pointer">
          <ChevronIcon direction="left" size="3" mr={4} color="gray.700" />

          <Text fontWeight={500} color="gray.700">
            {children}
          </Text>
        </HStack>
      ) : (
        <ChevronIcon direction="left" size="5" mr={4} color="gray.900" />
      )}
    </Link>
  )
}

import { Suspense, lazy } from 'react'

import { LoadingIcon } from 'atoms/Icons/LoadingIcon'

import { RecoveryPasswordCreatePasswordFeedbackScreenComponent } from './RecoveryPasswordCreatePasswordFeedbackScreen.types'
import { useRecoveryPasswordCreatePasswordFeedbackScreen } from './useRecoveryPasswordCreatePasswordFeedbackScreen'

const RecoveryPasswordSentEmailSuccesfullyDesktop = lazy(
  () => import('./RecoveryPasswordCreatePasswordFeedbackScreen.desktop')
)

const RecoveryPasswordSentEmailSuccesfullyMobile = lazy(
  () => import('./RecoveryPasswordCreatePasswordFeedbackScreen.mobile')
)

export const RecoveryPasswordCreatePasswordFeedbackScreen: RecoveryPasswordCreatePasswordFeedbackScreenComponent =
  ({ navigation, route }) => {
    const props = useRecoveryPasswordCreatePasswordFeedbackScreen({ navigation, route })

    const Screen = props.isMobile
      ? RecoveryPasswordSentEmailSuccesfullyMobile
      : RecoveryPasswordSentEmailSuccesfullyDesktop

    return (
      <Suspense fallback={<LoadingIcon />}>
        <Screen {...props} />
      </Suspense>
    )
  }

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

export const HeartIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <G clipPath="url(#clip0_12543_7585)">
      {filled ? (
        <Path
          d="M20.5537 2.55842C19.1849 1.84498 17.6077 1.63891 16.1015 1.9767C14.5953 2.31448 13.2572 3.17434 12.3241 4.40402L12.0001 4.77602L11.7193 4.45682C10.7888 3.21234 9.44679 2.33848 7.93227 1.99084C6.41776 1.64319 4.82901 1.84432 3.44891 2.55842C2.50457 3.08383 1.70087 3.82923 1.10597 4.73141C0.511074 5.63359 0.14253 6.66593 0.031596 7.74089C-0.079338 8.81584 0.0706101 9.90169 0.468723 10.9063C0.866836 11.911 1.50137 12.8048 2.31851 13.512L10.5481 21.588C10.736 21.7807 10.9605 21.9341 11.2083 22.0392C11.4561 22.1443 11.7224 22.199 11.9916 22.2001C12.2608 22.2012 12.5275 22.1487 12.7762 22.0457C13.0249 21.9427 13.2506 21.7912 13.4401 21.6L21.6169 13.572C22.3642 12.9406 22.9647 12.1537 23.3763 11.2662C23.788 10.3787 24.0009 9.41196 24.0001 8.43362C24.0089 7.23454 23.6932 6.0554 23.0865 5.0211C22.4798 3.98681 21.6046 3.13587 20.5537 2.55842V2.55842Z"
          fill="currentColor"
        />
      ) : (
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.59418 4.66045C3.9275 5.03185 3.3723 5.57504 2.98639 6.2337C2.59717 6.898 2.39461 7.6551 2.40011 8.42501L1.20014 8.43357L2.40014 8.43451C2.39965 9.06359 2.53655 9.68519 2.80128 10.2559C3.06601 10.8265 3.45218 11.3325 3.9328 11.7384C3.95581 11.7578 3.97807 11.7781 3.99955 11.7992L11.9955 19.6553L20.0012 11.7987C20.0226 11.7778 20.0447 11.7577 20.0675 11.7384C20.5481 11.3325 20.9343 10.8265 21.199 10.2559C21.4637 9.68519 21.6006 9.06359 21.6001 8.43451L22.8001 8.43357L21.6002 8.42501C21.6057 7.6551 21.4031 6.898 21.0139 6.2337C20.6281 5.57518 20.073 5.03209 19.4065 4.66069C18.5335 4.22224 17.5338 4.10498 16.5827 4.32989C15.6251 4.55633 14.7788 5.11496 14.1942 5.90649C14.1747 5.93287 14.1542 5.95843 14.1326 5.98312L12.9038 7.38952C12.6756 7.65073 12.3455 7.80041 11.9986 7.79997C11.6518 7.79953 11.322 7.64902 11.0945 7.38724L9.86568 5.97364C9.84515 5.95002 9.82555 5.92561 9.80693 5.90046C9.22154 5.10987 8.37488 4.55236 7.41733 4.32695C6.46612 4.10304 5.46666 4.22124 4.59418 4.66045ZM24.0001 8.43733C24.0002 9.41429 23.7873 10.3796 23.3761 11.2658C22.9709 12.1393 22.3828 12.9153 21.6518 13.5415L13.4474 21.5931C13.2615 21.7838 13.0398 21.9361 12.7949 22.0411C12.5422 22.1495 12.2702 22.2054 11.9953 22.2054C11.7205 22.2054 11.4484 22.1495 11.1958 22.0411C10.9508 21.936 10.729 21.7837 10.543 21.5928L2.34818 13.5413C1.6173 12.9151 1.02929 12.1392 0.62414 11.2658C0.213002 10.3796 6.39035e-05 9.41433 0.000137813 8.43739C-0.00757622 7.23683 0.308684 6.05638 0.915635 5.02045C1.52339 3.98315 2.40003 3.12927 3.45295 2.549C3.46438 2.5427 3.47591 2.53659 3.48754 2.53067C4.86995 1.82661 6.45716 1.63533 7.96725 1.9908C9.46134 2.34251 10.7842 3.20691 11.7062 4.43276L12.0024 4.77351L12.2945 4.43921C13.2153 3.21288 14.537 2.34744 16.0304 1.9943C17.5406 1.63719 19.1285 1.82708 20.5119 2.53023C20.5238 2.53629 20.5356 2.54255 20.5473 2.549C21.6002 3.12927 22.4769 3.98315 23.0846 5.02045C23.6916 6.05636 24.0078 7.23679 24.0001 8.43733Z"
          fill="currentColor"
        />
      )}
    </G>
    <Defs>
      <ClipPath id="clip0_12543_7585">
        <Rect width={24} height={24} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)

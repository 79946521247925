import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ShoppingIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.60667 3.80589C9.50684 2.90571 10.7277 2.4 12.0008 2.4C13.2738 2.4 14.4947 2.90571 15.3949 3.80589C16.2951 4.70606 16.8008 5.92696 16.8008 7.2L7.20078 7.20001V7.2C7.20078 5.92696 7.70649 4.70606 8.60667 3.80589ZM4.80078 7.20001V7.2C4.80078 5.29044 5.55935 3.45909 6.90961 2.10883C8.25987 0.758569 10.0912 0 12.0008 0C13.9103 0 15.7417 0.758569 17.0919 2.10883C18.4422 3.45909 19.2008 5.29044 19.2008 7.2L20.5278 7.20001C21.0312 7.20031 21.5291 7.31002 21.9859 7.52155C22.4426 7.73308 22.8479 8.04136 23.1737 8.42506C23.4995 8.80875 23.738 9.25868 23.8727 9.74368C24.0073 10.2287 24.035 10.7371 23.9537 11.2339L23.953 11.2379L22.3042 21.0993C22.1691 21.9079 21.7522 22.6429 21.1274 23.1738C20.5026 23.7047 19.71 23.9974 18.8901 24L18.8862 24H5.11847C4.29728 23.9991 3.50298 23.7072 2.8766 23.1761C2.25027 22.6451 1.83236 21.9094 1.69705 21.0995L0.0475754 11.2339C-0.033747 10.7372 -0.00611025 10.2287 0.128559 9.74368C0.263228 9.25868 0.501718 8.80875 0.827508 8.42506C1.1533 8.04136 1.5586 7.73308 2.01535 7.52155C2.47209 7.31002 2.97007 7.20031 3.47342 7.20001H4.80078ZM3.4738 9.60001H20.5274C20.6828 9.60015 20.8363 9.63404 20.9773 9.69934C21.1184 9.76468 21.2436 9.85992 21.3443 9.97845C21.4449 10.097 21.5186 10.236 21.5602 10.3858C21.6016 10.5351 21.6102 10.6916 21.5854 10.8445L21.5852 10.8461L19.937 20.7039C19.8953 20.9538 19.7665 21.1808 19.5735 21.3448C19.3809 21.5084 19.1367 21.5988 18.884 21.6H5.1205C4.86705 21.5996 4.62194 21.5094 4.42862 21.3455C4.23512 21.1815 4.10598 20.9539 4.06419 20.7037L2.41605 10.8461L2.41579 10.8445C2.39099 10.6916 2.39961 10.5351 2.44107 10.3858C2.48267 10.236 2.55634 10.097 2.65698 9.97845C2.75762 9.85992 2.88283 9.76468 3.02392 9.69934C3.16492 9.63404 3.31842 9.60015 3.4738 9.60001Z"
      fill="currentColor"
    />
  </Icon>
)

import axios from 'axios'

export const API_BASE_URL = process.env.API_BASE_URL

const client = axios.create({
  baseURL: API_BASE_URL,
  timeout: 80000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default client

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const LocationIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M14.8 8.4C14.8 7.07453 13.7255 6.00002 12.4 6V10.8C13.7255 10.8 14.8 9.72547 14.8 8.4Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M10 8.4C10 9.72547 11.0745 10.8 12.4 10.8V6C11.0745 6.00002 10 7.07453 10 8.4Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4885 23.5805C11.4887 23.5808 11.4889 23.5809 12.4 22.8L11.4889 23.5809C11.7169 23.8469 12.0497 24 12.4 24C12.7503 24 13.0831 23.8469 13.3111 23.5809L12.4 22.8C13.3111 23.5809 13.3113 23.5808 13.3115 23.5805L13.3135 23.5782L13.3185 23.5723C13.3227 23.5674 13.3286 23.5604 13.3362 23.5515C13.3513 23.5337 13.373 23.5079 13.401 23.4745C13.4568 23.4078 13.5375 23.3107 13.6393 23.1858C13.843 22.936 14.1319 22.5748 14.4777 22.1234C15.1682 21.2219 16.0904 19.9541 17.0146 18.4908C17.9369 17.0304 18.8741 15.3551 19.5838 13.6401C20.2885 11.937 20.8 10.1213 20.8 8.4C20.8 6.17218 19.915 4.03561 18.3397 2.4603C16.7644 0.884997 14.6278 0 12.4 0C10.1722 0 8.03561 0.884996 6.4603 2.4603C4.885 4.03561 4 6.17218 4 8.4C4 10.1213 4.51146 11.937 5.21618 13.6401C5.92586 15.3551 6.86308 17.0304 7.78541 18.4908C8.70964 19.9541 9.63181 21.2219 10.3223 22.1234C10.6681 22.5748 10.957 22.936 11.1607 23.1858C11.2625 23.3107 11.3432 23.4078 11.399 23.4745C11.427 23.5079 11.4487 23.5337 11.4638 23.5515C11.4714 23.5604 11.4773 23.5674 11.4815 23.5723L11.4865 23.5782L11.4885 23.5805ZM8.15736 4.15736C9.28258 3.03214 10.8087 2.4 12.4 2.4C13.9913 2.4 15.5174 3.03214 16.6426 4.15736C17.7679 5.28258 18.4 6.8087 18.4 8.4C18.4 9.67875 18.0115 11.163 17.3662 12.7224C16.7259 14.2699 15.8631 15.8196 14.9854 17.2092C14.1096 18.5959 13.2318 19.8031 12.5723 20.6641C12.513 20.7415 12.4555 20.8161 12.4 20.8877C12.3445 20.8161 12.287 20.7415 12.2277 20.6641C11.5682 19.8031 10.6904 18.5959 9.81459 17.2092C8.93692 15.8196 8.07414 14.2699 7.43382 12.7224C6.78854 11.163 6.4 9.67875 6.4 8.4C6.4 6.8087 7.03214 5.28258 8.15736 4.15736Z"
      fill="currentColor"
    />
  </Icon>
)

import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { Center, Box, Heading, View, Button, useBreakpointValue, HStack } from 'native-base'
import { useCheckoutAtomValue } from 'src/store/checkout'

export const ResumedCart: FunctionComponent = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const checkoutAtomString = useCheckoutAtomValue()

  const checkoutAtom =
    typeof checkoutAtomString === 'string' ? JSON.parse(checkoutAtomString) : checkoutAtomString

  if (!checkoutAtom?.total?.items || !isMobile) {
    return <View />
  }

  return (
    <Box
      bg="white"
      p={6}
      style={{
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: {
          width: 0,
          height: 3
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7
      }}>
      <Button px={6} h={14} onPress={() => linkTo('/carrinho')} _stack={{ flex: 1 }}>
        <HStack justifyContent="space-between" w="94%">
          <Box>
            <Icons.Shopping color="white" size={5} />
            <Center
              bg="white"
              borderColor="primary.500"
              borderRadius="full"
              borderWidth={1}
              h={5}
              position="absolute"
              right="-14px"
              w={5}>
              <Heading
                color="primary.500"
                fontSize="12px"
                fontWeight="bold"
                lineHeight="15px"
                pl="1px">
                {checkoutAtom?.total?.items ?? 0}
              </Heading>
            </Center>
          </Box>

          <Heading color="white" fontSize="16px" fontWeight="600">
            Ver sacola
          </Heading>

          <Heading color="white" fontSize="16px" fontWeight="600">
            {checkoutAtom?.total?.points ?? 0} pts
          </Heading>
        </HStack>
      </Button>
    </Box>
  )
}

import { FunctionComponent, useEffect, useMemo } from 'react'

import NetInfo from '@react-native-community/netinfo'
import { useLinkTo } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NotificationPush } from 'atoms'
import { SessionExpiredFeedback } from 'atoms/SessionExpiredFeedback'
import { AxiosError } from 'axios'
import client from 'integration/client'
import { useBreakpointValue, useTheme, useToast } from 'native-base'
import { Platform } from 'react-native'
import {
  CartAddressScreen,
  EntryScreen,
  LoginScreen,
  RegisterNameScreen,
  RegisterEmailScreen,
  RegisterPasswordScreen,
  OnboardingScreen,
  ProductScreen,
  ProductEditScreen,
  CartScreen,
  StatementScreen,
  AddressFormScreen,
  AddressScreen,
  PrivacyPolicyScreen,
  ErrorScreen,
  RegisterSuccessScreen,
  DisconnectedScreen,
  NotFoundScreen,
  RecoveryPasswordCreatePasswordScreen,
  RecoveryPasswordEmailScreen,
  RecoveryPasswordSentEmailSuccesfullyScreen,
  RecoveryPasswordCreatePasswordFeedbackScreen,
  NotificationsScreen,
  TrackOrderScreen
} from 'src/screens'
import { useAuthAtomValue, useSetAuthAtom } from 'src/store/auth'

import { TabNavigator } from './TabNavigator'
import { RootStackParamList } from './types'

const Stack = createNativeStackNavigator<RootStackParamList>()

export const RootStackNavigator: FunctionComponent = () => {
  const authAtom = useAuthAtomValue()

  const linkTo = useLinkTo()

  const theme = useTheme()

  const toast = useToast()

  const setAuthAtom = useSetAuthAtom()

  const screens = useMemo(() => {
    // user is logged
    if (authAtom) {
      // In the first access of the user we show the onboarding screen to him
      if (authAtom.first_login) {
        return (
          <Stack.Screen
            component={OnboardingScreen}
            name="Onboarding"
            options={{ headerShown: false }}
          />
        )
      }

      // logged screens
      return (
        <>
          <Stack.Screen component={TabNavigator} name="Tab" options={{ headerShown: false }} />
          <Stack.Screen options={{ headerShown: false }} component={AddressScreen} name="Address" />
          <Stack.Screen
            options={{ headerShown: false }}
            component={AddressScreen}
            name="CheckoutAddress"
          />
          <Stack.Screen
            options={{ headerShown: false }}
            component={AddressFormScreen}
            name="AddressForm"
          />
          <Stack.Screen
            options={
              Platform.OS !== 'web'
                ? {
                    contentStyle: { backgroundColor: theme.colors.overlay },
                    headerShown: false,
                    presentation: 'containedTransparentModal',
                    animation: 'slide_from_bottom'
                  }
                : {}
            }
            component={CartScreen}
            name="Cart"
          />
          <Stack.Screen
            options={{ headerShown: false }}
            component={CartAddressScreen}
            name="CartAddress"
          />
          <Stack.Screen
            options={{
              contentStyle: { backgroundColor: theme.colors.overlay },
              headerShown: false,
              presentation: 'containedTransparentModal',
              animation: 'slide_from_bottom'
            }}
            component={StatementScreen}
            name="Statement"
          />
          <Stack.Screen
            options={{ headerShown: false }}
            component={ProductScreen}
            name="ProductDetails"
          />
          <Stack.Screen
            options={{ headerShown: false }}
            component={ProductEditScreen}
            name="ProductEdit"
          />
          <Stack.Screen options={{ headerShown: false }} component={ErrorScreen} name="Error" />
          <Stack.Screen
            options={{ headerShown: false }}
            component={DisconnectedScreen}
            name="Disconnected"
          />
          <Stack.Screen
            options={{ headerShown: false }}
            component={NotFoundScreen}
            name="NotFound"
          />
          <Stack.Screen
            options={{ headerShown: false }}
            component={PrivacyPolicyScreen}
            name="PrivacyPolicy"
          />

          <Stack.Screen
            options={{
              title: 'Notificações',
              headerTitleAlign: 'center',
              headerShadowVisible: false
            }}
            component={NotificationsScreen}
            name="Notifications"
          />

          <Stack.Screen
            options={{ headerShown: false }}
            component={TrackOrderScreen}
            name="TrackOrder"
          />
        </>
      )
    }

    // Auth flow, entry and login screen
    return (
      <>
        <Stack.Screen options={{ headerShown: false }} component={EntryScreen} name="Entry" />
        <Stack.Screen options={{ headerShown: false }} component={LoginScreen} name="Login" />
        <Stack.Screen
          options={{ headerShown: false }}
          component={RegisterNameScreen}
          name="RegisterName"
        />
        <Stack.Screen
          options={{ headerShown: false }}
          component={RegisterEmailScreen}
          name="RegisterEmail"
        />
        <Stack.Screen
          options={{ headerShown: false }}
          component={RegisterPasswordScreen}
          name="RegisterPassword"
        />
        <Stack.Screen
          options={{ headerShown: false }}
          component={PrivacyPolicyScreen}
          name="PrivacyPolicy"
        />

        <Stack.Screen
          options={{ headerShown: false }}
          component={RegisterSuccessScreen}
          name="RegisterSuccess"
        />

        <Stack.Screen
          options={{ headerShown: false, title: 'Recuperar a senha' }}
          component={RecoveryPasswordEmailScreen}
          name="RecoveryPasswordEmail"
        />

        <Stack.Screen
          options={{ headerShown: false, title: 'Recuperar senha, e-mail enviado' }}
          component={RecoveryPasswordSentEmailSuccesfullyScreen}
          name="RecoveryPasswordSentEmailSuccesfully"
        />

        <Stack.Screen
          options={{ headerShown: false, title: 'Recuperar senha, nova senha' }}
          component={RecoveryPasswordCreatePasswordScreen}
          name="RecoveryPasswordCreatePassword"
        />
        <Stack.Screen
          options={{ headerShown: false, title: 'Recuperar senha feedback' }}
          component={RecoveryPasswordCreatePasswordFeedbackScreen}
          name="RecoveryPasswordCreatePasswordFeedback"
        />
      </>
    )
  }, [authAtom, theme])

  const headerShown = useBreakpointValue({ base: true, lg: false })

  useEffect(() => {
    client.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response?.status === 401 && !error.response?.config.url?.includes('auth')) {
          setAuthAtom(null)

          toast.show({
            placement: 'top',
            render: () => <SessionExpiredFeedback />
          })
        }

        return Promise.reject(error)
      }
    )
  }, [setAuthAtom, toast])

  useEffect(() => {
    if (Platform.OS === 'web') {
      const unsubscribe = NetInfo.addEventListener((state) => {
        if (!state.isConnected || (!state.isConnected && !state.isInternetReachable)) {
          linkTo('/sem-conexao')
        }
      })

      return unsubscribe
    }
  }, [linkTo])

  return (
    <>
      <NotificationPush />

      <Stack.Navigator
        screenOptions={{ headerShown, contentStyle: { backgroundColor: theme.colors.white } }}>
        {screens}
      </Stack.Navigator>
    </>
  )
}

import * as Device from 'expo-device'
import * as Notifications from 'expo-notifications'
import { Alert, Platform } from 'react-native'

/**
 * Get token to receive push notifications
 * @error Receive an alert
 * @returns Promise with token
 */
export const registerForPushNotificationsAsync = async () => {
  let token

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.HIGH,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C'
    })
  }

  if (Device.isDevice && Platform.OS !== 'web') {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()

    let finalStatus = existingStatus

    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()

      finalStatus = status
    }

    if (finalStatus !== 'granted') {
      console.warn('Permissão de notificações não aceita!')

      return
    }

    try {
      token = (
        await Notifications.getExpoPushTokenAsync({
          projectId: '05a14d20-9b57-4d4d-84eb-a379fc7c4cfd'
        })
      ).data
    } catch (error) {
      Alert.alert(JSON.stringify(error))
    }
  } else {
    console.warn('Para testar a notificações se faz necessário um dispositivo físico')
  }

  return token
}

export const getDeviceDescription = () => {
  let platform

  if (Platform.OS === 'web') {
    const userAgent = navigator.userAgent

    if (userAgent.match(/chrome|chromium|crios/i)) {
      platform = 'Chrome'
    } else if (userAgent.match(/firefox|fxios/i)) {
      platform = 'Firefox'
    } else if (userAgent.match(/safari/i)) {
      platform = 'Safari'
    } else if (userAgent.match(/opr\//i)) {
      platform = 'Opera'
    } else if (userAgent.match(/edg/i)) {
      platform = 'Edge'
    } else {
      platform = 'Navegador Desconhecido'
    }
  } else {
    platform = Platform.OS
  }

  return `Platform: ${platform} - Device: ${Device.brand} ${Device.modelName} - ${Device.deviceName}`
}

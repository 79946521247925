import { useCallback, useMemo, useState } from 'react'

import { differenceInDays, isEqual, parseISO, startOfDay } from 'date-fns'
import { useGetTrackOrderQuery } from 'integration/resources/track-order'
import { useBreakpointValue } from 'native-base'
import { ORDER_DELIVERED } from 'src/constants/order'

import { UseTrackOrderScreen } from './TrackOrderScreen.types'

export const useTrackOrderScreen: UseTrackOrderScreen = ({ navigation, route }) => {
  const {
    params: { orderId }
  } = route

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [modalReceivedOrder, setModalReceivedOrder] = useState(false)

  let delivered = false

  const handleGoBack = () => {
    navigation?.goBack()
  }

  const { data, isLoading } = useGetTrackOrderQuery(orderId)

  const trackOrder = useMemo(() => data?.data, [data])

  const delivered_at = trackOrder?.steps.find(
    (step) => step.name.toLowerCase() === ORDER_DELIVERED
  )?.updated_at

  const today = new Date()

  const deliveryDate = delivered_at ? parseISO(delivered_at) : undefined

  const daysPassed = deliveryDate ? differenceInDays(today, deliveryDate) : undefined

  const todayStartOfDay = startOfDay(today)

  const deliveryDateStartOfDay = deliveryDate ? startOfDay(deliveryDate) : undefined

  const deliveredToday = deliveryDateStartOfDay
    ? isEqual(todayStartOfDay, deliveryDateStartOfDay)
    : false

  if ((daysPassed !== undefined && daysPassed > 0 && daysPassed <= 7) || deliveredToday) {
    delivered = true
  }

  const onCloseModalOrder = useCallback(() => {
    setModalReceivedOrder(false)
  }, [])

  const onOpenModalOrder = useCallback(() => {
    setModalReceivedOrder(true)
  }, [])

  return {
    isLoading,
    isMobile,
    trackOrder,
    handleGoBack,
    delivered,
    modalReceivedOrder,
    onCloseModalOrder,
    onOpenModalOrder
  }
}

import { FunctionComponent } from 'react'

import { Button, Center, Heading, Text, Modal } from 'native-base'

export type CheckoutSuccessFeedbackDesktopProps = {
  isOpen: boolean
  onClose: () => void
  orderId: number
}

export const CheckoutSuccessFeedbackDesktop: FunctionComponent<
  CheckoutSuccessFeedbackDesktopProps
> = ({ isOpen, onClose, orderId }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxWidth="900px" py={12} px={35}>
        <Modal.CloseButton />
        <Modal.Body>
          <Center mb={10}>
            <Heading>Parabéns!</Heading>

            <Text pt={2} fontSize="16px" fontWeight={600} fontFamily="heading" lineHeight="21px">
              Seu pedido chegará logo.
            </Text>

            <Text pt={4} fontSize="16px" fontFamily="body" lineHeight="24px">
              O código do pedido é: #{orderId}
            </Text>
          </Center>
          <Center>
            <Button w={100} onPress={onClose}>
              Continuar comprando
            </Button>
          </Center>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

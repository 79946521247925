import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { CategoryCard } from 'molecules'
import { Box, Link, FlatList } from 'native-base'

import { useCategoryList } from './useCategoryList'
import { CategoryListPreview } from '../CategoryListPreview/CategoryListPreview'

export const CategoryList: FunctionComponent = () => {
  const linkTo = useLinkTo()

  const { data: categories, isLoading } = useCategoryList()

  return isLoading ? (
    <CategoryListPreview />
  ) : (
    <FlatList
      key={`flatlist-categories-${Math.random()}`}
      data={categories?.data.results}
      horizontal
      keyExtractor={(item) => `${item.id}`}
      renderItem={({ item, index }) => (
        <Box minW="196px" mr={categories?.data.results?.length === index + 1 ? 0 : 5}>
          <Link onPress={() => linkTo(`/produtos/${item.slug}`)}>
            <CategoryCard category={item} />
          </Link>
        </Box>
      )}
      showsHorizontalScrollIndicator={false}
    />
  )
}

import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { ProfileDataSerialized, ResumeData } from './types'
import { ResponseError } from '../../types'

export const useGetProfileQuery = () =>
  useQuery<AxiosResponse<ProfileDataSerialized>, AxiosError<ResponseError>>(
    'getProfile',
    requests.getProfile
  )

export const useGetResumeQuery = () =>
  useQuery<AxiosResponse<ResumeData>, AxiosError<ResponseError>>('getResume', requests.getResume)

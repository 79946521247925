import { useCallback } from 'react'

import { parseISO, format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useGetResumeQuery } from 'integration/resources/profile'
import { useBreakpointValue } from 'native-base'
import { useGetAllStatementsQuery } from 'src/integration/resources/statements'

import { UseStatementScreen } from './StatementScreen.types'

export const useStatementScreen: UseStatementScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const { data: allStatements, isLoading: isLoadingStatements } = useGetAllStatementsQuery()

  const { data: profileResume, isLoading: isLoadingProfile } = useGetResumeQuery()

  const normalizeOperationDate = (date: string) => {
    return format(parseISO(date), 'dd MMM', {
      locale: ptBR
    })
  }

  const handleGoBack = useCallback(() => navigation.goBack(), [navigation])

  return {
    isMobile,
    handleGoBack,
    isLoading: isLoadingStatements || isLoadingProfile,
    normalizeOperationDate,
    allStatements: allStatements?.data,
    profileResume: profileResume?.data
  }
}

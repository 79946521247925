import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { NotFoundScreenComponent } from './NotFoundScreen.types'
import { useNotFoundScreen } from './useNotFoundScreen'

const NotFoundScreenDesktop = lazy(() => import('./NotFoundScreen.desktop'))

const NotFoundScreenMobile = lazy(() => import('./NotFoundScreen.mobile'))

export const NotFoundScreen: NotFoundScreenComponent = ({ navigation, route }) => {
  const notFoundScreen = useNotFoundScreen({ navigation, route })

  const Screen = notFoundScreen.isMobile ? NotFoundScreenMobile : NotFoundScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...notFoundScreen} />
    </Suspense>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'
import { theme } from 'src/theme'

type StatementTypeIconProps = IIconProps & {
  useDefaultColor?: boolean
  type: 'in' | 'out'
}

export const StatementTypeIcon: FunctionComponent<StatementTypeIconProps> = ({
  useDefaultColor = true,
  type,
  ...props
}) => {
  switch (type) {
    case 'in': {
      return (
        <Icon {...{ viewBox: '0 0 16 16', ...props }}>
          <Path
            key={Math.random()}
            d="m.738 4.02 2.4 2.4h.01c.14.15.34.23.55.23.21 0 .42-.09.57-.23l2.4-2.4c.31-.31.31-.82 0-1.13a.785.785 0 0 0-.57-.23c-.21 0-.41.08-.57.23l-1.03 1.03V1.3c0-.44-.36-.8-.8-.8-.44 0-.8.36-.8.8v2.62l-1.04-1.03a.803.803 0 0 0-1.13 0c-.31.31-.3.82.01 1.13Z"
            fill={useDefaultColor ? '#409565' : 'currentColor'}
          />
          <Path
            key={Math.random()}
            d="M15.248 5.74V3.7c0-.63-.26-1.25-.7-1.7-.45-.45-1.06-.7-1.7-.7h-4.8c-.44 0-.8.36-.8.8 0 .44.36.8.8.8h4.8c.21 0 .42.08.57.23.15.15.23.36.23.57v2h-2.34c-.88 0-1.6.72-1.6 1.6v3.2c0 .88.72 1.6 1.6 1.6h2v1.2c0 .21-.08.42-.23.57a.81.81 0 0 1-.57.23h-9.6c-.21 0-.42-.09-.57-.23a.81.81 0 0 1-.23-.57V6.86c0-.44-.36-.8-.8-.8-.44 0-.8.36-.8.8v6.44c0 .63.26 1.25.7 1.7.45.45 1.06.7 1.7.7h9.6c.63 0 1.25-.26 1.7-.7.45-.45.7-1.06.7-1.7v-1.2c.88 0 1.6-.72 1.6-1.6V7.3c0-.76-.54-1.4-1.26-1.56Zm-.34 4.76h-3.6V7.3h3.6v3.2Z"
            fill={useDefaultColor ? '#409565' : 'currentColor'}
          />
        </Icon>
      )
    }

    case 'out': {
      return (
        <Icon {...{ viewBox: '0 0 16 16', ...props }}>
          <Path
            key={Math.random()}
            d="M14.55 4.4v-2c0-.63-.26-1.25-.7-1.7-.45-.45-1.06-.7-1.7-.7h-9.6C1.91 0 1.31.25.85.7c-.45.45-.7 1.06-.7 1.7v5.35c0 .44.36.8.8.8.44 0 .8-.36.8-.8V2.4c0-.21.08-.42.23-.57.15-.15.35-.23.57-.23h9.6c.21 0 .42.08.57.23.15.15.23.36.23.57v2h-2c-.88 0-1.6.72-1.6 1.6v3.2c0 .88.72 1.6 1.6 1.6h2V12c0 .21-.09.42-.23.57a.81.81 0 0 1-.57.23H7.98c-.44 0-.8.36-.8.8 0 .44.36.8.8.8h4.17c.63 0 1.25-.26 1.7-.7.45-.45.7-1.06.7-1.7v-1.2c.88 0 1.6-.72 1.6-1.6V6c0-.88-.72-1.6-1.6-1.6Zm0 4.8h-3.6V6h3.6v3.2Z"
            fill={useDefaultColor ? theme.colors.red[500] : 'currentColor'}
          />
          <Path
            key={Math.random()}
            d="M5.92 11.953c.15-.14.23-.34.23-.55 0-.21-.09-.42-.23-.57l-2.4-2.4a.803.803 0 0 0-1.13 0c-.16.16-.23.36-.23.57 0 .21.08.41.23.57l1.03 1.03H.8c-.44 0-.8.36-.8.8 0 .44.36.8.8.8h2.62l-1.03 1.04c-.31.31-.31.82 0 1.13.31.31.82.31 1.13 0l2.4-2.4v-.02Z"
            fill={useDefaultColor ? theme.colors.red[500] : 'currentColor'}
          />
        </Icon>
      )
    }
  }
}

import { FunctionComponent } from 'react'

import { IIconProps } from 'native-base'
import Svg, { Defs, Path, Stop } from 'react-native-svg'

export const LojinhaFinanceiraLogo: FunctionComponent<IIconProps> = (props) => (
  <Svg width="221" height="63" viewBox="0 0 221 63" fill="none">
    <Path d="M84.0985 21.4763V0.435591H88.0061V18.1699H99.0074V21.4763H84.0985Z" fill="white" />
    <Path
      d="M111.57 21.7769C109.927 21.7769 108.414 21.5064 107.031 20.9653C105.648 20.4243 104.446 19.6728 103.424 18.711C102.402 17.7291 101.611 16.5869 101.049 15.2843C100.488 13.9618 100.208 12.519 100.208 10.956C100.208 9.39293 100.488 7.96016 101.049 6.65764C101.611 5.33508 102.402 4.19286 103.424 3.231C104.446 2.2491 105.648 1.48763 107.031 0.946581C108.414 0.405534 109.917 0.13501 111.54 0.13501C113.183 0.13501 114.686 0.405534 116.048 0.946581C117.431 1.48763 118.633 2.2491 119.655 3.231C120.677 4.19286 121.469 5.33508 122.03 6.65764C122.591 7.96016 122.872 9.39293 122.872 10.956C122.872 12.519 122.591 13.9618 122.03 15.2843C121.469 16.6069 120.677 17.7491 119.655 18.711C118.633 19.6728 117.431 20.4243 116.048 20.9653C114.686 21.5064 113.193 21.7769 111.57 21.7769ZM111.54 18.3503C112.602 18.3503 113.584 18.1699 114.485 17.8092C115.387 17.4485 116.169 16.9375 116.83 16.2763C117.491 15.5949 118.002 14.8134 118.363 13.9317C118.744 13.03 118.934 12.0381 118.934 10.956C118.934 9.87386 118.744 8.89196 118.363 8.01025C118.002 7.10851 117.491 6.327 116.83 5.66572C116.169 4.9844 115.387 4.46339 114.485 4.10269C113.584 3.74199 112.602 3.56164 111.54 3.56164C110.478 3.56164 109.496 3.74199 108.594 4.10269C107.712 4.46339 106.931 4.9844 106.25 5.66572C105.588 6.327 105.067 7.10851 104.686 8.01025C104.326 8.89196 104.145 9.87386 104.145 10.956C104.145 12.018 104.326 12.9999 104.686 13.9017C105.067 14.8034 105.588 15.5949 106.25 16.2763C106.911 16.9375 107.692 17.4485 108.594 17.8092C109.496 18.1699 110.478 18.3503 111.54 18.3503Z"
      fill="white"
    />
    <Path
      d="M129.85 21.7769C128.507 21.7769 127.265 21.5164 126.122 20.9954C125 20.4744 124.078 19.7229 123.357 18.741L125.581 16.0658C126.162 16.8674 126.794 17.4686 127.475 17.8693C128.176 18.2701 128.938 18.4705 129.759 18.4705C131.944 18.4705 133.036 17.178 133.036 14.593V3.71193H125.551V0.435591H136.943V14.3826C136.943 16.8674 136.332 18.721 135.11 19.9434C133.908 21.1657 132.154 21.7769 129.85 21.7769Z"
      fill="white"
    />
    <Path d="M142.424 21.4763V0.435591H146.332V21.4763H142.424Z" fill="white" />
    <Path
      d="M152.023 21.4763V0.435591H155.239L168.435 16.637H166.842V0.435591H170.719V21.4763H167.503L154.307 5.27496H155.9V21.4763H152.023Z"
      fill="white"
    />
    <Path
      d="M191.146 0.435591H195.053V21.4763H191.146V0.435591ZM180.265 21.4763H176.357V0.435591H180.265V21.4763ZM191.446 12.4589H179.934V9.12241H191.446V12.4589Z"
      fill="white"
    />
    <Path
      d="M197.716 21.4763L207.184 0.435591H211.031L220.53 21.4763H216.442L208.296 2.50961H209.859L201.743 21.4763H197.716ZM202.074 16.6069L203.126 13.541H214.488L215.54 16.6069H202.074Z"
      fill="white"
    />
    <Path
      d="M84.54 43.2183H91.9542V44.1182H84.54V43.2183ZM84.6574 50.163H83.6597V36.4692H92.815V37.3691H84.6574V50.163Z"
      fill="white"
    />
    <Path d="M97.5503 50.163V36.4692H98.548V50.163H97.5503Z" fill="white" />
    <Path
      d="M104.869 50.163V36.4692H105.71L115.472 48.9306H115.022V36.4692H116.02V50.163H115.198L105.417 37.7016H105.867V50.163H104.869Z"
      fill="white"
    />
    <Path
      d="M120.063 50.163L126.362 36.4692H127.359L133.659 50.163H132.583L126.636 37.0952H127.066L121.138 50.163H120.063ZM122.371 46.3092L122.703 45.468H130.9L131.233 46.3092H122.371Z"
      fill="white"
    />
    <Path
      d="M137.694 50.163V36.4692H138.535L148.297 48.9306H147.847V36.4692H148.845V50.163H148.023L138.242 37.7016H138.692V50.163H137.694Z"
      fill="white"
    />
    <Path
      d="M160.967 50.2608C159.949 50.2608 159.004 50.0913 158.13 49.7522C157.269 49.4001 156.519 48.911 155.88 48.285C155.241 47.659 154.746 46.9287 154.394 46.094C154.042 45.2463 153.865 44.3203 153.865 43.3161C153.865 42.3119 154.042 41.3924 154.394 40.5578C154.746 39.7101 155.241 38.9732 155.88 38.3472C156.532 37.7212 157.289 37.2387 158.15 36.8996C159.01 36.5474 159.949 36.3714 160.967 36.3714C161.932 36.3714 162.832 36.5279 163.666 36.8409C164.514 37.1539 165.238 37.6299 165.838 38.269L165.212 38.9145C164.625 38.3407 163.973 37.9299 163.255 37.6821C162.551 37.4212 161.801 37.2908 161.006 37.2908C160.132 37.2908 159.317 37.4408 158.56 37.7408C157.817 38.0407 157.165 38.4646 156.604 39.0123C156.056 39.5601 155.626 40.1991 155.313 40.9295C155.013 41.6598 154.863 42.4554 154.863 43.3161C154.863 44.1769 155.013 44.9724 155.313 45.7027C155.626 46.4331 156.056 47.0721 156.604 47.6199C157.165 48.1676 157.817 48.5915 158.56 48.8915C159.317 49.1914 160.132 49.3414 161.006 49.3414C161.801 49.3414 162.551 49.2175 163.255 48.9697C163.973 48.7089 164.625 48.285 165.212 47.6981L165.838 48.3437C165.238 48.9827 164.514 49.4653 163.666 49.7913C162.832 50.1043 161.932 50.2608 160.967 50.2608Z"
      fill="white"
    />
    <Path
      d="M171.609 42.7684H179.023V43.6487H171.609V42.7684ZM171.727 49.2631H180.178V50.163H170.729V36.4692H179.884V37.3691H171.727V49.2631Z"
      fill="white"
    />
    <Path d="M185.365 50.163V36.4692H186.362V50.163H185.365Z" fill="white" />
    <Path
      d="M192.683 50.163V36.4692H197.594C198.741 36.4692 199.726 36.6518 200.548 37.0169C201.369 37.3821 202.002 37.9103 202.445 38.6015C202.889 39.2797 203.11 40.1078 203.11 41.086C203.11 42.038 202.889 42.8596 202.445 43.5509C202.002 44.229 201.369 44.7572 200.548 45.1354C199.726 45.5006 198.741 45.6832 197.594 45.6832H193.231L193.681 45.2137V50.163H192.683ZM202.191 50.163L198.63 45.1941H199.765L203.325 50.163H202.191ZM193.681 45.2724L193.231 44.8029H197.594C199.067 44.8029 200.182 44.4768 200.939 43.8247C201.708 43.1726 202.093 42.2597 202.093 41.086C202.093 39.8992 201.708 38.9863 200.939 38.3472C200.182 37.6951 199.067 37.3691 197.594 37.3691H193.231L193.681 36.88V45.2724Z"
      fill="white"
    />
    <Path
      d="M206.024 50.163L212.323 36.4692H213.321L219.62 50.163H218.544L212.597 37.0952H213.027L207.1 50.163H206.024ZM208.332 46.3092L208.665 45.468H216.861L217.194 46.3092H208.332Z"
      fill="white"
    />
    <Path
      d="M64.1667 31.5C64.1667 48.897 49.8025 63 32.0833 63C14.3642 63 0 48.897 0 31.5C0 14.103 14.3642 0 32.0833 0C49.8025 0 64.1667 14.103 64.1667 31.5Z"
      fill="url(#paint0_linear_14949_46374)"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9504 20.1518H22.5039C19.1988 20.1518 16.4574 22.7098 16.2288 26.007L15.1819 41.1078C14.9298 44.7444 17.8118 47.8331 21.457 47.8331H42.7093C46.3546 47.8331 49.2366 44.7444 48.9845 41.1078L47.9376 26.007C47.709 22.7098 44.9676 20.1518 41.6624 20.1518H39.3018V21.4569C39.3018 22.6894 38.2948 23.6885 37.0526 23.6885C35.8104 23.6885 34.8034 22.6894 34.8034 21.4569V20.1518H29.4488V21.4569C29.4488 22.6894 28.4418 23.6885 27.1996 23.6885C25.9574 23.6885 24.9504 22.6894 24.9504 21.4569V20.1518ZM37.042 33.334C37.0088 32.6242 36.7912 31.9188 36.3939 31.2835L32.865 25.6621C32.5954 25.2328 32.4109 24.7727 32.3068 24.3039L32.1602 24.5405C31.285 25.9294 31.285 27.6469 32.1602 29.0358L34.9795 33.5311C35.8546 34.9244 35.8546 36.6375 34.9795 38.0264L34.8328 38.263C34.7288 37.7898 34.5443 37.3342 34.2747 36.9048L30.0409 30.1618C29.7713 29.7325 29.5868 29.2724 29.4827 28.8036L29.3361 29.0402C28.4657 30.4247 28.461 32.1335 29.3266 33.5224L32.1554 38.0308C33.0306 39.4241 33.0306 41.1372 32.1554 42.5261L32.0088 42.7627C31.9047 42.2895 31.7202 41.8338 31.4506 41.4045L27.9217 35.7832C27.4487 35.0296 27.2311 34.1796 27.2736 33.3383C23.5035 34.2453 20.897 36.1731 20.897 38.4032C20.897 41.5052 25.9397 44.0245 32.1602 44.0245C38.3807 44.0245 43.4233 41.5096 43.4233 38.4032C43.4233 36.1731 40.8168 34.2453 37.042 33.334Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1121 19.9675C26.1121 16.6716 28.805 13.9998 32.1269 13.9998C35.4488 13.9998 38.1417 16.6716 38.1417 19.9675V21.3813C38.1417 21.9777 37.6545 22.4612 37.0534 22.4612C36.4523 22.4612 35.9651 21.9777 35.9651 21.3813V19.9675C35.9651 17.8643 34.2467 16.1594 32.1269 16.1594C30.0072 16.1594 28.2888 17.8643 28.2888 19.9675V21.3813C28.2888 21.9777 27.8015 22.4612 27.2004 22.4612C26.5994 22.4612 26.1121 21.9777 26.1121 21.3813V19.9675Z"
      fill="white"
    />
    <Defs>
      <linearGradient
        id="paint0_linear_14949_46374"
        x1="32.0833"
        y1="0"
        x2="32.0833"
        y2="63"
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#E54C6F" />
        <Stop offset="1" stopColor="#E84C52" />
      </linearGradient>
    </Defs>
  </Svg>
)

import { FunctionComponent, ReactNode, useRef } from 'react'

import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import { format } from 'date-fns'
import { Text, useBreakpointValue } from 'native-base'
import screensNames from 'src/constants/screensNames'
import { useAuthAtomValue } from 'src/store/auth'
import { LogEventScreen } from 'src/utils'

import { linking } from './linking'

export type RootNavigationContainerProps = {
  children: ReactNode
}

export const RootNavigationContainer: FunctionComponent<RootNavigationContainerProps> = ({
  children
}) => {
  const navigationRef = useNavigationContainerRef()

  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const routeNameRef = useRef<string>()

  const authAtom = useAuthAtomValue()

  const onReady = () => {
    routeNameRef.current = navigationRef?.current?.getCurrentRoute()?.name
  }

  const onStateChange = async () => {
    const currentRouteName = navigationRef.current?.getCurrentRoute()?.name

    if (routeNameRef.current !== currentRouteName) {
      const date = new Date()

      const formated = format(date, 'dd-MM-yyyy HH:mm')

      LogEventScreen({
        screen_name:
          // @ts-ignore
          currentRouteName in screensNames ? screensNames[currentRouteName] : currentRouteName,
        screen_class: currentRouteName,
        date: formated,
        hora: format(date, 'HH'),
        minutos: format(date, 'mm')
      })
    }

    routeNameRef.current = currentRouteName ?? ''
  }

  if (isDesktop && authAtom === undefined) {
    return <Text>Carregando...</Text>
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={onReady}
      onStateChange={onStateChange}
      documentTitle={{
        formatter: (options, route) => `${options?.title ?? route?.name} - Lojinha Financeira`
      }}
      linking={linking}>
      {children}
    </NavigationContainer>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const MenuVerticalIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.59961 2.4C9.59961 1.07451 10.6741 0 11.9996 0C13.3251 0 14.3996 1.07451 14.3996 2.4C14.3996 3.72548 13.3251 4.8 11.9996 4.8C10.6741 4.8 9.59961 3.72548 9.59961 2.4ZM9.59961 12C9.59961 10.6745 10.6741 9.6 11.9996 9.6C13.3251 9.6 14.3996 10.6745 14.3996 12C14.3996 13.3255 13.3251 14.4 11.9996 14.4C10.6741 14.4 9.59961 13.3255 9.59961 12ZM9.59961 21.6C9.59961 20.2745 10.6741 19.2 11.9996 19.2C13.3251 19.2 14.3996 20.2745 14.3996 21.6C14.3996 22.9255 13.3251 24 11.9996 24C10.6741 24 9.59961 22.9255 9.59961 21.6Z"
      fill="currentColor"
    />
  </Icon>
)

import { Suspense, lazy } from 'react'

import { LoadingIcon } from 'atoms/Icons/LoadingIcon'

import { RecoveryPasswordSentEmailSuccesfullyScreenComponent } from './RecoveryPasswordSentEmailSuccesfullyScreen.types'
import { useRecoveryPasswordSentEmailSuccesfullyScreen } from './useRecoveryPasswordSentEmailSuccesfullyScreen'

const RecoveryPasswordSentEmailSuccesfullyDesktop = lazy(
  () => import('./RecoveryPasswordSentEmailSuccesfullyScreen.desktop')
)

const RecoveryPasswordSentEmailSuccesfullyMobile = lazy(
  () => import('./RecoveryPasswordSentEmailSuccesfullyScreen.mobile')
)

export const RecoveryPasswordSentEmailSuccesfullyScreen: RecoveryPasswordSentEmailSuccesfullyScreenComponent =
  ({ navigation, route }) => {
    const props = useRecoveryPasswordSentEmailSuccesfullyScreen({ navigation, route })

    const Screen = props.isMobile
      ? RecoveryPasswordSentEmailSuccesfullyMobile
      : RecoveryPasswordSentEmailSuccesfullyDesktop

    return (
      <Suspense fallback={<LoadingIcon />}>
        <Screen {...props} />
      </Suspense>
    )
  }

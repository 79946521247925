import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, Rect, G, Defs, ClipPath } from 'react-native-svg'

export const InformationCicleIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <G clipPath="url(#clip0_16483_26350)">
      <Path
        d="M12.0002 23.2133C13.4185 23.2133 14.8229 22.9339 16.1332 22.3912C17.4435 21.8484 18.6341 21.0529 19.6369 20.05C20.6398 19.0472 21.4353 17.8566 21.9781 16.5463C22.5208 15.2359 22.8002 13.8316 22.8002 12.4133C22.8002 10.995 22.5208 9.59062 21.9781 8.2803C21.4353 6.96998 20.6398 5.7794 19.6369 4.77653C18.6341 3.77366 17.4435 2.97813 16.1332 2.43538C14.8229 1.89263 13.4185 1.61328 12.0002 1.61328C9.13586 1.61328 6.38883 2.75114 4.36344 4.77653C2.33805 6.80192 1.2002 9.54894 1.2002 12.4133C1.2002 15.2776 2.33805 18.0246 4.36344 20.05C6.38883 22.0754 9.13586 23.2133 12.0002 23.2133Z"
        stroke="#2E9FAF"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 18.4133V13.6133"
        stroke="#2E9FAF"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.0004 8.88555C11.8413 8.88555 11.6886 8.82233 11.5761 8.70981C11.4636 8.59729 11.4004 8.44468 11.4004 8.28555C11.4004 8.12642 11.4636 7.9738 11.5761 7.86128C11.6886 7.74876 11.8413 7.68555 12.0004 7.68555"
        stroke="#2E9FAF"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 8.88555C12.1591 8.88555 12.3117 8.82233 12.4243 8.70981C12.5368 8.59729 12.6 8.44468 12.6 8.28555C12.6 8.12642 12.5368 7.9738 12.4243 7.86128C12.3117 7.74876 12.1591 7.68555 12 7.68555"
        stroke="#2E9FAF"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_16483_26350">
        <Rect width="24" height="24" fill="white" transform="translate(0 0.414062)" />
      </ClipPath>
    </Defs>
  </Icon>
)

import { FunctionComponent } from 'react'

import { Flex, HStack, Skeleton, VStack, useBreakpointValue } from 'native-base'

export const TrackOrderPreview: FunctionComponent = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <VStack
      borderColor="gray.200"
      borderRadius={20}
      borderWidth={1}
      padding={6}
      mt={{ base: 2, lg: 12 }}
      mb={{ base: 2, lg: 14 }}
      w="full">
      <HStack justifyContent="space-between" pb={4}>
        <Skeleton.Text w={40} lines={1} />
        <Skeleton.Text w={10} lines={1} />
      </HStack>

      <VStack textAlign="center" bg="white" pt={4}>
        {[0, 1].map((_, index) => (
          <HStack alignItems="center" mb={4} key={index}>
            <Skeleton w={12} h={12} />

            <Flex alignItems="flex-start" ml={4}>
              <HStack space={2} mb="5px">
                <Skeleton.Text w={30} lines={1} />
              </HStack>

              <Skeleton.Text w={40} lines={1} />
            </Flex>
          </HStack>
        ))}
      </VStack>

      <HStack justifyContent="space-between" my={6}>
        <Skeleton.Text w={50} lines={1} />
      </HStack>

      {isMobile ? (
        <HStack space={2}>
          <VStack>
            {Array.from({ length: 4 }, (_, index) => (
              <VStack space={0} h="100px">
                <VStack alignItems="center" maxW="24px">
                  <Skeleton w={4} h={4} borderRadius="full" />

                  {index !== 3 && <Skeleton h="80px" w="2px" mt="2px" />}
                </VStack>
              </VStack>
            ))}
          </VStack>
          <VStack>
            {Array.from({ length: 4 }, () => (
              <VStack space={0} h="100px">
                <VStack>
                  <Skeleton.Text w={30} lines={2} />
                </VStack>
              </VStack>
            ))}
          </VStack>
        </HStack>
      ) : (
        <VStack space={4}>
          <HStack>
            {Array.from({ length: 4 }, (_, index) => (
              <VStack space={0} w={48}>
                <HStack alignItems="center" pt="'2px">
                  <Skeleton w={4} h={4} borderRadius="full" />

                  {index !== 3 && <Skeleton w="172px" h="2px" ml="2px" />}
                </HStack>
              </VStack>
            ))}
          </HStack>

          <HStack>
            {Array.from({ length: 4 }, (_, index) => (
              <VStack space={0} w={48}>
                <VStack>
                  <Skeleton.Text w={30} lines={2} />
                </VStack>
              </VStack>
            ))}
          </HStack>
        </VStack>
      )}

      <HStack justifyContent="space-between" py={4}>
        <Skeleton.Text w={30} lines={1} />
        <Skeleton.Text w={30} lines={1} />
      </HStack>
    </VStack>
  )
}

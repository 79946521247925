import AsyncStorage from '@react-native-async-storage/async-storage'
import * as SecureStore from 'expo-secure-store'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { Platform } from 'react-native'

type Storage = {
  getItem: (key: string) => Promise<string | null>
  removeItem: (key: string) => Promise<void>
  setItem: (key: string, newValue: string) => Promise<void>
}

const storage = Platform.select<Storage>({
  native: {
    getItem: (key) => SecureStore.getItemAsync(key),
    removeItem: (key) => SecureStore.deleteItemAsync(key),
    setItem: (key, value) => SecureStore.setItemAsync(key, value)
  },
  web: {
    getItem: (key) => AsyncStorage.getItem(key),
    removeItem: (key) => AsyncStorage.removeItem(key),
    setItem: (key, value) => AsyncStorage.setItem(key, value)
  }
}) as Storage

export type TCheckoutProductSize = {
  amount: number
  available: boolean
  size: string
  sizeId: string
}

export type TCheckoutProductItem = {
  price: number
  limitQuantity: number
  color: {
    colorId: string
    colorHex: string
    colorName: string
    sizes: TCheckoutProductSize[]
  }
  productCartId: string
  productDescription: string
  productId: string
  imageUrl: string
  productImage: {
    color: string
    imageDescription: string
    imageUrl: string
    main: boolean
  }
  productName: string
  productSlug: string
  quantity: number
  size: TCheckoutProductSize
}

export type TCheckoutATom = {
  products: TCheckoutProductItem[]
  total: {
    items: number
    points: number
  }
}

const checkoutAtom = atomWithStorage<TCheckoutATom | null | undefined>('checkout', undefined, {
  delayInit: true,
  removeItem: storage.removeItem,
  getItem: async (key) => {
    const item = await storage.getItem(key)

    const itemParsed = item ? JSON.parse(item) : null

    return itemParsed
  },
  setItem: async (key, value) => {
    if (value) {
      return storage.setItem(key, JSON.stringify(value))
    }

    return storage.removeItem(key)
  }
})

export const useCheckoutAtom = () => useAtom(checkoutAtom)

export const useCheckoutAtomValue = () => useAtomValue(checkoutAtom)

export const useSetCheckoutAtom = () => useSetAtom(checkoutAtom)

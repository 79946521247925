import { Product, ProductsData } from './types'
import client from '../../client'

export const getAllProducts = () => client.get<ProductsData>('v1/products/')

export const getAllProductsByCategory = (category: string) =>
  client.get<ProductsData>(`v1/products/category/${category}`)

export const getFeaturedProducts = () => client.get<ProductsData>('v1/products/featured/')

export const getProduct = ({ queryKey }: any) => client.get<Product>(`v1/product/${queryKey}`)

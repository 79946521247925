import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'
import { theme } from 'src/theme'

type ConsultorMaisIconProps = IIconProps & {
  useDefaultColor?: boolean
}

export const ConsultorMaisIcon: FunctionComponent<ConsultorMaisIconProps> = ({
  useDefaultColor,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M24 20.4545C24 22.4126 22.393 24 20.4106 24H16.8213C14.8389 24 13.2319 22.4126 13.2319 20.4545V13.3636L20.4106 13.3636C22.393 13.3636 24 14.951 24 16.9091V20.4545Z"
      fill={useDefaultColor ? theme.colors.red[500] : 'currentColor'}
      fillOpacity={0.3}
    />
    <Path
      key={Math.random()}
      d="M3.58937 24C1.60702 24 2.41484e-07 22.4126 1.55567e-07 20.4545L0 16.9091C-8.59171e-08 14.951 1.60702 13.3636 3.58937 13.3636L10.7681 13.3636L10.7681 20.4545C10.7681 22.4126 9.16109 24 7.17874 24H3.58937Z"
      fill={useDefaultColor ? '#CC0000' : 'currentColor'}
    />
    <Path
      key={Math.random()}
      d="M6.90238e-07 3.57576C6.90238e-07 1.60092 1.60702 0 3.58937 0H7.17874C9.16109 0 10.7681 1.60092 10.7681 3.57576V10.7273H3.58937C1.60702 10.7273 6.90238e-07 9.12635 6.90238e-07 7.15152V3.57576Z"
      fill={useDefaultColor ? theme.colors.red[500] : 'currentColor'}
      fillOpacity={0.3}
    />
    <Path
      key={Math.random()}
      d="M20.4106 4.26406e-08C22.393 1.90909e-08 24 1.60092 24 3.57576V7.15152C24 9.12635 22.393 10.7273 20.4106 10.7273H13.2319V3.57576C13.2319 1.60092 14.8389 1.08831e-07 16.8213 8.52811e-08L20.4106 4.26406e-08Z"
      fill={useDefaultColor ? '#CC0000' : 'currentColor'}
    />
  </Icon>
)

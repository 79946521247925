import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { OrdersScreenComponent } from './OrdersScreen.types'
import { useOrdersScreen } from './useOrdersScreen'

const OrdersScreenDesktop = lazy(() => import('./OrdersScreen.desktop'))

const OrdersScreenMobile = lazy(() => import('./OrdersScreen.mobile'))

export const OrdersScreen: OrdersScreenComponent = ({ navigation, route }) => {
  const screen = useOrdersScreen({ navigation, route })

  const Screen = screen.isMobile ? OrdersScreenMobile : OrdersScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...screen} />
    </Suspense>
  )
}

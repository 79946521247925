import { useEffect, useRef } from 'react'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Subscription } from 'expo-modules-core'
import * as Notifications from 'expo-notifications'
import { useQueryClient } from 'react-query'

export const NotificationComponent = () => {
  const responseListener = useRef<Subscription>()

  const responseListenerReceived = useRef<Subscription>()

  const navigation = useNavigation<NativeStackNavigationProp<any>>()

  const queryClient = useQueryClient()

  useEffect(() => {
    responseListener.current = Notifications.addNotificationResponseReceivedListener(() =>
      navigation.navigate('Notifications')
    )

    const current = responseListenerReceived.current

    return () => {
      if (responseListener.current)
        Notifications.removeNotificationSubscription(responseListener.current)

      if (current) {
        Notifications.removeNotificationSubscription(current)
      }
    }
  }, [navigation, queryClient])

  return <></>
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path, Defs, ClipPath } from 'react-native-svg'

export const QuestionIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <G clipPath="url(#a)" fill="currentColor">
      <Path d="M10.56 3.12c.18-.24.435-.435.705-.57.285-.12.585-.18.885-.15.3.03.6.135.855.3.255.165.465.405.6.69.135.27.195.585.195.885 0 .3-.105.6-.27.855a1.79 1.79 0 0 1-1.53.855c-.66 0-1.2.54-1.2 1.2 0 .66.54 1.2 1.2 1.2.72 0 1.425-.18 2.04-.525a4.11 4.11 0 0 0 1.53-1.47 4.201 4.201 0 0 0 .615-2.01 4.29 4.29 0 0 0-.435-2.07A4.073 4.073 0 0 0 14.355.72 4.276 4.276 0 0 0 12.36 0c-.705-.06-1.44.06-2.085.345-.66.285-1.23.75-1.65 1.32-.405.525-.285 1.275.24 1.68.525.405 1.275.285 1.68-.24l.015.015Z" />
      <Path d="M22.95 1.05A3.597 3.597 0 0 0 20.4 0c-.66 0-1.2.54-1.2 1.2 0 .66.54 1.2 1.2 1.2a1.203 1.203 0 0 1 1.215 1.2v12a1.203 1.203 0 0 1-1.2 1.2h-8.4c-.27 0-.54.09-.75.255l-4.05 3.24v-2.31c0-.66-.54-1.2-1.2-1.2h-2.4a1.23 1.23 0 0 1-.855-.36 1.18 1.18 0 0 1-.345-.84V3.6c0-.315.12-.615.345-.855a1.19 1.19 0 0 1 .855-.345c.66 0 1.2-.54 1.2-1.2 0-.66-.54-1.2-1.2-1.2-.96 0-1.89.39-2.565 1.05A3.597 3.597 0 0 0 0 3.6v12c0 .945.39 1.875 1.05 2.55A3.623 3.623 0 0 0 3.6 19.2h1.2v3.6c0 .465.27.885.675 1.08.165.075.345.12.525.12.27 0 .525-.09.75-.255l5.67-4.545h7.98c.96 0 1.86-.375 2.55-1.05A3.597 3.597 0 0 0 24 15.6v-12c0-.96-.375-1.86-1.05-2.55Z" />
      <Path d="M12 14.7c1.155 0 2.1-.945 2.1-2.1 0-1.155-.945-2.1-2.1-2.1-1.155 0-2.1.945-2.1 2.1 0 1.155.945 2.1 2.1 2.1Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Icon>
)

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const CheckIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.553 2.06569C24.0691 2.48155 24.1503 3.237 23.7344 3.75303L9.92238 20.8914L9.91949 20.895C9.59073 21.2998 9.17638 21.6267 8.70622 21.8522C8.23607 22.0778 7.72179 22.1964 7.20034 22.1995C6.67889 22.2026 6.16323 22.0902 5.6904 21.8703C5.21757 21.6504 4.79933 21.3285 4.46573 20.9277L4.4563 20.9163L0.268305 15.7563C-0.149345 15.2417 -0.0707654 14.4859 0.443817 14.0683C0.9584 13.6506 1.71412 13.7292 2.13177 14.2438L6.31424 19.397C6.42041 19.5232 6.55291 19.6246 6.70247 19.6941C6.85387 19.7646 7.01898 19.8006 7.18594 19.7996C7.3529 19.7985 7.51757 19.7606 7.66811 19.6884C7.81812 19.6164 7.95038 19.5122 8.05547 19.3832C8.05584 19.3828 8.05622 19.3823 8.05659 19.3819L21.8657 2.24703C22.2816 1.73101 23.037 1.64982 23.553 2.06569Z"
      fill="currentColor"
    />
  </Icon>
)

import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { Product } from 'integration/resources/products'
import {
  Badge,
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack
} from 'native-base'

type Props = {
  product: Product
}

export const ProductCard: FunctionComponent<Props> = ({
  product: { image, name, description, price, slug, average_rating }
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  return (
    <VStack
      w={{ base: 39, lg: '341px' }}
      position="relative"
      h={{ base: 'auto', lg: '476px' }}
      borderRadius={{ base: 0, lg: '10px' }}>
      <Box
        style={{
          shadowColor: 'rgba(0, 0, 0, 0.04)',
          shadowOpacity: 1,
          shadowRadius: 16,
          shadowOffset: { width: 0, height: 3 }
        }}>
        <Image
          // @ts-ignore
          cursor="pointer"
          source={image?.imageUrl ? { uri: image.imageUrl } : require('assets/not-found.png')}
          alt={image?.imageDescription ?? name}
          borderRadius={{ base: 5, lg: '10px' }}
          h={{ base: '131px', lg: '304px' }}
          w={{ base: 'full', lg: '341px' }}
          mb={{ lg: '13px' }}
        />
      </Box>
      <Badge position="absolute" left={2} top={2}>
        <HStack alignItems="baseline">
          <Heading
            fontSize={{ base: '14px', lg: '18px' }}
            lineHeight={{ base: '18px', lg: '20px' }}
            fontWeight="bold"
            color="white"
            mr="2px">
            {price}
          </Heading>
          <Heading
            fontSize={{ base: '12px', lg: '14px' }}
            lineHeight={{ base: '16px', lg: '18px' }}
            fontWeight="normal"
            color="white"
            textTransform="uppercase">
            pts
          </Heading>
        </HStack>
      </Badge>
      <VStack flex={1} justifyContent="space-between">
        <Heading
          // @ts-ignore
          cursor="pointer"
          fontSize={{ base: '16px', lg: '24px' }}
          lineHeight={{ base: '24px', lg: '36px' }}
          fontWeight="bold"
          color={{ base: 'gray.700', lg: 'gray.900' }}
          mt={4}
          numberOfLines={1}
          textAlign={{ base: 'left', lg: 'center' }}>
          {name}
        </Heading>
        <Text
          // @ts-ignore
          cursor="pointer"
          fontSize={{ base: '12px', lg: '14px' }}
          lineHeight={{ base: '16px', lg: '18px' }}
          fontWeight="normal"
          color="gray.500"
          mt={2}
          numberOfLines={isMobile ? 2 : 1}
          textAlign={{ base: 'left', lg: 'center' }}>
          {description}
        </Text>
        {average_rating > 0 && isMobile && (
          <HStack space={2} mt={2}>
            <Icons.Star color="tertiary.500" size={4} filled />
            <Text fontSize="12px" lineHeight="18px" fontWeight="400" color="tertiary.500">
              {average_rating}
            </Text>
          </HStack>
        )}

        <HStack space={3} mt={6} justifyContent="center" flex={1}>
          {average_rating > 0 && !isMobile && (
            <HStack space={2} justifyContent="center" alignItems="center">
              <Icons.Star color="tertiary.500" size={8} filled />
              <Text fontSize="16px" lineHeight="24px" fontWeight="400" color="tertiary.500">
                {average_rating}
              </Text>
            </HStack>
          )}

          <Button
            variant="ghost"
            onPress={() => linkTo(`/produto/${slug}`)}
            borderWidth="1px"
            borderColor="gray.700"
            colorScheme="gray"
            width="100%"
            maxWidth="246px"
            borderRadius={30}
            display={{ base: 'none', lg: 'flex' }}>
            Ver detalhes
          </Button>
        </HStack>
      </VStack>
    </VStack>
  )
}

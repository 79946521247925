import { Icons } from 'atoms'
import { Box, Button, Center, Container, Heading, VStack } from 'native-base'
import { AddressList, FooterDesktop, HeaderDesktop } from 'organisms'
import { RootStackScreenComponent } from 'src/navigation'

export const AddressScreen: RootStackScreenComponent<'Address' | 'CheckoutAddress'> = ({
  navigation,
  route
}) => {
  const isCheckoutFlow = route?.path?.split('/').pop() === 'trocar-endereco'

  return (
    <Box safeAreaTop>
      <HeaderDesktop />

      <Center h="48px" maxW="1280px" w="full" mx="auto">
        <Button left={0} onPress={navigation.goBack} position="absolute" variant="unstyled">
          <Icons.Chevron direction="left" size="15px" />
        </Button>
        <Heading color="gray.900" fontSize="16px">
          Meus endereços
        </Heading>
      </Center>

      <Container pb={32} py={6} w="full" mx="auto">
        <VStack w="full" h="full" px={4}>
          <AddressList isCheckoutFlow={isCheckoutFlow} />
        </VStack>
      </Container>

      <FooterDesktop />
    </Box>
  )
}

import { useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { useSendEmailRecoveryPassword } from 'integration/resources/users'
import { useBreakpointValue, useToast } from 'native-base'
import { maskedEmail } from 'src/utils'

import { UseRecoveryPasswordSentEmailSuccesfullyScreen } from './RecoveryPasswordSentEmailSuccesfullyScreen.types'

export const useRecoveryPasswordSentEmailSuccesfullyScreen: UseRecoveryPasswordSentEmailSuccesfullyScreen =
  ({ route }) => {
    const isMobile = useBreakpointValue({ base: true, lg: false })

    const { mutateAsync } = useSendEmailRecoveryPassword()

    const toast = useToast()

    const { email } = route.params

    const linkTo = useLinkTo()

    const handleGoToLogin = useCallback(() => linkTo('/entrar'), [linkTo])

    const resendPasswordToEmail = async () => {
      try {
        await mutateAsync({ email })

        toast.show({
          title: `Enviamos um e-mail no endereço: ${maskedEmail(email)}`,
          _web: {
            background: 'emerald.500',
            borderRadius: 10,
            textAlign: 'center',
            fontSize: '20px',
            lineHeight: '22px'
          }
        })
      } catch (error) {
        toast.show({
          title: 'Atenção',
          description: 'Não foi possível enviar o e-mail, tente novamente mais tarde!',
          _web: {
            background: 'red.500',
            borderRadius: 10,
            textAlign: 'center',
            fontSize: '20px',
            lineHeight: '22px'
          }
        })
      }
    }

    return {
      email: maskedEmail(email),
      isMobile,
      handleGoToLogin,
      resendPasswordToEmail
    }
  }

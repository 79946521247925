import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

export const ArrowReload: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <G clipPath="url(#clip0_15790_40872)">
      <Path
        d="M0.855469 8.57142L5.14118 4.28571L9.4269 8.57142"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M10.2835 23.1428H6.85491C6.40025 23.1428 5.96422 22.9622 5.64272 22.6407C5.32124 22.3193 5.14062 21.8832 5.14062 21.4286V4.28571"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M23.1417 15.4285L18.856 19.7142L14.5703 15.4285"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.7109 0.857117H17.1395C17.5941 0.857117 18.0303 1.03773 18.3517 1.35922C18.6731 1.68071 18.8538 2.11674 18.8538 2.5714V19.7143"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_15790_40872">
        <Rect width="24" height="24" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)

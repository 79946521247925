import { AxiosError, AxiosResponse } from 'axios'
import { useMutation } from 'react-query'

import * as requests from './requests'
import { AuthData, AuthPayload, DeviceLogoutData, DeviceLogoutPayload } from './types'
import { ResponseError } from '../../types'

export const useAuthMutation = () =>
  useMutation<AxiosResponse<AuthData>, AxiosError<ResponseError>, AuthPayload>(requests.auth)

export const useDeviceLogoutMutation = () =>
  useMutation<AxiosResponse<DeviceLogoutData>, AxiosError<ResponseError>, DeviceLogoutPayload>(
    requests.deviceLogout
  )

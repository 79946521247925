import { useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { Address, useGetAddressQuery } from 'integration/resources/address'
import { useCheckoutMutation } from 'integration/resources/checkout/hooks'
import { CheckoutPayload } from 'integration/resources/checkout/types'
import { CheckoutSuccessFeedbackMobile } from 'molecules'
import { Center, Button, Image, VStack, Box, Heading, Text, HStack } from 'native-base'
import { RootStackScreenComponent } from 'src/navigation'
import { useCheckoutAtomValue, useSetCheckoutAtom } from 'src/store/checkout'

export const CartAddressScreen: RootStackScreenComponent<'CartAddress'> = ({ navigation }) => {
  const linkTo = useLinkTo()

  const setCheckoutAtom = useSetCheckoutAtom()

  const addresses: Address[] = useGetAddressQuery()?.data?.data?.results ?? []

  const currentMainAddress = addresses?.filter((address) => address.main)

  const [mainAddress]: Address[] = currentMainAddress

  const { mutate, isLoading } = useCheckoutMutation()

  const checkoutAtomString = useCheckoutAtomValue()

  const [orderId, setCheckoutSuccess] = useState<number>()

  const checkoutAtom =
    typeof checkoutAtomString === 'string' ? JSON.parse(checkoutAtomString) : checkoutAtomString

  const [currentAddress] = addresses.filter((address) => address?.id === checkoutAtom?.address?.id)

  const selectedAddress: Address = currentAddress ?? mainAddress

  const handleCheckoutSubmit = (checkoutAtom: CheckoutPayload, deliveryAddressId: string) => {
    const products = checkoutAtom.products.map((product: any) => ({
      id: product.productId,
      size_id: product?.size.sizeId,
      color_id: product?.color.colorId,
      quantity: product?.quantity
    }))

    const payload = { products, address_id: deliveryAddressId }

    mutate(payload, {
      onError: () => {
        setCheckoutAtom(null)

        linkTo('/erro')
      },
      onSuccess: (responseData) => {
        setCheckoutSuccess(responseData?.data?.order_id)
      }
    })
  }

  return (
    <Box flex={1} safeArea>
      <Center h="48px">
        <Button left={0} onPress={navigation.goBack} position="absolute" variant="unstyled">
          <Icons.Chevron direction="left" size="15px" />
        </Button>
        <Heading color="gray.900" fontSize="16px">
          Entrega
        </Heading>
      </Center>
      <VStack p={6} space={6}>
        {selectedAddress ? (
          // at the end of the screen tasks, implement code when there are registered addresses
          <VStack space={6}>
            <HStack
              bg="white"
              borderRadius="10px"
              p={6}
              space={2}
              style={{
                shadowColor: 'rgba(0, 0, 0, 0.2)',
                shadowOffset: {
                  width: 0,
                  height: 3
                },
                shadowOpacity: 0.29,
                shadowRadius: 4.65,

                elevation: 7
              }}>
              <Icons.Pin color="primary.500" size={6} />
              <VStack space={1}>
                <Text color="primary.500" fontSize="12px" lineHeight="18px">
                  {selectedAddress.street}, {selectedAddress.number}
                  {selectedAddress.complement ? `, ${selectedAddress.complement}` : ''}
                  {`, ${selectedAddress.district}`}
                </Text>
                <Text fontSize="12px" lineHeight="18px">
                  CEP {selectedAddress.postal_code} - {selectedAddress.city}
                  {` - `}
                  {selectedAddress.state}
                </Text>
                {selectedAddress.main && (
                  <HStack alignItems="center" space={1}>
                    <Box bg="primary.500" borderRadius="full" h={1} w={1} />
                    <Text color="primary.500" fontSize="12px" lineHeight="18px">
                      Principal
                    </Text>
                  </HStack>
                )}
              </VStack>
            </HStack>
            <HStack alignItems="center" justifyContent="space-between">
              <Image source={require('assets/jadlog.png')} alt="img 1" />
              <Heading color="gray.800" fontSize="14px" fontWeight="semibold" lineHeight="18px">
                Até 60 dias úteis
              </Heading>
            </HStack>
          </VStack>
        ) : (
          <Button
            variant="outline"
            onPress={() => linkTo('/endereco/salvar')}
            endIcon={<Icons.Chevron direction="right" size={5} />}
            startIcon={<Box size={5} />}
            _stack={{ flex: 1, justifyContent: 'space-between' }}>
            Cadastrar endereço
          </Button>
        )}
        <Center>
          <Box borderTopWidth="1px" borderTopColor="gray.100" maxW="295px" w="full" />
        </Center>
        {selectedAddress && (
          <VStack space={3} mb={15}>
            <Text fontSize="14px" lineHeight="18px">
              Deseja alterar o endereço de entrega?
            </Text>
            <Button
              bg="background.base"
              colorScheme="gray"
              px={6}
              _stack={{ flex: 1, justifyContent: 'space-between' }}
              _text={{
                color: 'gray.700',
                isTruncated: true,
                noOfLines: 1,
                ...(selectedAddress.street?.length > 35 && { maxW: '99%' })
              }}
              _pressed={{ bg: 'gray.200' }}
              onPress={() => linkTo('/carrinho/trocar-endereco')}
              leftIcon={<Icons.Pin color="primary.500" size={6} />}
              rightIcon={<Icons.Chevron color="primary.500" direction="right" size={6} />}>
              {selectedAddress.street}
            </Button>
          </VStack>
        )}
        <Button
          bg={selectedAddress ? 'primary.500' : 'gray.200'}
          disabled={!selectedAddress}
          onPress={() => handleCheckoutSubmit(checkoutAtom, selectedAddress?.id ?? '')}
          isLoading={isLoading}>
          Fazer pedido
        </Button>
      </VStack>

      <CheckoutSuccessFeedbackMobile
        isOpen={Boolean(orderId)}
        orderId={orderId ?? 0}
        onClose={() => {
          setCheckoutAtom(null)

          linkTo('/inicio')
        }}
      />
    </Box>
  )
}

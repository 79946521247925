import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { G, Path, Defs, ClipPath, Rect } from 'react-native-svg'

export const StarIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <G clipPath="url(#clip0_12543_7584)">
      <Path
        d="M10.3433 2.10721C10.5211 1.82923 10.766 1.60047 11.0554 1.44202C11.3448 1.28356 11.6694 1.2005 11.9993 1.2005C12.3293 1.2005 12.6539 1.28356 12.9433 1.44202C13.2327 1.60047 13.4776 1.82923 13.6553 2.10721L16.8809 7.20001L21.0881 7.72081C21.4369 7.76263 21.7678 7.89805 22.0458 8.11269C22.3239 8.32734 22.5386 8.61321 22.6673 8.94001C22.7936 9.2653 22.8287 9.61883 22.7691 9.96261C22.7095 10.3064 22.5573 10.6274 22.3289 10.8912L18.8345 14.9328L19.9601 20.4912C20.0329 20.853 19.9998 21.2281 19.8649 21.5716C19.7299 21.9151 19.4989 22.2124 19.1993 22.428C18.8977 22.6456 18.5405 22.7733 18.1693 22.7962C17.798 22.8192 17.4279 22.7364 17.1017 22.5576L11.9993 19.7592L6.90175 22.56C6.57482 22.7395 6.20362 22.8224 5.8314 22.799C5.45918 22.7756 5.10126 22.647 4.79935 22.428C4.49894 22.213 4.26693 21.916 4.13113 21.5724C3.99533 21.2289 3.96152 20.8535 4.03375 20.4912L5.15935 14.9328L1.67935 10.9008C1.45097 10.637 1.2988 10.316 1.23918 9.97221C1.17956 9.62843 1.21474 9.2749 1.34095 8.94961C1.46968 8.62281 1.68444 8.33694 1.96246 8.12229C2.24048 7.90765 2.57141 7.77223 2.92015 7.73041L7.11775 7.20001L10.3433 2.10721Z"
        stroke="currentColor"
        strokeWidth={2.4}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={filled ? 'currentColor' : 'none'}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_12543_7584">
        <Rect width={24} height={24} fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
)

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const HomeIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M24.0433 12.9436C24.0433 19.6082 18.661 25.0109 12.0217 25.0109C5.38228 25.0109 0 19.6082 0 12.9436C0 6.27909 5.38228 0.876404 12.0217 0.876404C18.661 0.876404 24.0433 6.27909 24.0433 12.9436Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.57627 9.10797C9.57627 9.54611 9.20811 9.9013 8.75395 9.9013C8.2998 9.9013 7.93164 9.54611 7.93164 9.10797C7.93164 8.95411 7.97704 8.81048 8.05562 8.68887H7.4664C7.02089 8.67044 6.63072 8.98932 6.55369 9.43479L5.28252 18.4059C5.2692 18.6549 5.36135 18.8979 5.53579 19.0738C5.71023 19.2496 5.95038 19.3416 6.19586 19.3265H17.707C17.9524 19.3416 18.1925 19.2496 18.3669 19.0738C18.5412 18.8979 18.6332 18.6549 18.6197 18.4059L17.3485 9.43479C17.2715 8.98957 16.8817 8.67077 16.4365 8.68887H15.6705C15.7448 8.81048 15.7876 8.95411 15.7876 9.10797C15.7876 9.54611 15.4399 9.9013 15.011 9.9013C14.5821 9.9013 14.2344 9.54611 14.2344 9.10797C14.2344 8.95411 14.2773 8.81048 14.3515 8.68887H9.45229C9.53087 8.81048 9.57627 8.95411 9.57627 9.10797ZM13.4222 12.9661C13.5559 13.1856 13.6292 13.4293 13.6403 13.6746C14.9112 13.9894 15.7886 14.6555 15.7886 15.4259C15.7886 16.4992 14.091 17.3681 11.9969 17.3681C9.9027 17.3681 8.20508 16.4977 8.20508 15.4259C8.20508 14.6555 9.08256 13.9894 10.3518 13.6761C10.3375 13.9667 10.4107 14.2604 10.57 14.5207L11.758 16.4629C11.8488 16.6112 11.9109 16.7686 11.9459 16.9321L11.9953 16.8504C12.2899 16.3705 12.2899 15.7786 11.9953 15.2973L11.0429 13.7396C10.7515 13.2598 10.7531 12.6694 11.0461 12.1911L11.0955 12.1094C11.1305 12.2713 11.1926 12.4303 11.2834 12.5786L12.7087 14.9083C12.7995 15.0566 12.8616 15.214 12.8966 15.3775L12.946 15.2958C13.2406 14.8159 13.2406 14.224 12.946 13.7427L11.9969 12.1896C11.7022 11.7097 11.7022 11.1163 11.9969 10.6365L12.0462 10.5547C12.0813 10.7167 12.1434 10.8757 12.2341 11.024L13.4222 12.9661Z"
      fill="white"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.44497 6.46681C10.089 5.7987 10.963 5.42296 11.8751 5.42426C12.7871 5.42556 13.6601 5.80379 14.3023 6.47373C14.9443 7.14346 15.3032 8.04981 15.3019 8.9931C15.3016 9.17635 15.156 9.32469 14.9766 9.32444C14.7972 9.32418 14.652 9.17542 14.6523 8.99217C14.6533 8.22102 14.3598 7.48222 13.8381 6.93801C13.3167 6.39399 12.6101 6.08892 11.8742 6.08787C11.1382 6.08682 10.4308 6.38988 9.90787 6.93241C9.38471 7.47514 9.08918 8.21309 9.08813 8.98425C9.08788 9.1675 8.94225 9.31584 8.76286 9.31559C8.58347 9.31533 8.43824 9.16657 8.4385 8.98332C8.43978 8.04004 8.80116 7.13471 9.44497 6.46681Z"
      fill="white"
    />
  </Icon>
)

import { useLinkTo } from '@react-navigation/native'
import { useChangeOrderMutation } from 'integration/resources/orders/hooks'
import { Actionsheet, Box, Button, HStack, Heading, Text, VStack } from 'native-base'
import { ORDER_FINISHED } from 'src/constants/order'

export type ConfirmDeliveryProps = {
  orderId: string
  isOpen: boolean
  onClose: () => void
}

export const ConfirmDelivery = ({ orderId, isOpen, onClose }: ConfirmDeliveryProps) => {
  const linkTo = useLinkTo()

  const { mutate, isLoading } = useChangeOrderMutation()

  const handleConfirmReceivedOrder = () => {
    mutate(
      {
        id: orderId,
        status: ORDER_FINISHED
      },
      {
        onError: () => {
          onClose()

          linkTo('/erro')
        },
        onSuccess: () => {
          onClose()
        }
      }
    )
  }

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose} hideDragIndicator>
      <Actionsheet.Content>
        <Box
          bg="white"
          px="24px"
          pt="32px"
          flexDirection="column"
          w="full"
          alignItems="center"
          borderTopLeftRadius="10px"
          borderTopRightRadius="10px">
          <Heading
            color="gray.700"
            fontSize="28px"
            lineHeight="48px"
            mb={4}
            fontWeight="bold"
            textAlign="center">
            Confirmar recebimento
          </Heading>

          <VStack maxW={80}>
            <Text color="gray.700" fontSize="16px" lineHeight="24px" textAlign="center">
              Você tem certeza que confirma o recebimento deste pedido?
            </Text>

            <Text color="gray.700" fontSize="16px" lineHeight="24px" textAlign="center" mb="40px">
              Esta ação não poderá ser desfeita.
            </Text>
          </VStack>

          <HStack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            space={4}
            w="100%"
            mb="60px">
            <Button
              onPress={onClose}
              disabled={isLoading}
              colorScheme="gray.500"
              flex="1"
              variant="outline"
              width={{ base: 'full', lg: '267px' }}>
              Cancelar
            </Button>

            <Button
              onPress={handleConfirmReceivedOrder}
              isLoading={isLoading}
              flex="1"
              variant="outline"
              width={{ base: 'full', lg: '267px' }}>
              Confirmar
            </Button>
          </HStack>
        </Box>
      </Actionsheet.Content>
    </Actionsheet>
  )
}

import { useCallback, useRef } from 'react'

import { AnimatedSlideDot } from 'molecules'
import { Box, HStack } from 'native-base'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

import { AnimatedIconButton, AnimatedSlide } from './OnboardingComponents'
import {
  AnimatedDesktopRightBanner,
  AnimatedDesktopRightBannerHandle
} from './OnboardingComponents/AnimatedDesktopRightBanner'
import { ResponsiveOnboardingScreenComponent } from './OnboardingScreen.types'
import {
  INDEX_SLIDE_GENDER,
  INDEX_SLIDE_PROVIDER,
  slides,
  SLIDE_WIDTH
} from './useOnboardingScreen'

export const OnboardingScreenMobile: ResponsiveOnboardingScreenComponent = ({
  finishOnboarding,
  onSelectProvider,
  onSelectGender,
  buttonDisabled,
  setButtonDisabled,
  refGender,
  refProvider,
  userIsConsultant
}) => {
  const refScrollView = useRef<Animated.ScrollView>(null)

  const refAnimatedDesktopRightBanner = useRef<AnimatedDesktopRightBannerHandle>(null)

  const scrollX = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const next = useCallback(() => {
    const x = scrollX.value + SLIDE_WIDTH

    const nextIndex = Number(
      ((Math.floor(scrollX.value) + Math.floor(SLIDE_WIDTH)) / Math.floor(SLIDE_WIDTH)).toFixed(0)
    )

    const slide = slides[nextIndex]

    if (nextIndex === INDEX_SLIDE_PROVIDER && !refProvider.current) {
      setButtonDisabled(true)
    }

    if (nextIndex === INDEX_SLIDE_GENDER && !refGender.current) {
      setButtonDisabled(true)
    }

    if (!slide) {
      finishOnboarding()

      return
    }

    refAnimatedDesktopRightBanner.current?.changeSlide(slide?.imageDesktop ? slide : null)

    refScrollView.current?.scrollTo({
      animated: true,
      x
    })
  }, [finishOnboarding, refGender, refProvider, scrollX.value, setButtonDisabled])

  return (
    <HStack flex={1} safeArea>
      <Box flex={1} justifyContent={{ lg: 'center' }} pb={7} pt={4}>
        <Box flex={{ base: 1, lg: undefined }}>
          <Animated.ScrollView
            horizontal
            onScroll={scrollHandler}
            pagingEnabled
            ref={refScrollView}
            scrollEventThrottle={32}
            showsHorizontalScrollIndicator={false}>
            {slides.map((slide, index) => (
              <AnimatedSlide
                index={index}
                key={slide.id}
                onSelectGender={onSelectGender}
                onSelectProvider={onSelectProvider}
                scrollX={scrollX}
                slide={slide}
                userIsConsultant={userIsConsultant}
              />
            ))}
          </Animated.ScrollView>
          <Box alignItems={{ lg: 'center' }}>
            <Box>
              <HStack alignItems="center" justifyContent="space-between" px={{ base: 6, lg: 0 }}>
                <HStack alignItems="center" space={1}>
                  {slides.map((slide, index) => (
                    <AnimatedSlideDot
                      index={index}
                      key={slide.id}
                      scrollX={scrollX}
                      slideWidth={SLIDE_WIDTH}
                    />
                  ))}
                </HStack>
                <AnimatedIconButton onPress={next} scrollX={scrollX} disabled={buttonDisabled} />
              </HStack>
            </Box>
          </Box>
        </Box>
      </Box>
      <AnimatedDesktopRightBanner ref={refAnimatedDesktopRightBanner} />
    </HStack>
  )
}

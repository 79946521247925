import { AxiosResponse } from 'axios'
import { formatRelative, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { ProfileData, ProfileDataSerialized } from './types'

export const getProfile: (
  response: AxiosResponse<ProfileData>
) => AxiosResponse<ProfileDataSerialized> = (response) => ({
  ...response,
  data: {
    ...response.data,
    balanceExpirationFormatted: formatRelative(
      parseISO(response.data.balanceExpiration),
      new Date(),
      {
        locale: ptBR
      }
    )
  }
})

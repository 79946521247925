import { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import {
  Assistant_200ExtraLight,
  Assistant_300Light,
  Assistant_400Regular,
  Assistant_500Medium,
  Assistant_600SemiBold,
  Assistant_700Bold,
  Assistant_800ExtraBold
} from '@expo-google-fonts/assistant'
import { DMSans_400Regular, DMSans_500Medium, DMSans_700Bold } from '@expo-google-fonts/dm-sans'
import * as Font from 'expo-font'
import { LinearGradient } from 'expo-linear-gradient'
import * as SplashScreen from 'expo-splash-screen'
import LottieView from 'lottie-react-native'
import { NativeBaseProvider } from 'native-base'
import { Platform } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { QueryClient, QueryClientProvider } from 'react-query'
import { theme } from 'src/theme'

import { BottomSheetProvider } from './contexts/BottomSheetContext'
import { RootNavigationContainer } from './navigation'

const lottieFile = require('../assets/lottie/launchscreen.json')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      refetchOnWindowFocus: false
    }
  }
})

export type AppProvidersProps = {
  children: ReactNode
}

export const AppProviders: FunctionComponent<AppProvidersProps> = ({ children }) => {
  const [appIsReady, setAppIsReady] = useState(false)

  const [finishSplash, setFinishSplash] = useState(false)

  const animation = useRef<LottieView>(null)

  useEffect(() => {
    async function prepare() {
      try {
        animation.current?.play()

        // Pre-load fonts, make any API calls you need to do here
        await Font.loadAsync({
          Assistant_200ExtraLight,
          Assistant_300Light,
          Assistant_400Regular,
          Assistant_500Medium,
          Assistant_600SemiBold,
          Assistant_700Bold,
          Assistant_800ExtraBold,
          DMSans: DMSans_400Regular,
          DMSans_400Regular,
          DMSans_500Medium,
          DMSans_700Bold
        })
      } catch (e) {
        console.warn(e)
      } finally {
        // Tell the application to render
        setAppIsReady(true)
      }
    }

    prepare()
  }, [])

  const onLayout = useCallback(() => {
    if (appIsReady) {
      SplashScreen.hideAsync()
    }
  }, [appIsReady])

  const handleAnimationFinish = useCallback(() => {
    setFinishSplash(true)
  }, [])

  return (
    <GestureHandlerRootView style={{ flex: 1 }} onLayout={onLayout}>
      <QueryClientProvider client={queryClient}>
        <NativeBaseProvider
          initialWindowMetrics={{
            frame: { x: 0, y: 0, width: 0, height: 0 },
            insets: { top: 0, left: 0, right: 0, bottom: 0 }
          }}
          config={{
            suppressColorAccessibilityWarning: true,
            dependencies: {
              'linear-gradient': LinearGradient
            }
          }}
          theme={theme}>
          {!finishSplash ? (
            <>
              {Platform.OS !== 'web' ? (
                <LottieView
                  autoPlay
                  loop={false}
                  resizeMode="cover"
                  source={lottieFile}
                  onAnimationFinish={handleAnimationFinish}
                />
              ) : (
                handleAnimationFinish()
              )}
            </>
          ) : (
            <RootNavigationContainer>
              <BottomSheetProvider>{children}</BottomSheetProvider>
            </RootNavigationContainer>
          )}
        </NativeBaseProvider>
      </QueryClientProvider>
    </GestureHandlerRootView>
  )
}

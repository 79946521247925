import {
  useGetAllProductsByCategoryQuery,
  useGetAllProductsQuery
} from 'integration/resources/products'
import { useGetResumeQuery } from 'integration/resources/profile'
import { useBreakpointValue } from 'native-base'

import { UseProductsScreen } from './ProductsScreen.types'

export const useProductsScreen: UseProductsScreen = ({ route }) => {
  const category = route.params?.categorySlug ?? 'todos'

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const productsBySegment = useGetAllProductsByCategoryQuery(category)

  const allProducts = useGetAllProductsQuery()

  const isLoading = productsBySegment.isLoading || allProducts.isLoading

  const profileResume = useGetResumeQuery()

  return {
    productsBySegment: productsBySegment?.data?.data,
    allProducts: allProducts?.data?.data,
    profileResume: profileResume.data?.data,
    isLoading,
    isMobile,
    category
  }
}

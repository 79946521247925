export const ORDER_CANCELED = 'cancelado'

export const ORDER_FINISHED = 'finalizado'

export const ORDER_NOT_FINISHED = 'não finalizado'

export const ORDER_DELIVERED = 'entregue'

export const ORDER_PROGRESS = 'andamento'

export const ORDER_UNPRODUCTIVE = 'improdutiva'

export const ORDER_STATUS = [
  ORDER_CANCELED,
  ORDER_FINISHED,
  ORDER_NOT_FINISHED,
  ORDER_DELIVERED,
  ORDER_PROGRESS,
  ORDER_UNPRODUCTIVE
] as const

import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

import {
  useDeliveredOrdersQuery,
  useNotDeliveredOrdersQuery
} from 'integration/resources/orders/hooks'
import { Order } from 'integration/resources/orders/types'
import { useGetResumeQuery } from 'integration/resources/profile'
import { useBreakpointValue } from 'native-base'
import { ScrollView } from 'react-native'

import { UseOrdersScreen } from './OrdersScreen.types'

const itemsPerPage = 6

export const useOrdersScreen: UseOrdersScreen = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [currentPage, setCurrentPage] = useState(1)

  const [orders, setOrders] = useState<Order[]>([])

  const [totalPages, setTotalPages] = useState(1)

  const scrollViewRef: RefObject<ScrollView> = useRef<ScrollView>(null)

  const {
    data: allDeliveredOrders,
    isLoading,
    isRefetching,
    refetch
  } = useDeliveredOrdersQuery({
    finished: true,
    page: currentPage,
    page_size: itemsPerPage
  })

  const allNotDeliveredOrders = useNotDeliveredOrdersQuery()

  const isFetching = isLoading || isRefetching || allNotDeliveredOrders.isLoading

  const profileResume = useGetResumeQuery()

  const scrollToTop = () => {
    scrollViewRef.current?.scrollTo({ y: 530, animated: true })
  }

  const onRefetch = useCallback(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    onRefetch()
  }, [currentPage, onRefetch])

  useEffect(() => {
    if (isFetching) {
      scrollToTop()
    }
  }, [isFetching])

  useEffect(() => {
    if (!isFetching && allDeliveredOrders) {
      setOrders(allDeliveredOrders.data.results ?? [])

      setTotalPages(Math.ceil((allDeliveredOrders.data.count ?? 0) / itemsPerPage))
    }
  }, [allDeliveredOrders, isFetching])

  return {
    allDeliveredOrders: orders,
    allNotDeliveredOrders: allNotDeliveredOrders.data?.data,
    profileResume: profileResume.data?.data,
    isLoading: isFetching,
    isRefetching,
    isMobile,
    scrollViewRef,
    totalPages,
    setCurrentPage,
    currentPage
  }
}

import { useLinkTo } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'

import { UseErrorScreen } from './ErrorScreen.types'

export const useErrorScreen: UseErrorScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const handleGoToHome = () => linkTo('/inicio')

  const handleGoBack = () => navigation.goBack()

  return {
    isMobile,
    handleGoToHome,
    handleGoBack
  }
}

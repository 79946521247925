import { ABTestingIllustration as ABTesting } from './ABTestingIllustration'
import { CheckoutSuccessIllustration as CheckoutSuccess } from './CheckoutSuccessIllustration'
import { EmptyFolderIllustration as EmptyFolder } from './EmptyFolderIllustration'
import { GetInspiredIllustration as GetInspired } from './GetInspiredIllustration'
import { GiftCardsIllustration as GiftCards } from './GiftCardsIllustration'
import { InsufficientFundsIllustration as InsufficientFunds } from './InsufficientFundsIllustration'
import { LoggedOutIllustration as LoggedOut } from './LoggedOutIllustration'
import { WelcomeIllustration as Welcome } from './WelcomeIllustration'

export default {
  ABTesting,
  CheckoutSuccess,
  EmptyFolder,
  GetInspired,
  GiftCards,
  InsufficientFunds,
  LoggedOut,
  Welcome
}

import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const normalizeDate = (date: string, pattern: string) => {
  if (!date) return

  return format(parseISO(date), pattern, {
    locale: ptBR
  })
}

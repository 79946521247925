import { Icons } from 'atoms'
import { Button, HStack, Text } from 'native-base'

export type RatingProps = {
  score: number
  viewScore?: boolean
  size: number
  onSelectScore?: (score: number) => void
}

export const Rating = ({ score, size, viewScore, onSelectScore }: RatingProps) => {
  const roundedScore = Math.ceil(score)

  const handleScoreSelection = (selectedScore: number) => {
    if (onSelectScore) {
      onSelectScore(selectedScore)
    }
  }

  const stars = Array.from({ length: 5 }, (_, index) => (
    <Button
      key={index}
      onPress={() => handleScoreSelection(index + 1)}
      variant="unstyled"
      p={0}
      h={size}>
      <Icons.Star filled={roundedScore >= index + 1} size={size} color="tertiary.500" />
    </Button>
  ))

  const feedbackOptions: {
    [key: number]: string
  } = {
    1: 'Insatisfeito',
    2: 'Pouco satisfeito',
    3: 'Neutro',
    4: 'Satisfeito',
    5: 'Muito satisfeito'
  }

  const feedback = feedbackOptions[roundedScore] || ''

  const rating = viewScore ? (score ? score : '') : feedback

  return (
    <HStack space={2} alignItems="center">
      {stars}
      <Text fontSize={16} fontWeight={400} lineHeight={24} color="tertiary.500">
        {rating}
      </Text>
    </HStack>
  )
}

import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Icons } from 'atoms'
import { useGetCategoriesQuery } from 'integration/resources/categories'
import { Center, Flex, HStack, Link, Text, useBreakpointValue } from 'native-base'
import { useQueryClient } from 'react-query'
import { useSetAuthAtom } from 'src/store/auth'

export const FooterDesktop: FunctionComponent = () => {
  const linkTo = useLinkTo()

  const isVisible = useBreakpointValue({ base: false, lg: true })

  const { data } = useGetCategoriesQuery()

  const queryClient = useQueryClient()

  const setAuthAtom = useSetAuthAtom()

  const handleLogout = () => {
    queryClient.invalidateQueries()

    setAuthAtom(null)
  }

  if (!isVisible) {
    return <></>
  }

  const categories = data?.data.results ?? []

  return (
    <Center bg="gray.900" w="full" pt={20} pb={15}>
      <HStack
        justifyContent="space-between"
        borderBottomWidth={1}
        w="full"
        maxW="1280px"
        m="auto"
        borderColor="gray.300"
        mb={8}>
        <Flex direction="column" marginRight="auto">
          <HStack ml={-6} mb={10}>
            <Icons.LogoStore height={40} color="white" />
          </HStack>

          <Text fontSize="16px" mb="32px" lineHeight="28px" fontWeight={500} color="gray.300">
            Caro Colaborador,{'\n'}
            Bem Vindo a <Text color="red.500">Lojinha Financeira</Text>, aqui você encontra todos os
            {'\n'}itens que compõem o kit de uniforme, que foi desenvolvido{'\n'}
            pensado em como facilitar seu dia a dia e nos mais diversos{'\n'}
            estilos e ambientes, mantendo sempre como referência itens{'\n'}
            de qualidade.{'\n'}
            Além da possibilidade de fazer a gestão das suas solicitações{'\n'}
            acompanhando cada etapa.
          </Text>
        </Flex>

        <HStack>
          <Flex direction="column" marginRight="auto">
            <Text fontSize="16px" mb={4} lineHeight="28px" fontWeight={500} color="red.500">
              Categorias
            </Text>

            {categories.map((category) => (
              <Link onPress={() => linkTo(`/produtos/${category.slug}`)} key={category.id}>
                <Text fontSize="16px" mb={2} lineHeight="28px" fontWeight={400} color="gray.300">
                  {category.name}
                </Text>
              </Link>
            ))}
          </Flex>

          <Flex direction="column" marginLeft={20} marginRight="33px">
            <Text fontSize="16px" mb={4} lineHeight="28px" fontWeight={500} color="red.500">
              Meu perfil
            </Text>

            <Link onPress={() => linkTo('/perfil')}>
              <Text fontSize="16px" mb={2} lineHeight="28px" fontWeight={400} color="gray.300">
                Perfil
              </Text>
            </Link>
            <Link onPress={() => linkTo('/extrato')}>
              <Text fontSize="16px" mb={2} lineHeight="28px" fontWeight={400} color="gray.300">
                Extrato
              </Text>
            </Link>
            <Link onPress={handleLogout}>
              <Text fontSize="16px" mb={2} lineHeight="28px" fontWeight={400} color="gray.300">
                Sair
              </Text>
            </Link>
          </Flex>
        </HStack>
      </HStack>

      <HStack w="full" justifyContent="space-between" maxW="1280px" m="auto">
        <Flex direction="column" marginRight="auto">
          <Text fontSize="16px" mb={2} lineHeight="24px" fontWeight={400} color="gray.500">
            © {new Date().getFullYear()} Banco Santander (Brasil) S.A., CNPJ: 90.400.888/0001-42
          </Text>
          <Text fontSize="16px" lineHeight="24px" fontWeight={400} color="gray.500">
            Av. Presidente Juscelino Kubitscheck 2041/2235 – Vila Olímpia – São Paulo/SP.{' '}
          </Text>
        </Flex>

        <HStack justifyContent="flex-end">
          <Flex direction="column">
            <HStack justifyContent="flex-end">
              <Icons.LogoSantander color="white" size={104} />
            </HStack>
            <HStack>
              <Link onPress={() => linkTo('/politica-privacidade')}>
                <Text
                  mt={4}
                  fontSize="14px"
                  textDecorationLine="underline"
                  lineHeight="18.23px"
                  fontWeight={400}
                  color="gray.500">
                  Política de Privacidade
                </Text>
              </Link>
            </HStack>
          </Flex>
        </HStack>
      </HStack>
    </Center>
  )
}

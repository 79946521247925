import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { AddressFormScreenComponent } from './AddressFormScreen.types'
import { useAddressFormScreen } from './useAddressFormScreen'

const AddressFormScreenDesktop = lazy(() => import('./AddressFormScreen.desktop'))

const AddressFormScreenMobile = lazy(() => import('./AddressFormScreen.mobile'))

export const AddressFormScreen: AddressFormScreenComponent = ({ navigation, route }) => {
  const addressFormScreen = useAddressFormScreen({ navigation, route })

  const Screen = addressFormScreen.isMobile ? AddressFormScreenMobile : AddressFormScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...addressFormScreen} />
    </Suspense>
  )
}

import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { GetAllRatingParams, RatingData, RatingPayload } from './types'
import { ResponseError } from '../../types'

export const useGetAllRatingQuery = (params: GetAllRatingParams) =>
  useQuery<AxiosResponse<RatingData>, AxiosError<ResponseError>>(
    ['getAllRating', params.identifier],
    () => requests.getAllRating(params)
  )

export const usePostRatingMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<RatingPayload[]>, AxiosError<ResponseError>, RatingPayload[]>(
    requests.postRating,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getDeliveredOrders')

        queryClient.invalidateQueries('getNotDeliveredOrders')
      }
    }
  )
}

import { FunctionComponent, useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Product } from 'integration/resources/products'
import { ProductCard } from 'molecules'
import { Box, FlatList, Link, useBreakpointValue } from 'native-base'
import { Platform } from 'react-native'

type Props = {
  products: Product[]
  vertical?: boolean
  listHeaderComponent?: React.ComponentType<any> | React.ReactElement | null | undefined
  listFooterComponent?: React.ComponentType<any> | React.ReactElement | null | undefined
}

export const ProductList: FunctionComponent<Props> = ({
  products,
  vertical = false,
  listHeaderComponent,
  listFooterComponent
}) => {
  const linkTo = useLinkTo()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const numColumns = isMobile ? 0 : 3

  const keyExtractor = useCallback((item: { id: number | string }) => `key-${item.id}`, [])

  return (
    <FlatList
      w={{ lg: 'full' }}
      h={Platform.OS === 'web' && isMobile ? 320 : 'full'}
      data={products}
      keyExtractor={keyExtractor}
      key={`flatlist-products-${Math.random()}`}
      renderItem={({ item, index }) => (
        <Box mx={index % 2 === 0 ? 5 : 0} mb={isMobile ? (vertical ? 10 : 0) : 15}>
          <Link onPress={() => linkTo(`/produto/${item.slug}`)}>
            <ProductCard product={item} />
          </Link>
        </Box>
      )}
      horizontal={vertical ? false : isMobile}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      ListHeaderComponent={listHeaderComponent}
      ListFooterComponent={listFooterComponent}
      numColumns={vertical ? 2 : numColumns}
    />
  )
}

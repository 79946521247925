import { useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'

import { UseRecoveryPasswordCreatePasswordFeedbackScreen } from './RecoveryPasswordCreatePasswordFeedbackScreen.types'

export const useRecoveryPasswordCreatePasswordFeedbackScreen: UseRecoveryPasswordCreatePasswordFeedbackScreen =
  ({ route }) => {
    const isMobile = useBreakpointValue({ base: true, lg: false })

    const { isSuccess } = route.params ?? {}

    const linkTo = useLinkTo()

    const handleGoToLogin = useCallback(() => linkTo('/entrar'), [linkTo])

    return {
      isSuccess: Boolean(parseInt(isSuccess, 10)),
      isMobile,
      handleGoToLogin
    }
  }

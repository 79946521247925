import { FunctionComponent, useState } from 'react'

import {
  Center,
  VStack,
  HStack,
  Heading,
  Text,
  Pressable,
  View,
  MinusIcon,
  Input,
  AddIcon,
  Button
} from 'native-base'

type TProductDetailsProps = {
  id: string
  name: string
  price: number
  description: string
  color: string
  size: string
  quantity: number
  onRemove(id: string): void
  onUpdate(newQuantity: number): void
  onReviewProduct(): void
  limitQuantity: number
  renderQuantityError(limitQuantity: number): void
}

export const ReviewProductDetails: FunctionComponent<TProductDetailsProps> = ({
  id,
  name,
  price = 0,
  description,
  color,
  size,
  quantity = 0,
  limitQuantity,
  onRemove,
  onUpdate,
  onReviewProduct,
  renderQuantityError
}) => {
  const [productQuantity, setProductQuantity] = useState(quantity)

  const handleRemoveQuantity = (quantity: number) => {
    setProductQuantity(quantity - 1)
  }

  const handleAddQuantity = (quantity: number, limitQuantity: number) => {
    const newQuantity = quantity + 1

    if (newQuantity > limitQuantity) {
      renderQuantityError(limitQuantity)
    }

    setProductQuantity(newQuantity)
  }

  const handleChangeQuantity = (productQuantity: string, limitQuantity: number) => {
    if (Number(productQuantity) > limitQuantity) {
      renderQuantityError(limitQuantity)
    }

    setProductQuantity(Number(productQuantity))
  }

  const addDisabled = productQuantity > limitQuantity || quantity === productQuantity

  return (
    <VStack w="full" px={6} pb={6}>
      <Center pb={6}>
        <Heading fontSize="16px" mt="24px" mb={4}>
          {name}
        </Heading>
        <Text fontSize="12px" textAlign="center" mb={6}>
          {`${description} `}

          {`Cor: ${color} `}

          {`Tamanho: ${size}`}
        </Text>
        <Pressable onPress={onReviewProduct}>
          <Heading fontSize="12px" fontWeight="semibold" lineHeight="16px" color="primary.500">
            Revisar detalhes do produto
          </Heading>
        </Pressable>
      </Center>
      <VStack>
        <View flexDirection="row" justifyContent="space-between">
          <HStack
            justifyContent="space-between"
            borderRadius="50px"
            borderWidth="1px"
            borderColor="gray.200"
            bg={!size ? 'gray.200' : 'white'}
            width={{ base: 31, lg: 35 }}
            mr={{ sm: 0 }}>
            <Pressable
              onPress={() => handleRemoveQuantity(productQuantity)}
              borderRadius={15}
              disabled={productQuantity < 1}>
              <Center h="50px" w={10}>
                <MinusIcon color={productQuantity < 1 ? 'gray.500' : 'primary.500'} size={3} />
              </Center>
            </Pressable>

            <Input
              isDisabled={!size}
              keyboardType="number-pad"
              bg="white"
              borderColor="transparent"
              _disabled={{ backgroundColor: 'gray.200' }}
              value={String(productQuantity)}
              color={!size ? 'white' : 'rgb(18,19,22)'}
              textAlign="center"
              mt="1px"
              fontFamily="heading"
              fontWeight="semibold"
              fontSize={{ base: '16px', lg: '14px' }}
              lineHeight="21px"
              onChangeText={(productQuantity: string) =>
                handleChangeQuantity(productQuantity, limitQuantity)
              }
              type="text"
              h="12"
              w="12"
            />

            <Pressable
              onPress={() => handleAddQuantity(productQuantity, limitQuantity)}
              borderRadius={15}
              disabled={!size}>
              <Center h="50px" w={10}>
                <AddIcon color={!size ? 'white' : 'red.500'} size={3} />
              </Center>
            </Pressable>
          </HStack>

          {id &&
            (productQuantity === 0 ? (
              <Button onPress={() => onRemove(id)} w={47}>
                Remover
              </Button>
            ) : (
              <Button
                onPress={() => onUpdate(productQuantity)}
                bg={addDisabled ? 'gray.200' : 'primary.500'}
                disabled={addDisabled}
                _stack={{ flex: 1, justifyContent: 'space-between' }}
                endIcon={
                  <Heading fontSize="16px" fontWeight="semibold" color="white">
                    {price * productQuantity} pts
                  </Heading>
                }
                w={47}>
                Atualizar
              </Button>
            ))}
        </View>
      </VStack>
    </VStack>
  )
}

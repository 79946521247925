import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { Address, AddressData } from './types'
import { ResponseError } from '../../types'

export const useGetAddressQuery = () =>
  useQuery<AxiosResponse<AddressData>, AxiosError<ResponseError>>('getAddress', requests.getAddress)

export const useSaveAddressMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<Address>, AxiosError<ResponseError>, Address>(
    requests.saveAddress,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getAddress')
      }
    }
  )
}

export const useDeleteAddressMutation = () =>
  useMutation<AxiosResponse<Address>, AxiosError<ResponseError>, Address>(requests.deleteAddress)

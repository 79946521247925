import { FunctionComponent } from 'react'

import { Box, Pressable, useBreakpointValue } from 'native-base'

export type ColorRadioProps = {
  active: boolean
  color: string
  onPress: () => void
}

export const ColorRadio: FunctionComponent<ColorRadioProps> = ({ active, color, onPress }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <Pressable
      bg={active ? 'white' : color}
      borderColor={active ? 'red.500' : 'gray.200'}
      borderRadius={20}
      borderWidth={1}
      h={10}
      mb={4}
      mr={isDesktop ? 6 : 2}
      onPress={onPress}
      justifyContent="center"
      alignItems="center"
      w={10}>
      <Box
        h="34px"
        w="34px"
        bgColor={color}
        borderRadius={20}
        borderWidth={active ? 1 : 0}
        borderColor={active ? 'gray.300' : 'none'}
      />
    </Pressable>
  )
}

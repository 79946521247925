import { FunctionComponent } from 'react'

import { Actionsheet, Button, Center, Heading, Text } from 'native-base'

export type InsufficientFundsFeedbackProps = {
  isOpen: boolean
  onClose: () => void
}

export const InsufficientFundsFeedback: FunctionComponent<InsufficientFundsFeedbackProps> = ({
  isOpen,
  onClose
}) => {
  return (
    <Actionsheet hideDragIndicator isOpen={isOpen}>
      <Actionsheet.Content px={6} pt={8} pb={15}>
        <Center mb={14}>
          <Heading>Seu saldo é insuficiente</Heading>

          <Text pt={4} fontSize="16px" fontFamily="body" lineHeight="24px" textAlign="center">
            O valor do pedido excede a quantidade de pontos que você tem disponível. Revise seu
            pedido para finalizarmos seu resgate.
          </Text>
        </Center>

        <Button w="full" onPress={onClose}>
          Revisar pedido
        </Button>
      </Actionsheet.Content>
    </Actionsheet>
  )
}

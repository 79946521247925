import { useEffect, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { useBottomSheetContext } from 'contexts/BottomSheetContext'
import { Address, useGetAddressQuery } from 'integration/resources/address'
import { useCheckoutMutation } from 'integration/resources/checkout/hooks'
import { CheckoutPayload } from 'integration/resources/checkout/types'
import { useBreakpointValue } from 'native-base'
import { useCheckoutAtomValue, useSetCheckoutAtom } from 'src/store/checkout'

import { UseCartScreen } from './CartScreen.types'
import { useProfileScreen } from '../ProfileScreen/useProfileScreen'

export const useCartScreen: UseCartScreen = ({ navigation }) => {
  const [showInsufficientFundsModal, setInsufficientFundsModal] = useState(false)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const bottomSheetContext = useBottomSheetContext()

  const checkoutAtomString = useCheckoutAtomValue()

  const { mutate, isLoading: isLoadingCheckout } = useCheckoutMutation()

  const [orderId, setCheckoutSuccess] = useState<number>()

  const setCheckoutAtom = useSetCheckoutAtom()

  const addresses: Address[] = useGetAddressQuery()?.data?.data?.results ?? []

  const currentMainAddress = addresses?.filter((address) => address.main)

  const [mainAddress]: Address[] = currentMainAddress

  const checkoutAtom =
    typeof checkoutAtomString === 'string' ? JSON.parse(checkoutAtomString) : checkoutAtomString

  const [currentAddress] = addresses.filter((address) => address?.id === checkoutAtom?.address?.id)

  const selectedAddress: Address = currentAddress ?? mainAddress

  const linkTo = useLinkTo()

  const { resume } = useProfileScreen()

  const [reviewDetails, setShowReviewDetails] = useState<any | undefined>()

  const renderQuantityError = (limitQuantity: number) =>
    bottomSheetContext.open({
      description: `Há somente ${limitQuantity} ${
        limitQuantity === 1 ? 'disponível' : 'disponíveis'
      } para o tamanho escolhido.`,
      title: 'Erro ao selecionar o produto!'
    })

  const goToProducts = () => linkTo(`/produtos/todos`)

  const goToAddress = () => {
    navigation.goBack()

    linkTo('/carrinho/endereco')
  }

  const handleReviewProduct = () => {
    navigation.goBack()

    linkTo(`/produto/${reviewDetails.productSlug}/editar/${reviewDetails.productCartId}`)
  }

  const handleRemoveAmount = () => {
    const currentCheckout =
      typeof checkoutAtom === 'string' ? JSON.parse(checkoutAtom) : checkoutAtom

    const currentProducts = currentCheckout?.products?.filter(
      (product: { productId: string }) => product.productId !== reviewDetails.productId
    )

    const products = [...currentProducts]

    const totalItems = products.reduce(
      (previousValue, currentValue) => previousValue + currentValue.quantity,
      0
    )

    const totalPoints = products.reduce(
      (previousValue, currentValue) => previousValue + currentValue.price * currentValue.quantity,
      0
    )

    const total = {
      items: totalItems,
      points: totalPoints
    }

    setCheckoutAtom({ products, total })

    const isInsufficientFunds = total.points > (resume?.balance ?? 0)

    setInsufficientFundsModal(isInsufficientFunds)

    setShowReviewDetails(undefined)
  }

  const handleUpdateAmount = (quantity: number) => {
    const currentCheckout =
      typeof checkoutAtom === 'string' ? JSON.parse(checkoutAtom) : checkoutAtom

    const currentProducts = currentCheckout?.products?.filter(
      (product: { productId: string }) => product.productId !== reviewDetails.productId
    )

    const products = [...(currentProducts ?? []), { ...reviewDetails, quantity }]

    const totalItems = products.reduce(
      (previousValue, currentValue) => previousValue + currentValue.quantity,
      0
    )

    const totalPoints = products.reduce(
      (previousValue, currentValue) => previousValue + currentValue.price * currentValue.quantity,
      0
    )

    const total = {
      items: totalItems,
      points: totalPoints
    }

    setCheckoutAtom({ products, total })

    const isInsufficientFunds = total.points > (resume?.balance ?? 0)

    setInsufficientFundsModal(isInsufficientFunds)

    setShowReviewDetails(undefined)
  }

  const handleCheckoutSubmit = (checkoutAtom: CheckoutPayload, deliveryAddressId: string) => {
    const products = checkoutAtom.products.map((product: any) => ({
      id: product.productId,
      size_id: product?.size.sizeId,
      color_id: product?.color.colorId,
      quantity: product?.quantity
    }))

    const payload = { products, address_id: deliveryAddressId }

    mutate(payload, {
      onError: () => {
        setCheckoutAtom(null)

        linkTo('/erro')
      },
      onSuccess: (responseData) => {
        setCheckoutSuccess(responseData?.data?.order_id)
      }
    })
  }

  const balanceIsLoaded = resume?.balance !== undefined

  const balanceValue = resume?.balance ?? 0

  const cartPoints = checkoutAtom?.total?.points

  const insufficientFunds = balanceIsLoaded && cartPoints > balanceValue

  useEffect(() => {
    setInsufficientFundsModal(insufficientFunds)
  }, [insufficientFunds])

  return {
    isMobile,
    setCheckoutAtom,
    resume,
    checkoutAtom,
    setShowReviewDetails,
    reviewDetails,
    handleRemoveAmount,
    handleUpdateAmount,
    handleReviewProduct,
    renderQuantityError,
    goToProducts,
    balanceIsLoaded,
    insufficientFunds,
    goToAddress,
    showInsufficientFundsModal,
    setInsufficientFundsModal,
    selectedAddress,
    orderId,
    handleCheckoutSubmit,
    isLoadingCheckout
  }
}

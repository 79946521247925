import { useCallback } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { useBreakpointValue } from 'native-base'

import { UseEntryScreen } from './EntryScreen.types'

export const useEntryScreen: UseEntryScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  const goToCreateAccountRegisterNameScreen = useCallback(() => linkTo('/cadastrar/nome'), [linkTo])

  const goToLoginScreen = useCallback(() => linkTo('/entrar'), [linkTo])

  return {
    goToCreateAccountRegisterNameScreen,
    goToLoginScreen,
    isMobile
  }
}

import { Icons } from 'atoms'
import { Box, Button, Center, Container, FormControl, Heading, Input, VStack } from 'native-base'
import { Controller } from 'react-hook-form'
import { Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Animated, { Layout } from 'react-native-reanimated'
import { RootStackScreenComponent } from 'src/navigation'
import { validateEmail } from 'src/utils'

import { useRegisterScreen } from './useRegisterScreen'

export const RegisterEmailScreen: RootStackScreenComponent<'RegisterEmail'> = ({
  navigation,
  route
}) => {
  const { control, isLoading, watch, navigateToPassword } = useRegisterScreen({ navigation, route })

  const emailValue: string = watch('email') as string

  return (
    <KeyboardAwareScrollView style={{ flex: 1 }}>
      <VStack flex={1} justifyContent="center" pt={Platform.OS === 'ios' ? 0 : 4} p={5}>
        <Box safeArea h="full">
          <Center h={12}>
            <Button left={0} onPress={navigation.goBack} position="absolute" variant="unstyled">
              <Icons.Chevron direction="left" size="15px" />
            </Button>
          </Center>

          <Container py={8} px={1}>
            <VStack w="full">
              <Controller
                control={control}
                rules={{
                  required: 'Digite seu e-mail',
                  validate: (value) => value && validateEmail(value)
                }}
                render={({
                  field: { onChange: onChangeText, onBlur, value },
                  formState: { errors }
                }) => (
                  <FormControl isInvalid={!!errors.email} isRequired mb={15}>
                    <Heading
                      fontSize="24px"
                      fontWeight="bold"
                      lineHeight={{ base: '30px', lg: '28px' }}
                      mb={10}>
                      Digite seu e-mail
                    </Heading>

                    <Input
                      {...{ onChangeText, onBlur, value }}
                      autoCapitalize="none"
                      autoCorrect={false}
                      enablesReturnKeyAutomatically
                      onSubmitEditing={navigateToPassword}
                      returnKeyType="next"
                      keyboardType="email-address"
                      placeholder="E-mail"
                      InputLeftElement={<Icons.Mail size={5} color="gray.700" ml={4} />}
                    />
                    {errors.email && (
                      <FormControl.ErrorMessage fontSize="16px" lineHeight="24px" mt={15}>
                        {errors.email.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
                name="email"
              />

              <Animated.View layout={Layout.springify()}>
                <Button
                  isLoading={isLoading}
                  isDisabled={!(emailValue?.length > 0)}
                  _disabled={{ opacity: 1 }}
                  onPress={navigateToPassword}
                  w="full">
                  Confirmar e-mail
                </Button>
              </Animated.View>
            </VStack>
          </Container>
        </Box>
      </VStack>
    </KeyboardAwareScrollView>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AddPlusIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.10294 0.702944C9.55303 0.252856 10.1635 0 10.8 0H13.2C13.8365 0 14.447 0.252856 14.8971 0.702944C15.3471 1.15303 15.6 1.76348 15.6 2.4V8.4H21.6C22.2365 8.4 22.847 8.65286 23.2971 9.10294C23.7471 9.55303 24 10.1635 24 10.8V13.2C24 13.8365 23.7471 14.447 23.2971 14.8971C22.847 15.3471 22.2365 15.6 21.6 15.6H15.6V21.6C15.6 22.2365 15.3471 22.847 14.8971 23.2971C14.447 23.7471 13.8365 24 13.2 24H10.8C10.1635 24 9.55303 23.7471 9.10294 23.2971C8.65286 22.847 8.4 22.2365 8.4 21.6V15.6H2.4C1.76348 15.6 1.15303 15.3471 0.702944 14.8971C0.252856 14.447 0 13.8365 0 13.2V10.8C0 10.1635 0.252856 9.55303 0.702944 9.10294C1.15303 8.65286 1.76348 8.4 2.4 8.4H8.4V2.4C8.4 1.76348 8.65286 1.15303 9.10294 0.702944ZM13.2 2.4H10.8V9.6C10.8 10.2627 10.2627 10.8 9.6 10.8H2.4V13.2H9.6C10.2627 13.2 10.8 13.7373 10.8 14.4V21.6H13.2V14.4C13.2 13.7373 13.7373 13.2 14.4 13.2H21.6V10.8H14.4C13.7373 10.8 13.2 10.2627 13.2 9.6V2.4Z"
      fill="currentColor"
    />
  </Icon>
)

import { CheckIcon } from 'native-base'

export const Select = {
  defaultProps: {
    _selectedItem: {
      _stack: { flex: 1, justifyContent: 'space-between' },
      _text: { color: 'primary.500' },
      endIcon: <CheckIcon size="5" color="primary.500" />
    }
  }
}

import { FunctionComponent, useCallback } from 'react'

import { Box, FlatList, Skeleton } from 'native-base'

export const CategoryListPreview: FunctionComponent = () => {
  const renderItem = useCallback(() => {
    return (
      <Box mr={4}>
        <Skeleton h={32} w={39} rounded={{ base: 5, lg: '10px' }} />
      </Box>
    )
  }, [])

  const keyExtractor = useCallback((item: string) => `key-categories-${item}`, [])

  return (
    <FlatList
      data={['0', '1', '2', '4', '5']}
      horizontal
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      showsHorizontalScrollIndicator={false}
    />
  )
}

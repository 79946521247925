import { Suspense, lazy } from 'react'

import { LoadingIcon } from 'atoms/Icons/LoadingIcon'

import { RecoveryPasswordEmailScreenComponent } from './RecoveryPasswordEmailScreen.types'
import { useRecoveryPasswordEmailScreen } from './useRecoveryPasswordEmailScreen'

const RecoveryPasswordEmailScreenDesktop = lazy(
  () => import('./RecoveryPasswordEmailScreen.desktop')
)

const RecoveryPasswordEmailScreenMobile = lazy(() => import('./RecoveryPasswordEmailScreen.mobile'))

export const RecoveryPasswordEmailScreen: RecoveryPasswordEmailScreenComponent = ({
  navigation,
  route
}) => {
  const props = useRecoveryPasswordEmailScreen({ navigation, route })

  const Screen = props.isMobile
    ? RecoveryPasswordEmailScreenMobile
    : RecoveryPasswordEmailScreenDesktop

  return (
    <Suspense fallback={<LoadingIcon />}>
      <Screen {...props} />
    </Suspense>
  )
}

import { Icons, Version } from 'atoms'
import { Box, Flex, HStack, View, VStack } from 'native-base'
import { ImageBackground } from 'react-native'

import { FormLogin } from './FormLogin'
import { ResponsiveLoginScreenComponent } from './LoginScreen.types'
import bgDesk from '../../../assets/entry-bg-desktop.png'

export const LoginScreenDesktop: ResponsiveLoginScreenComponent = ({
  control,
  setRememberEmail,
  submit,
  error,
  handleRedirectToRecoveryPassword,
  isLoading,
  isMobile
}) => {
  return (
    <VStack flex={1} overflowY="hidden" justifyContent="center" p={0}>
      <Box h="full">
        <HStack h="full">
          <VStack width="1/2" justifyContent="center" alignItems="center" overflowY="auto" mb={4}>
            <View width="full" maxWidth="414px">
              <FormLogin
                control={control}
                setRememberEmail={setRememberEmail}
                submit={submit}
                error={error}
                isLoading={isLoading}
                isMobile={isMobile}
                handleRedirectToRecoveryPassword={handleRedirectToRecoveryPassword}
              />
            </View>

            <VStack justifyContent="center" alignItems="center" mb={-18} mt={10}>
              <Version />

              <Icons.LogoSantander color="gray.700" size={120} />
            </VStack>
          </VStack>
          <VStack width="1/2" height="140%">
            <ImageBackground
              source={bgDesk}
              resizeMode="cover"
              style={{
                flex: 1
              }}>
              <Flex
                alignItems="flex-start"
                pt={15}
                pl={17}
                justifyContent="flex-start"
                h="full"
                pb={10}
                pr={10}>
                <Icons.LogoStore color="white" />
              </Flex>
            </ImageBackground>
          </VStack>
        </HStack>
      </Box>
    </VStack>
  )
}

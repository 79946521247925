import { FunctionComponent } from 'react'

import NetInfo from '@react-native-community/netinfo'
import * as Notifications from 'expo-notifications'
import { enableFreeze } from 'react-native-screens'
import { onlineManager } from 'react-query'

import { AppProviders } from './AppProviders'
import { RootStackNavigator } from './navigation'

enableFreeze(true)

// Handler to enable notications
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false
  })
})

/**
 * Reference: https://react-query.tanstack.com/react-native#online-status-management
 */
onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(!!state.isConnected)
  })
})

export const App: FunctionComponent = () => (
  <AppProviders>
    <RootStackNavigator />
  </AppProviders>
)

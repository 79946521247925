import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const SettingsIcon: FunctionComponent<IIconProps & { filled?: boolean }> = ({
  filled,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      d="M9.0512 4.10877L9.7712 2.25597C9.89339 1.94345 10.1074 1.67527 10.385 1.48676C10.6626 1.29826 10.9908 1.19827 11.3264 1.19997H12.6752C13.0095 1.19972 13.3362 1.30039 13.6124 1.4888C13.8885 1.67721 14.1014 1.94459 14.2232 2.25597L14.9432 4.10877L17.3432 5.50077L19.3112 5.20317C19.6403 5.1546 19.9763 5.20596 20.2759 5.35059C20.5754 5.49523 20.8246 5.72647 20.9912 6.01437L21.668 7.18557C21.8346 7.47504 21.9102 7.80796 21.8849 8.14099C21.8597 8.47403 21.7347 8.79174 21.5264 9.05277L20.2856 10.608V13.392L21.5264 14.9472C21.7347 15.2082 21.8597 15.5259 21.8849 15.859C21.9102 16.192 21.8346 16.5249 21.668 16.8144L20.9912 17.9856C20.8246 18.2735 20.5754 18.5047 20.2759 18.6494C19.9763 18.794 19.6403 18.8453 19.3112 18.7968L17.3432 18.4992L14.9432 19.8912L14.2232 21.744C14.1014 22.0554 13.8885 22.3227 13.6124 22.5111C13.3362 22.6996 13.0095 22.8002 12.6752 22.8H11.3264C10.9921 22.8002 10.6654 22.6996 10.3892 22.5111C10.113 22.3227 9.90015 22.0554 9.7784 21.744L9.0584 19.8912L6.6584 18.4992L4.6904 18.7968C4.36133 18.8453 4.02527 18.794 3.72573 18.6494C3.4262 18.5047 3.17699 18.2735 3.0104 17.9856L2.3096 16.8144C2.14299 16.5249 2.0674 16.192 2.09265 15.859C2.11791 15.5259 2.24285 15.2082 2.4512 14.9472L3.692 13.392V10.608L2.4512 9.05277C2.24285 8.79174 2.11791 8.47403 2.09265 8.14099C2.0674 7.80796 2.14299 7.47504 2.3096 7.18557L2.9864 6.01437C3.15299 5.72647 3.4022 5.49523 3.70173 5.35059C4.00127 5.20596 4.33733 5.1546 4.6664 5.20317L6.6344 5.50077L9.0512 4.10877ZM9.0008 12C9.0008 12.5933 9.17674 13.1733 9.50639 13.6667C9.83603 14.16 10.3046 14.5445 10.8527 14.7716C11.4009 14.9987 12.0041 15.0581 12.5861 14.9423C13.168 14.8266 13.7026 14.5409 14.1221 14.1213C14.5417 13.7017 14.8274 13.1672 14.9432 12.5852C15.0589 12.0033 14.9995 11.4001 14.7724 10.8519C14.5454 10.3037 14.1609 9.83521 13.6675 9.50556C13.1742 9.17592 12.5941 8.99997 12.0008 8.99997C11.2051 8.99997 10.4421 9.31604 9.87948 9.87865C9.31687 10.4413 9.0008 11.2043 9.0008 12Z"
      stroke="currentColor"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={filled ? 'currentColor' : 'none'}
    />
  </Icon>
)

import { FunctionComponent } from 'react'

import { Button, Actionsheet, Center, Heading, Text } from 'native-base'

export type CheckoutSuccessFeedbackMobileProps = {
  isOpen: boolean
  onClose: () => void
  orderId: number
}

export const CheckoutSuccessFeedbackMobile: FunctionComponent<
  CheckoutSuccessFeedbackMobileProps
> = ({ isOpen, onClose, orderId }) => {
  return (
    <Actionsheet hideDragIndicator isOpen={isOpen}>
      <Actionsheet.Content px={6} pt={8} pb={15}>
        <Center pt={10} mb={14}>
          <Heading>Parabéns!</Heading>

          <Text pt={2} fontSize="16px" fontWeight={600} fontFamily="heading" lineHeight="21px">
            Seu pedido chegará logo.
          </Text>

          <Text pt={4} fontSize="16px" fontFamily="body" lineHeight="24px">
            O código do pedido é: #{orderId}
          </Text>
        </Center>

        <Button w="full" onPress={onClose}>
          Continuar comprando
        </Button>
      </Actionsheet.Content>
    </Actionsheet>
  )
}

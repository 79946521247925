import { FunctionComponent } from 'react'

import { Category } from 'integration/resources/categories'
import { VStack, Heading, Image } from 'native-base'

type Props = {
  category: Category
}

export const CategoryCard: FunctionComponent<Props> = ({
  category: { image_url, image_name, name }
}) => (
  <VStack
    h={32}
    alignItems="center"
    bg="background.base"
    px={{ base: '71px', lg: 14 }}
    py="30px"
    // @ts-ignore
    cursor="pointer"
    borderRadius="10px">
    <Image
      source={image_url ? { uri: image_url } : require('assets/not-found.png')}
      alt={image_name ?? name}
      resizeMode="contain"
      h={10}
      w={10}
    />
    <Heading
      fontSize={{ md: '14px', lg: '20px' }}
      lineHeight="20px"
      fontWeight={{ md: 'bold', lg: 'normal' }}
      color={{ md: 'gray.700', lg: 'gray.500' }}
      mt={2}>
      {name}
    </Heading>
  </VStack>
)

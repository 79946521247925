import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { Product, ProductsData } from './types'
import { ResponseError } from '../../types'

export const useGetAllProductsQuery = () =>
  useQuery<AxiosResponse<ProductsData>, AxiosError<ResponseError>>(
    'getAllProducts',
    requests.getAllProducts
  )

export const useGetAllProductsByCategoryQuery = (category: string) =>
  useQuery<AxiosResponse<ProductsData>, AxiosError<ResponseError>>(
    ['getAllProductsByCategory', category],
    () => requests.getAllProductsByCategory(category)
  )

export const useGetFeaturedProductsQuery = () =>
  useQuery<AxiosResponse<ProductsData>, AxiosError<ResponseError>>(
    'getFeaturedProducts',
    requests.getFeaturedProducts
  )

export const useGetProductQuery = (idOrSlug: string) =>
  useQuery<AxiosResponse<Product>, AxiosError<ResponseError>>(idOrSlug, requests.getProduct)

export const useGetProductDetailQuery = (slug?: string) =>
  useQuery<AxiosResponse<Product>, AxiosError<ResponseError>>([slug], requests.getProduct, {
    enabled: false,
    refetchOnWindowFocus: false
  })

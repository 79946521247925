import { FunctionComponent } from 'react'

import { Box, Center, Pressable, Text } from 'native-base'

export type SizeRadioProps = {
  active: boolean
  onPress: () => void
  size: string
  amount: number
}

export const SizeRadio: FunctionComponent<SizeRadioProps> = ({ amount, active, onPress, size }) => {
  const disabled = !amount

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      h={10}
      minW={10}
      mr={2}
      mb={4}
      bg={active ? 'red.500' : 'background.base'}
      borderRadius="20px"
      textAlign="center">
      <Center h={10} minW={10} position="relative">
        <Text color={active ? 'white' : disabled ? 'gray.200' : 'gray.700'} px={3}>
          {size}
        </Text>
        {disabled && (
          <Box
            bg="gray.200"
            h="3px"
            w="50px"
            position="absolute"
            style={{
              transform: [{ rotate: '45deg' }]
            }}
          />
        )}
      </Center>
    </Pressable>
  )
}

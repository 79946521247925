import { createRef, useMemo } from 'react'

import { Icons } from 'atoms'
import { InputPassword } from 'molecules'
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  Heading,
  HStack,
  Text,
  VStack
} from 'native-base'
import { Controller } from 'react-hook-form'
import { Platform, TextInput } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Animated, { Layout } from 'react-native-reanimated'
import { RootStackScreenComponent } from 'src/navigation'
import { validatePasswordMessage, validatePassword } from 'src/utils'

import { useRegisterScreen } from './useRegisterScreen'

export const RegisterPasswordScreen: RootStackScreenComponent<'RegisterPassword'> = ({
  navigation,
  route
}) => {
  const { control, isLoading, watch, submit } = useRegisterScreen({ navigation, route })

  const passwordValue: string = watch('password') as string

  const confirmPasswordInputRef = createRef<TextInput>()

  const focusOnConfirmPasswordInput = () => confirmPasswordInputRef.current?.focus()

  const passwordRules = useMemo(
    () =>
      Object.entries(validatePasswordMessage(passwordValue)).map((item) => {
        const color = item[1] !== null ? (item[1] ? 'success.500' : 'error') : 'gray.200'

        return (
          <HStack key={item[0]}>
            <Text color={color} fontSize="32px" lineHeight="32px" mr={2}>{`\u2022`}</Text>
            <Text color={color} fontSize="16px" lineHeight="24px">
              {item[0]}
            </Text>
          </HStack>
        )
      }),
    [passwordValue]
  )

  return (
    <KeyboardAwareScrollView style={{ flex: 1 }}>
      <VStack flex={1} justifyContent="center" pt={Platform.OS === 'ios' ? 0 : 4} p={5}>
        <Box safeArea h="full">
          <Center h={12}>
            <Button left={0} onPress={navigation.goBack} position="absolute" variant="unstyled">
              <Icons.Chevron direction="left" size="15px" />
            </Button>
          </Center>
          <Container py={8} px={1}>
            <VStack w="full">
              <Controller
                control={control}
                rules={{
                  required: 'Digite uma senha',
                  validate: (value) => value && validatePassword(value)
                }}
                render={({
                  field: { onChange: onChangeText, onBlur, value },
                  formState: { errors }
                }) => (
                  <FormControl isInvalid={!!errors.password} isRequired mb={13}>
                    <Heading
                      fontSize="24px"
                      fontWeight="bold"
                      lineHeight={{ base: '30px', lg: '28px' }}
                      mb={10}>
                      Escolha uma senha
                    </Heading>
                    <InputPassword
                      {...{ onChangeText, onBlur, value }}
                      autoCapitalize="none"
                      autoCorrect={false}
                      enablesReturnKeyAutomatically
                      onSubmitEditing={focusOnConfirmPasswordInput}
                      returnKeyType="next"
                      InputLeftElement={<Icons.Key size={5} color="gray.700" ml={4} />}
                    />
                    {errors.password && (
                      <FormControl.ErrorMessage>{errors.password.message}</FormControl.ErrorMessage>
                    )}
                    <Box mt={4}>{passwordRules}</Box>
                  </FormControl>
                )}
                name="password"
              />
              <Controller
                control={control}
                rules={{
                  required: 'Digite sua senha',
                  validate: (value) => value === passwordValue || 'As senhas não conferem.'
                }}
                render={({
                  field: { onChange: onChangeText, onBlur, value },
                  formState: { errors }
                }) => (
                  <FormControl isInvalid={!!errors.password_confirmation} isRequired mb={15}>
                    <Heading
                      fontSize="24px"
                      fontWeight="bold"
                      lineHeight={{ base: '30px', lg: '28px' }}
                      mb={10}>
                      Confirme a senha
                    </Heading>

                    <InputPassword
                      {...{ onChangeText, onBlur, value }}
                      autoCapitalize="none"
                      autoCorrect={false}
                      enablesReturnKeyAutomatically
                      onSubmitEditing={submit}
                      returnKeyType="next"
                      InputLeftElement={<Icons.Key size={5} color="gray.700" ml={4} />}
                    />
                    {errors.password_confirmation && (
                      <FormControl.ErrorMessage fontSize="16px" lineHeight="24px" mt={15}>
                        {errors.password_confirmation.message}
                      </FormControl.ErrorMessage>
                    )}
                  </FormControl>
                )}
                name="password_confirmation"
              />

              <Animated.View layout={Layout.springify()}>
                <Button
                  isLoading={isLoading}
                  isDisabled={!(passwordValue?.length > 0)}
                  _disabled={{ opacity: 1 }}
                  onPress={submit}
                  w="full">
                  Confirmar senha
                </Button>
              </Animated.View>
            </VStack>
          </Container>
        </Box>
      </VStack>
    </KeyboardAwareScrollView>
  )
}

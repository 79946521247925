import { FunctionComponent, useEffect, useRef } from 'react'

import { ProductImage } from 'integration/resources/products'
import { AnimatedSlideDot } from 'molecules'
import { Box, Heading, HStack, Image, Modal, Text, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import Animated, { useAnimatedScrollHandler } from 'react-native-reanimated'

export type FullScreenImagesProps = {
  description: string
  images: ProductImage[]
  isOpen: boolean
  name: string
  onClose: () => void
  scrollX: { value: number }
}

export const FullScreenImages: FunctionComponent<FullScreenImagesProps> = ({
  description,
  images,
  isOpen,
  name,
  onClose,
  scrollX
}) => {
  const refScrollView = useRef<Animated.ScrollView>(null)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const { width: SCREEN_WIDTH } = useWindowDimensions()

  useEffect(() => {
    const checkIsMountCarousel = setInterval(() => {
      if (refScrollView.current) {
        refScrollView.current?.scrollTo({
          animated: false,
          x: scrollX.value
        })

        clearInterval(checkIsMountCarousel)
      }
    }, 100)
  })

  return (
    <Modal bg="black" p={0} isOpen={isOpen} onClose={onClose}>
      <Modal.CloseButton
        _icon={{ color: 'white', size: 6 }}
        _android={{ backgroundColor: 'none' }}
        _ios={{ backgroundColor: 'none' }}
      />

      <VStack w={`${SCREEN_WIDTH}px`} justifyContent="center">
        <Animated.ScrollView
          horizontal
          onScroll={scrollHandler}
          pagingEnabled
          ref={refScrollView}
          scrollEventThrottle={32}
          showsHorizontalScrollIndicator={false}>
          {images.map((slide: ProductImage, index: number) => (
            <Box key={`${slide}${index}`} w={`${SCREEN_WIDTH}px`}>
              <Image source={{ uri: slide.imageUrl }} alt={name} h={100} w="full" />
            </Box>
          ))}
        </Animated.ScrollView>

        <HStack
          alignItems="flex-end"
          space={1}
          px={6}
          justifyContent="center"
          w={`${SCREEN_WIDTH}px`}
          position="absolute"
          h={460}>
          {images.map((slide: ProductImage, index: number) => (
            <AnimatedSlideDot
              index={index}
              key={`${slide}${index}`}
              scrollX={scrollX}
              slideWidth={SCREEN_WIDTH}
            />
          ))}
        </HStack>
      </VStack>

      <Modal.Footer
        alignSelf="flex-end"
        bg="transparent"
        bottom={0}
        px={6}
        pb={8}
        position="absolute"
        left={0}
        justifyContent="flex-start">
        <VStack w="full">
          <Heading color="white" fontSize="18px" mb={4} style={{ fontWeight: '600' }}>
            {name}
          </Heading>

          <Text color="white" fontSize="14px">
            {description}
          </Text>
        </VStack>
      </Modal.Footer>
    </Modal>
  )
}

import { FunctionComponent, useEffect, useMemo, useState } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { Address, useGetAddressQuery } from 'integration/resources/address'
import { AddressCard } from 'molecules'
import { Button, FlatList, HStack, Skeleton, VStack } from 'native-base'
import { useCheckoutAtom } from 'src/store/checkout'

export type AddressListProps = {
  isCheckoutFlow: boolean
}

export const AddressList: FunctionComponent<AddressListProps> = ({ isCheckoutFlow }) => {
  const linkTo = useLinkTo()

  const [checkoutAtom, setCheckoutAtom] = useCheckoutAtom()

  const currentCheckout = typeof checkoutAtom === 'string' ? JSON.parse(checkoutAtom) : checkoutAtom

  const { data, isLoading } = useGetAddressQuery()

  const addresses = useMemo(() => data?.data.results ?? [], [data])

  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(
    currentCheckout?.address ?? addresses.find((item) => item.main)
  )

  const [openItem, setOpenItem] = useState<string>()

  const collapseListener = (id: string) => setOpenItem(id)

  useEffect(() => {
    const mainAddress = addresses.find((item) => item.main)

    if (!openItem) {
      collapseListener(mainAddress?.id as string)
    }
  }, [openItem, addresses])

  const onPressCard = (item: Address) => {
    setSelectedAddress(item)

    collapseListener(item.id!)
  }

  const renderAddresses = () => (
    <FlatList
      data={addresses}
      keyExtractor={(item) => `${item.id}`}
      w="full"
      renderItem={({ item }) => {
        return (
          <AddressCard
            collapseId={openItem}
            onPress={() => onPressCard(item)}
            address={item}
            selectedAddress={selectedAddress}
            isCheckoutFlow={isCheckoutFlow}
          />
        )
      }}
      showsVerticalScrollIndicator={false}
    />
  )

  return (
    <>
      {isLoading ? (
        <>
          {[0, 1, 2].map((_, index) => (
            <VStack space={2} w="full" mb={6} key={index}>
              <HStack
                bg="white"
                borderRadius="10px"
                px={4}
                py={6}
                space={2}
                style={{
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowOffset: {
                    width: 0,
                    height: 3
                  },
                  shadowOpacity: 0.29,
                  shadowRadius: 4.65,
                  elevation: 7
                }}>
                <VStack space={2} w="full" ml={4}>
                  <Skeleton.Text w={30} lines={1} />
                  <Skeleton.Text w={50} lines={1} />
                  <Skeleton.Text w={15} lines={1} />
                </VStack>
              </HStack>
            </VStack>
          ))}
        </>
      ) : (
        <>
          {renderAddresses()}

          <VStack justifyContent="flex-end">
            {isCheckoutFlow && (
              <Button
                size="lg"
                isDisabled={!selectedAddress?.id}
                onPress={() => {
                  setCheckoutAtom({
                    ...currentCheckout,
                    address: selectedAddress
                  })

                  linkTo('/carrinho')
                }}
                _text={{
                  fontSize: '16px',
                  fontWeight: 'semibold',
                  lineHeight: '21px'
                }}>
                Continuar
              </Button>
            )}

            <Button
              variant="outline"
              size="lg"
              mt={4}
              onPress={() => {
                linkTo('/endereco/salvar')
              }}
              _text={{
                fontSize: '16px',
                fontWeight: 'semibold',
                lineHeight: '21px'
              }}>
              Cadastrar novo endereço
            </Button>
          </VStack>
        </>
      )}
    </>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

type Direction = 'up' | 'down' | 'left' | 'right'

const getPath = (direction: Direction) => {
  switch (direction) {
    case 'up':
      return (
        <Path
          key={Math.random()}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0802 5.59074C11.3717 5.46975 11.6843 5.40747 12 5.40747C12.3157 5.40747 12.6283 5.46975 12.9198 5.59074C13.2114 5.71173 13.4763 5.88906 13.6992 6.11256C13.7056 6.119 13.712 6.12552 13.7183 6.1321L23.6687 16.5721C24.1259 17.0518 24.1077 17.8114 23.6279 18.2687C23.1482 18.7259 22.3886 18.7077 21.9314 18.2279L12 7.80792L2.06865 18.2279C1.61141 18.7077 0.85183 18.7259 0.372087 18.2687C-0.107656 17.8114 -0.125895 17.0518 0.33135 16.5721L10.2818 6.1321C10.288 6.12552 10.2944 6.119 10.3008 6.11256C10.5237 5.88906 10.7886 5.71173 11.0802 5.59074Z"
          fill="currentColor"
        />
      )

    case 'left':
      return (
        <Path
          key={Math.random()}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2694 0.372087C18.7266 0.85183 18.7084 1.61141 18.2287 2.06865L7.80865 12L18.2287 21.9314C18.7084 22.3886 18.7266 23.1482 18.2694 23.6279C17.8122 24.1077 17.0526 24.1259 16.5728 23.6687L6.13283 13.7183C6.12625 13.712 6.11973 13.7056 6.11329 13.6992C5.88979 13.4763 5.71246 13.2114 5.59147 12.9198C5.47048 12.6283 5.4082 12.3157 5.4082 12C5.4082 11.6843 5.47048 11.3717 5.59147 11.0802C5.71246 10.7886 5.88979 10.5237 6.11329 10.3008C6.11973 10.2944 6.12625 10.288 6.13283 10.2818L16.5728 0.33135C17.0526 -0.125895 17.8122 -0.107656 18.2694 0.372087Z"
          fill="currentColor"
        />
      )

    case 'down':
      return (
        <Path
          key={Math.random()}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.372087 5.73137C0.85183 5.27413 1.61141 5.29237 2.06865 5.77211L12 16.1921L21.9314 5.77211C22.3886 5.29237 23.1482 5.27413 23.6279 5.73137C24.1077 6.18862 24.1259 6.9482 23.6687 7.42794L13.7183 17.8679C13.7131 17.8733 13.7079 17.8787 13.7027 17.884C13.7015 17.8852 13.7004 17.8863 13.6992 17.8875C13.4763 18.111 13.2114 18.2883 12.9198 18.4093C12.6283 18.5303 12.3157 18.5926 12 18.5926C11.6843 18.5926 11.3717 18.5303 11.0802 18.4093C10.7886 18.2883 10.5237 18.111 10.3008 17.8875C10.2944 17.881 10.288 17.8745 10.2818 17.8679L0.33135 7.42794C-0.125895 6.9482 -0.107656 6.18862 0.372087 5.73137Z"
          fill="currentColor"
        />
      )

    case 'right':
      return (
        <Path
          key={Math.random()}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.73174 0.372087C6.18899 -0.107656 6.94856 -0.125895 7.42831 0.33135L17.8683 10.2818C17.8749 10.288 17.8814 10.2944 17.8878 10.3008C18.1114 10.5237 18.2887 10.7886 18.4097 11.0802C18.5307 11.3717 18.5929 11.6843 18.5929 12C18.5929 12.3157 18.5307 12.6283 18.4097 12.9198C18.2887 13.2114 18.1114 13.4763 17.8878 13.6992C17.8814 13.7056 17.8749 13.712 17.8683 13.7183L7.42831 23.6687C6.94856 24.1259 6.18899 24.1077 5.73174 23.6279C5.2745 23.1482 5.29273 22.3886 5.77248 21.9314L16.1925 12L5.77248 2.06865C5.29273 1.61141 5.2745 0.85183 5.73174 0.372087Z"
          fill="currentColor"
        />
      )
  }
}

export const ChevronIcon: FunctionComponent<
  IIconProps & { direction: 'up' | 'down' | 'left' | 'right' }
> = ({ direction, ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>{getPath(direction)}</Icon>
)

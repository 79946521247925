import { memo } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'
import { theme } from 'src/theme'

const SvgComponent = (props: IIconProps) => (
  <Icon {...props}>
    <Path
      fill={theme.colors.red[500]}
      fillRule="evenodd"
      d="M4.627 2.91H3.47A2.975 2.975 0 0 0 .502 5.677L.007 12.82A2.975 2.975 0 0 0 2.975 16h10.05a2.975 2.975 0 0 0 2.968-3.18l-.495-7.142A2.975 2.975 0 0 0 12.53 2.91h-1.116v.618a1.06 1.06 0 0 1-1.064 1.055 1.06 1.06 0 0 1-1.064-1.055v-.618H6.754v.618a1.06 1.06 0 0 1-1.063 1.055 1.06 1.06 0 0 1-1.064-1.055v-.618Zm5.718 6.233a2.003 2.003 0 0 0-.306-.97l-1.67-2.658a2.036 2.036 0 0 1-.263-.642l-.07.112a1.975 1.975 0 0 0 0 2.126L9.37 9.237a1.975 1.975 0 0 1 0 2.125l-.07.112a2.017 2.017 0 0 0-.264-.642L7.034 7.643a2.036 2.036 0 0 1-.264-.642l-.07.112a1.978 1.978 0 0 0-.004 2.12l1.338 2.131c.414.66.414 1.47 0 2.126l-.07.112a2.016 2.016 0 0 0-.263-.642L6.032 10.3a1.982 1.982 0 0 1-.306-1.156c-1.783.43-3.016 1.34-3.016 2.396 0 1.466 2.385 2.658 5.326 2.658 2.942 0 5.327-1.19 5.327-2.658 0-1.055-1.233-1.967-3.018-2.398Z"
      clipRule="evenodd"
    />
    <Path
      fill={theme.colors.red[500]}
      fillRule="evenodd"
      d="M5.176 2.822C5.176 1.264 6.45 0 8.021 0c1.57 0 2.844 1.264 2.844 2.822v.669c0 .282-.23.51-.514.51a.513.513 0 0 1-.515-.51v-.669c0-.994-.813-1.8-1.815-1.8a1.808 1.808 0 0 0-1.815 1.8v.669c0 .282-.23.51-.515.51a.513.513 0 0 1-.515-.51v-.669Z"
      clipRule="evenodd"
    />
  </Icon>
)

export const NewsIcon = memo(SvgComponent)

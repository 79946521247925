import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { ProductsScreenComponent } from './ProductsScreen.types'
import { useProductsScreen } from './useProductsScreen'

const ProductsScreenDesktop = lazy(() => import('./ProductsScreen.desktop'))

const ProductsScreenMobile = lazy(() => import('./ProductsScreen.mobile'))

export const ProductsScreen: ProductsScreenComponent = ({ navigation, route }) => {
  const screen = useProductsScreen({ navigation, route })

  const Screen = screen.isMobile ? ProductsScreenMobile : ProductsScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...screen} />
    </Suspense>
  )
}

import { Suspense } from 'react'

import { Spinner } from 'native-base'

import { StatementScreenDesktop } from './StatementScreen.desktop'
import { StatementScreenMobile } from './StatementScreen.mobile'
import { StatementScreenComponent } from './StatementScreen.types'
import { useStatementScreen } from './useStatementScreen'

export const StatementScreen: StatementScreenComponent = ({ navigation }) => {
  const statementScreen = useStatementScreen({ navigation })

  const Screen = statementScreen.isMobile ? StatementScreenMobile : StatementScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...statementScreen} />
    </Suspense>
  )
}

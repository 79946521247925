import { useState } from 'react'

import { Icons } from 'atoms'
import { Button, Center, Flex, Heading, HStack, Skeleton, Text, VStack } from 'native-base'
import { Pressable } from 'react-native'

import { StatementListProps } from './StatementScreen.types'

const itemsPerPage = 6

export const ListPaginationStatement: StatementListProps = ({
  allStatements,
  normalizeOperationDate,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const indexOfLastItem = currentPage * itemsPerPage

  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  const currentItems = (allStatements?.results ?? []).slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil((allStatements?.results ?? []).length / itemsPerPage)

  const nextPage = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages))
  }

  const prevPage = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const goToPage = (page: number) => {
    const pageNumber = Math.max(1, Math.min(page, totalPages))

    setCurrentPage(pageNumber)
  }

  const contentPreview = new Array<number>(totalPages).fill(1)

  const emptyStatement = !allStatements?.results.length

  return (
    <VStack pb={40}>
      {isLoading ? (
        [0, 1, 2, 3].map((index) => (
          <HStack
            key={index}
            h="74px"
            justifyContent="space-between"
            alignItems="center"
            space={6}
            borderBottomWidth={1}
            borderColor="gray.100"
            mb={6}>
            <Skeleton size={10} rounded="full" />

            <Skeleton h="3" flex="1" size={10} rounded="full" />
          </HStack>
        ))
      ) : (
        <>
          {(currentItems ?? []).map((statement, index) => (
            <HStack
              h="74px"
              justifyContent="space-between"
              alignItems="center"
              borderBottomWidth={1}
              borderColor="gray.100"
              key={index}
              mb={6}>
              <Center
                size={10}
                borderRadius="full"
                marginRight={4}
                backgroundColor={statement.operation === '-' ? '#F3F5F9' : '#E2F3EA'}>
                <Icons.StatementType type={statement.operation === '+' ? 'in' : 'out'} size={4} />
              </Center>

              <Flex direction="column" marginRight="auto">
                <Heading fontSize="20px" fontWeight="bold" lineHeight="30px" color="gray.900">
                  {statement.description}
                </Heading>

                <Heading fontSize="16px" lineHeight="24px" fontWeight="normal" color="gray.700">
                  {statement.operation_value} Ponto{statement.operation_value > 1 ? 's' : ''}
                </Heading>
              </Flex>

              <Heading
                fontSize="16px"
                lineHeight="24px"
                fontWeight="normal"
                color="gray.500"
                textTransform="uppercase">
                {normalizeOperationDate(statement.operation_date)}
              </Heading>
            </HStack>
          ))}
          <Center mt={9}>
            {emptyStatement ? (
              <Text
                fontSize="20px"
                lineHeight="30px"
                pb="40px"
                fontFamily="heading"
                fontWeight={600}
                color="gray.500">
                Você ainda não teve recarga de pontos
              </Text>
            ) : (
              <HStack>
                <Button
                  colorScheme="background"
                  _pressed={{ bg: 'gray.200' }}
                  _hover={{ bg: 'gray.100' }}
                  width={12}
                  height={12}
                  rounded="full"
                  onPress={prevPage}
                  _disabled={{
                    opacity: 0.2,
                    background: 'background'
                  }}
                  isDisabled={currentPage === 1}
                  leftIcon={<Icons.Chevron direction="left" color="gray.900" size="sm" />}
                />
                <Center>
                  <HStack>
                    {contentPreview.map((number, index) => (
                      <Pressable onPress={() => goToPage(index + 1)} key={number}>
                        <Heading
                          px={13}
                          fontSize="20px"
                          lineHeight="30px"
                          fontWeight={600}
                          color={currentPage === index + 1 ? 'primary.500' : 'gray.900'}>
                          {index + 1}
                        </Heading>
                      </Pressable>
                    ))}
                  </HStack>
                </Center>
                <Button
                  colorScheme="background"
                  _pressed={{ bg: 'gray.200' }}
                  _hover={{ bg: 'gray.100' }}
                  width={12}
                  height={12}
                  rounded="full"
                  onPress={nextPage}
                  _disabled={{
                    opacity: 0.2,
                    background: 'background'
                  }}
                  isDisabled={currentPage === totalPages}
                  leftIcon={<Icons.Chevron direction="right" color="gray.900" size="sm" />}
                />
              </HStack>
            )}
          </Center>
        </>
      )}
    </VStack>
  )
}

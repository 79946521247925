import { RatingData, GetAllRatingParams, RatingPayload } from './types'
import client from '../../client'

export const getAllRating = async (params: GetAllRatingParams) => {
  const response = await client.get<RatingData>(`/v1/reviews/${params?.identifier}/`, {
    params: {
      ...params
    }
  })

  return response
}

export const postRating = (data: RatingPayload[]) =>
  client.post<RatingPayload[]>('v1/reviews/create', data)

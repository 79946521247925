import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import { ButtonGradient, Icons, Illustrations } from 'atoms'
import {
  Button,
  Heading,
  HStack,
  VStack,
  Text,
  Box,
  Skeleton,
  useBreakpointValue
} from 'native-base'

type PointsCardProps = {
  expirationDate?: string
  points?: number
  loading?: boolean
}

export const PointsCard: FunctionComponent<PointsCardProps> = ({
  expirationDate,
  points,
  loading = false
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const linkTo = useLinkTo()

  return (
    <Box
      bg="background.base"
      borderRadius="20px"
      shadow={isMobile ? 1 : undefined}
      justifyContent="space-between"
      p={4}
      maxW="413px"
      h={50}>
      <HStack
        pt={{ base: '11px', lg: '0px' }}
        space={4}
        justifyContent="space-between"
        alignItems="center">
        <VStack space={1}>
          <Text
            color="gray.500"
            fontSize={{ base: '10px', lg: '14px' }}
            lineHeight={{ base: '13px', lg: '18px' }}>
            Seus pontos expiram em
          </Text>
          <Skeleton.Text lines={1} w={20} isLoaded={!loading}>
            <Text
              color="gray.500"
              fontSize={{ base: '10px', lg: '14px' }}
              fontWeight="bold"
              lineHeight={{ base: '13px', lg: '18px' }}>
              {expirationDate}
            </Text>
          </Skeleton.Text>
        </VStack>
        <Icons.Store color="primary.500" size={7} />
      </HStack>
      <HStack alignItems="flex-end" justifyContent="space-between" space={{ base: 8, lg: '120px' }}>
        <VStack space={4}>
          <HStack space={2} alignItems="baseline">
            <Skeleton.Text lines={1} w={14} isLoaded={!loading}>
              <Heading color="gray.600" fontSize="40px" fontWeight="extrabold" lineHeight="41px">
                {points}
              </Heading>
            </Skeleton.Text>
            <Heading
              color="gray.600"
              fontSize="24px"
              fontWeight="semibold"
              lineHeight="30px"
              textTransform="uppercase">
              {' '}
              PTS
            </Heading>
          </HStack>

          {isMobile ? (
            <Button onPress={() => linkTo(`/extrato`)} py={0} px={4} size="xs" w={20}>
              Extrato
            </Button>
          ) : (
            <ButtonGradient onPress={() => linkTo(`/extrato`)} py={0} px={4} size="xs" w={20}>
              Extrato
            </ButtonGradient>
          )}
        </VStack>

        <Box>
          <Illustrations.GiftCards />
        </Box>
      </HStack>
    </Box>
  )
}

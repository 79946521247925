import { FunctionComponent } from 'react'

import Svg, { Path, Defs, LinearGradient, Stop } from 'react-native-svg'
import { resize } from 'src/utils'

type Props = {
  size?: number
}

export const StoreGradientIcon: FunctionComponent<Props> = ({ size = 454 }) => {
  const { height, width } = resize({ height: 100, size, width: 454 })

  return (
    <Svg {...{ viewBox: '0 0 454 100', width, height }}>
      <Path
        d="M43.8883 17.6229H31.5513C14.1532 17.6229 0.0493164 31.7268 0.0493164 49.1249V68.4237C0.0493164 85.8218 14.1532 99.9257 31.5513 99.9257H43.8883C61.2863 99.9257 75.3902 85.8218 75.3902 68.4237V49.1249C75.3902 31.7268 61.2863 17.6229 43.8883 17.6229Z"
        fill="white"
      />
      <Path
        d="M0.0297852 87.7573C0.476059 94.5903 6.16357 100 13.1106 100H24.3765C13.1056 97.972 4.18013 93.4895 0.0297852 87.7573V87.7573Z"
        fill="url(#paint0_linear_14060_32355)"
      />
      <Path
        d="M20.0377 62.1511C20.0377 65.2006 20.6823 68.2502 22.6112 71.2997L35.4936 92.0366C36.1382 93.2564 36.7829 95.0861 37.4275 96.9158L38.0721 95.696C40.6506 90.8167 40.6506 84.7177 38.0721 79.2285L27.7681 62.761C24.545 57.2718 24.545 51.1728 27.7681 46.2935L28.4128 45.0737C28.4128 46.9034 29.0574 48.7331 30.3466 50.5629L36.1432 59.7115L45.1579 74.9592C46.4422 76.7889 47.0918 78.6186 47.0918 80.4483L47.7364 79.2285C50.9545 74.3493 50.9545 67.6403 47.7364 62.761L38.0721 46.2935C34.854 40.8044 34.854 34.7053 38.0721 29.826V28.6062C38.7167 30.4359 39.3613 32.2657 40.0059 33.4855L52.8884 54.2223C54.1776 56.6619 54.8222 59.1016 55.4668 62.1511C64.7642 64.2486 71.9294 68.3593 75.5045 73.303V30.8376C75.5045 23.5931 69.6336 17.7271 62.394 17.7271H13.1155C5.87098 17.7271 0 23.598 0 30.8376V73.298C3.57515 68.3543 10.7403 64.2436 20.0377 62.1461V62.1511Z"
        fill="url(#paint1_linear_14060_32355)"
      />
      <Path
        d="M51.4307 100H62.3842C69.3312 100 75.0088 94.6002 75.465 87.7672C71.3295 93.4944 62.4735 97.967 51.4307 100V100Z"
        fill="url(#paint2_linear_14060_32355)"
      />
      <Path
        d="M18.4064 26.345C18.4064 28.388 16.7502 30.0392 14.7122 30.0392C12.6742 30.0392 11.0181 28.388 11.0181 26.345C11.0181 24.3021 12.6742 22.6509 14.7122 22.6509C16.7502 22.6509 18.4064 24.3071 18.4064 26.345Z"
        fill="white"
      />
      <Path
        d="M64.4816 26.345C64.4816 28.388 62.8254 30.0392 60.7874 30.0392C58.7494 30.0392 57.0933 28.388 57.0933 26.345C57.0933 24.3021 58.7445 22.6509 60.7874 22.6509C62.8304 22.6509 64.4816 24.3071 64.4816 26.345Z"
        fill="white"
      />
      <Path
        d="M60.8669 27.4508C60.0041 27.4508 59.3099 26.7516 59.3099 25.8938V22.6608C59.3099 10.7949 49.6555 3.114 37.7896 3.114C25.9237 3.114 16.2693 10.7949 16.2693 22.6608V25.8938C16.2693 26.7516 15.5701 27.4508 14.7123 27.4508C13.8544 27.4508 13.1553 26.7516 13.1553 25.8938V22.6608C13.1553 9.07423 24.208 0 37.7896 0C51.3712 0 62.4239 9.07423 62.4239 22.6608V25.8938C62.4239 26.7516 61.7247 27.4508 60.8669 27.4508V27.4508Z"
        fill="url(#paint3_linear_14060_32355)"
      />
      <Path d="M102.216 18.8129H112.986V56.5676H136.317V65.3394H102.216V18.8129Z" fill="#FF0000" />
      <Path
        d="M146.214 42.0786C146.214 28.2541 156.984 18.0195 171.672 18.0195C186.359 18.0195 197.129 28.1896 197.129 42.0786C197.129 55.9677 186.295 66.1377 171.672 66.1377C157.049 66.1377 146.214 55.9032 146.214 42.0786V42.0786ZM186.23 42.0786C186.23 33.2375 179.982 27.188 171.672 27.188C163.361 27.188 157.113 33.2375 157.113 42.0786C157.113 50.9198 163.361 56.9693 171.672 56.9693C179.982 56.9693 186.23 50.9198 186.23 42.0786Z"
        fill="#FF0000"
      />
      <Path
        d="M205.236 59.3593L211.216 52.1793C213.74 55.5709 216.532 57.2965 219.859 57.2965C224.178 57.2965 226.439 54.7032 226.439 49.5859V27.4557H210.086V18.8129H237.14V48.9909C237.14 60.4899 231.358 66.1377 220.39 66.1377C214.142 66.1377 208.623 63.7427 205.236 59.3593V59.3593Z"
        fill="#FF0000"
      />
      <Path d="M255.348 18.8129H266.118V65.3394H255.348V18.8129Z" fill="#FF0000" />
      <Path
        d="M327.396 18.8129V65.3394H318.555L295.359 37.0903V65.3394H284.723V18.8129H293.628L316.76 47.062V18.8129H327.396Z"
        fill="#FF0000"
      />
      <Path
        d="M388.675 18.8129V65.3394H377.905V46.2637H356.766V65.3394H345.996V18.8129H356.766V37.1597H377.905V18.8129H388.675Z"
        fill="#FF0000"
      />
      <Path
        d="M437.924 55.3726H416.319L412.198 65.3444H401.166L421.902 18.8179H432.539L453.345 65.3444H442.044L437.924 55.3726V55.3726ZM434.532 47.1959L427.154 29.3846L419.775 47.1959H434.532Z"
        fill="#FF0000"
      />
      <Path
        d="M176.496 98.919C176.521 98.0612 176.585 97.5455 176.789 96.8463C177.959 97.4562 179.471 97.7488 180.646 97.7488C182.61 97.7488 183.894 96.8909 183.894 95.4034C183.894 93.9158 182.947 93.3505 181.167 92.6513L179.927 92.1555C177.944 91.3671 176.521 90.058 176.521 87.6878C176.521 85.1143 178.256 83.468 181.911 83.468C183.24 83.468 184.593 83.6713 185.788 84.1473C185.719 85.025 185.61 85.501 185.407 86.1754C184.236 85.7242 182.927 85.5209 181.956 85.5209C179.833 85.5209 178.98 86.468 178.98 87.663C178.98 89.1754 179.724 89.6712 181.326 90.2563L182.635 90.7522C184.351 91.4067 186.379 92.5323 186.379 95.2844C186.379 97.8331 184.484 99.7967 180.607 99.7967C179.094 99.7967 177.518 99.4793 176.501 98.919H176.496Z"
        fill="#6C787A"
      />
      <Path
        d="M203.397 95.671H197.625L196.474 99.4396H194.154L199.023 84.058C199.722 83.8349 201.051 83.7208 201.998 83.7208L206.982 99.4396H204.502L203.397 95.671V95.671ZM202.767 93.5736L200.536 86.0415L198.26 93.5736H202.772H202.767Z"
        fill="#6C787A"
      />
      <Path
        d="M227.302 83.6316V99.4396H225.294L219.586 90.1472C219.021 89.2695 218.505 88.3175 218.074 87.42C218.119 89.3588 218.119 91.6149 218.119 93.4893V99.4446H215.887V83.9936C216.497 83.7903 217.464 83.6316 218.119 83.6316L223.756 92.6066C224.208 93.3058 224.704 94.2529 225.175 95.2C225.105 93.1719 225.041 90.7769 225.041 88.5257V83.9936C225.626 83.8151 226.528 83.6316 227.297 83.6316H227.302Z"
        fill="#6C787A"
      />
      <Path
        d="M247.166 85.9771H242.946V99.4397H240.532V85.9771H236.133C236.158 85.3672 236.247 84.4647 236.381 83.835H247.409C247.365 84.4647 247.275 85.3672 247.161 85.9771H247.166Z"
        fill="#6C787A"
      />
      <Path
        d="M262.364 95.671H256.593L255.442 99.4396H253.122L257.991 84.058C258.69 83.8349 260.019 83.7208 260.966 83.7208L265.949 99.4396H263.47L262.364 95.671V95.671ZM261.735 93.5736L259.503 86.0415L257.227 93.5736H261.74H261.735Z"
        fill="#6C787A"
      />
      <Path
        d="M286.265 83.6316V99.4396H284.257L278.549 90.1472C277.984 89.2695 277.468 88.3175 277.037 87.42C277.081 89.3588 277.081 91.6149 277.081 93.4893V99.4446H274.85V83.9936C275.46 83.7903 276.427 83.6316 277.081 83.6316L282.719 92.6066C283.171 93.3058 283.667 94.2529 284.138 95.2C284.068 93.1719 284.004 90.7769 284.004 88.5257V83.9936C284.589 83.8151 285.491 83.6316 286.26 83.6316H286.265Z"
        fill="#6C787A"
      />
      <Path
        d="M308.881 91.5257C308.881 96.2859 306.491 99.4396 301.369 99.4396H296.856V83.8547C298.007 83.6068 299.361 83.4729 301.141 83.4729C306.893 83.4729 308.876 87.0828 308.876 91.5257H308.881ZM306.377 91.481C306.377 87.8266 304.909 85.5704 301.146 85.5704C300.536 85.5704 299.881 85.615 299.251 85.6844V97.3421H301.463C304.78 97.3421 306.382 95.3587 306.382 91.4761L306.377 91.481Z"
        fill="#6C787A"
      />
      <Path
        d="M320.964 97.2975H327.639C327.614 97.9273 327.524 98.8099 327.391 99.4397H318.574V83.835H327.688C327.663 84.4647 327.574 85.3672 327.44 85.9771H320.969V90.5092H326.925C326.9 91.1638 326.81 91.9968 326.677 92.6513H320.969V97.2975H320.964Z"
        fill="#6C787A"
      />
      <Path
        d="M341.598 93.3951H339.634V99.4396H337.264V83.944C338.345 83.6713 339.724 83.4928 341.256 83.4928C345.52 83.4928 347.052 85.9721 347.052 88.3621C347.052 90.7522 345.679 92.3092 343.849 93.0331L347.523 99.4396H344.771L341.593 93.3951H341.598ZM341.236 91.3423C343.333 91.3423 344.573 90.5538 344.573 88.4563C344.573 86.5622 343.403 85.5457 341.236 85.5457C340.715 85.5457 340.155 85.5655 339.634 85.6349V91.3423H341.236Z"
        fill="#6C787A"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_14060_32355"
          x1={0.0297852}
          y1={93.8762}
          x2={24.3814}
          y2={93.8762}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#B30000" />
          <Stop offset={0.7} stopColor="#FF0000" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_14060_32355"
          x1={0}
          y1={57.3214}
          x2={75.4946}
          y2={57.3214}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#FF0000" />
          <Stop offset={0.7} stopColor="#B30000" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_14060_32355"
          x1={51.4307}
          y1={93.8861}
          x2={75.465}
          y2={93.8861}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#B30000" />
          <Stop offset={0.7} stopColor="#FF0000" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_14060_32355"
          x1={13.1553}
          y1={13.7254}
          x2={62.4189}
          y2={13.7254}
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#B30000" />
          <Stop offset={0.66} stopColor="#FF0000" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ZoomInIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.98751 2.98751C4.90038 1.07464 7.49479 0 10.2 0C12.9052 0 15.4996 1.07464 17.4125 2.98751C19.3254 4.90038 20.4 7.49479 20.4 10.2C20.4 12.502 19.6218 14.7238 18.2111 16.5137L23.649 21.9515C24.1176 22.4201 24.1176 23.1799 23.649 23.6485C23.1803 24.1172 22.4205 24.1172 21.9519 23.6485L16.5141 18.2108C14.7242 19.6217 12.5022 20.4 10.2 20.4C7.49479 20.4 4.90038 19.3254 2.98751 17.4125C1.07464 15.4996 0 12.9052 0 10.2C0 7.49479 1.07464 4.90038 2.98751 2.98751ZM10.2 2.4C8.13131 2.4 6.14735 3.22178 4.68457 4.68457C3.22178 6.14735 2.4 8.13131 2.4 10.2C2.4 12.2687 3.22178 14.2526 4.68457 15.7154C6.14735 17.1782 8.13131 18 10.2 18C12.2687 18 14.2526 17.1782 15.7154 15.7154C17.1782 14.2526 18 12.2687 18 10.2C18 8.13131 17.1782 6.14735 15.7154 4.68457C14.2526 3.22178 12.2687 2.4 10.2 2.4ZM10.2008 4.79998C10.8635 4.79998 11.4008 5.33723 11.4008 5.99998V8.99999H14.3996C15.0624 8.99999 15.5996 9.53725 15.5996 10.2C15.5996 10.8627 15.0624 11.4 14.3996 11.4H11.4008V14.4C11.4008 15.0627 10.8635 15.6 10.2008 15.6C9.53804 15.6 9.00078 15.0627 9.00078 14.4V11.4H5.99961C5.33687 11.4 4.79961 10.8627 4.79961 10.2C4.79961 9.53725 5.33687 8.99999 5.99961 8.99999H9.00078V5.99998C9.00078 5.33723 9.53804 4.79998 10.2008 4.79998Z"
      fill="currentColor"
    />
  </Icon>
)

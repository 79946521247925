import { createRef, useCallback, useEffect, useRef, useState } from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { useBreakpointValue } from 'native-base'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Keyboard, TextInput } from 'react-native'
import { useAuthMutation, AuthPayload } from 'src/integration/resources/auth'
import { useSetAuthAtom } from 'src/store/auth'
import { getDeviceDescription, registerForPushNotificationsAsync } from 'src/utils/device'

import { UseLoginScreen } from './LoginScreen.types'

export const refInputPassword = createRef<TextInput>()

export const handleInputPasswordFocus = () => refInputPassword.current?.focus()

const REMEMBER_EMAIL_STORAGE_KEY = 'rememberEmail'

export const useLoginScreen: UseLoginScreen = ({ navigation }) => {
  const rememberEmailRef = useRef(true)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const setAuthAtom = useSetAuthAtom()

  const { control, handleSubmit, setValue } = useForm<AuthPayload>({})

  const [error, setError] = useState<string>()

  const { isLoading, mutate } = useAuthMutation()

  const setRememberEmail = useCallback((value: boolean) => {
    rememberEmailRef.current = value
  }, [])

  const handleRedirectToRecoveryPassword = useCallback(() => {
    navigation.navigate('RecoveryPasswordEmail')
  }, [navigation])

  const submit = handleSubmit(
    useCallback<SubmitHandler<AuthPayload>>(
      async (formData) => {
        Keyboard.dismiss()

        const expoPushToken = await registerForPushNotificationsAsync()

        const deviceDescription = await getDeviceDescription()

        if (rememberEmailRef.current) {
          AsyncStorage.setItem(REMEMBER_EMAIL_STORAGE_KEY, formData.email)
        } else {
          AsyncStorage.removeItem(REMEMBER_EMAIL_STORAGE_KEY)
        }

        const payload = {
          ...formData,
          expo_push_token: expoPushToken,
          device_description: deviceDescription
        }

        mutate(payload, {
          onError: () => setError('Você inseriu um e-mail ou senha incorreta.'),
          onSuccess: (responseData) =>
            /**
             * NOTE: RootStackNavigator is listening for authAtom.
             * Once authAtom receives a value, the RootStackNavigator will handle the transition to the Onboarding screen.
             */
            setAuthAtom(responseData.data)
        })
      },
      [mutate, setAuthAtom]
    )
  )

  useEffect(() => {
    const getEmailFromStorage = async () => {
      const savedEmail = await AsyncStorage.getItem(REMEMBER_EMAIL_STORAGE_KEY)

      if (savedEmail) {
        setValue('email', savedEmail)
      }
    }

    getEmailFromStorage()

    registerForPushNotificationsAsync()
  }, [setValue])

  return {
    isMobile,
    error,
    control,
    isLoading,
    setRememberEmail,
    handleRedirectToRecoveryPassword,
    submit
  }
}

import { Icons } from 'atoms'
import { Box, Button, Center, Flex, Heading, HStack, ScrollView, Text, VStack } from 'native-base'

import { ResponsiveStatementScreenComponent } from './StatementScreen.types'

export const StatementScreenMobile: ResponsiveStatementScreenComponent = ({
  allStatements,
  profileResume,
  normalizeOperationDate,
  handleGoBack
}) => {
  return (
    <Box bg="white" borderTopRadius="10px" flex={1} mt="75px" pt={4}>
      <HStack w="full" alignItems="center" justifyContent="space-between" px={6} h="46px">
        <Button
          onPress={handleGoBack}
          variant="ghost"
          p={0}
          w={6}
          h={6}
          leftIcon={<Icons.Chevron direction="down" size={4} color="gray.800" />}
        />
        <Heading fontSize="16px" lineHeight="24px" fontWeight="bold" color="gray.700">
          Extrato
        </Heading>

        <Box />
      </HStack>

      <Box width="full">
        <Box px={6} mt={6} mb={6}>
          <Text fontSize="12px" lineHeight="16px" fontWeight="bold" color="gray.500">
            Pontos disponíveis
          </Text>
          <HStack alignItems="baseline" space={1}>
            <Heading fontSize="24px" lineHeight="30px" fontWeight="bold" color="red.500">
              {profileResume?.balance}
            </Heading>
            <Heading fontSize="12px" lineHeight="16px" fontWeight="bold" color="gray.500">
              {' '}
              PTS
            </Heading>
          </HStack>
        </Box>
        <ScrollView showsVerticalScrollIndicator={false}>
          <VStack pb={40}>
            {(allStatements?.results ?? []).map((statement, index) => (
              <HStack
                h="74px"
                justifyContent="space-between"
                alignItems="center"
                borderBottomWidth={1}
                borderColor="gray.100"
                key={index}
                mb={6}
                px={6}>
                <Center
                  size={10}
                  borderRadius="full"
                  marginRight={4}
                  p={3}
                  backgroundColor={statement.operation === '-' ? '#F3F5F9' : '#E2F3EA'}>
                  <Icons.StatementType type={statement.operation === '+' ? 'in' : 'out'} size={4} />
                </Center>

                <Flex direction="column" marginRight="auto">
                  <Heading fontSize="16px" fontWeight="bold" lineHeight="24px" color="gray.900">
                    {statement.description}
                  </Heading>

                  <Heading fontSize="14px" lineHeight="18px" fontWeight="normal" color="gray.700">
                    {statement.operation_value} Ponto{statement.operation_value > 1 ? 's' : ''}
                  </Heading>
                </Flex>

                <Heading
                  fontSize="14px"
                  lineHeight="18px"
                  fontWeight="normal"
                  color="gray.500"
                  textTransform="uppercase">
                  {normalizeOperationDate(statement.operation_date)}
                </Heading>
              </HStack>
            ))}
          </VStack>
        </ScrollView>
      </Box>
    </Box>
  )
}

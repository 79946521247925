import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const LockIcon: FunctionComponent<IIconProps & { locked?: boolean }> = ({
  locked,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    {locked ? (
      <>
        <Path
          key={Math.random()}
          d="M17.9996 10.8H5.99961C4.67414 10.8 3.59961 11.8745 3.59961 13.2V20.4C3.59961 21.7255 4.67414 22.8 5.99961 22.8H17.9996C19.3251 22.8 20.3996 21.7255 20.3996 20.4V13.2C20.3996 11.8745 19.3251 10.8 17.9996 10.8Z"
          stroke="currentColor"
          strokeWidth={2.4}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <Path
          key={Math.random()}
          d="M6 10.8V7.20001C6 5.60872 6.63214 4.08258 7.75735 2.95736C8.88257 1.83215 10.4087 1.20001 12 1.20001C13.5913 1.20001 15.1174 1.83215 16.2426 2.95736C17.3679 4.08258 18 5.60872 18 7.20001V10.8"
          stroke="currentColor"
          strokeWidth={2.4}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <Path
          key={Math.random()}
          d="M11.9996 17.7C11.5025 17.7 11.0996 17.2971 11.0996 16.8C11.0996 16.303 11.5025 15.9 11.9996 15.9"
          stroke="currentColor"
          strokeWidth={2.4}
          fill="none"
        />
        <Path
          key={Math.random()}
          d="M12 17.7C12.4971 17.7 12.9 17.2971 12.9 16.8C12.9 16.303 12.4971 15.9 12 15.9"
          stroke="currentColor"
          strokeWidth={2.4}
          fill="none"
        />
      </>
    ) : (
      <Path
        key={Math.random()}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0008 0.799988C8.02433 0.799988 4.80078 4.02354 4.80078 7.99999V9.80472C3.40235 10.2988 2.40039 11.6324 2.40039 13.2V20.4C2.40039 22.3882 4.01218 24 6.00039 24H18.0004C19.9886 24 21.6004 22.3882 21.6004 20.4V13.2C21.6004 11.2118 19.9886 9.60001 18.0004 9.60001H7.20078V7.99999C7.20078 5.34902 9.34981 3.19999 12.0008 3.19999C14.2155 3.19999 15.8699 4.00195 16.3545 5.81163C16.5259 6.45182 17.0373 7 17.7 7C18.3627 7 18.913 6.45798 18.8052 5.80407C18.2736 2.58043 15.559 0.799988 12.0008 0.799988ZM4.80039 13.2C4.80039 12.5373 5.33766 12 6.00039 12H18.0004C18.6631 12 19.2004 12.5373 19.2004 13.2V20.4C19.2004 21.0627 18.6631 21.6 18.0004 21.6H6.00039C5.33766 21.6 4.80039 21.0627 4.80039 20.4V13.2ZM14.1004 16.8C14.1004 15.6402 13.1602 14.7 12.0004 14.7C10.8406 14.7 9.90039 15.6402 9.90039 16.8C9.90039 17.9598 10.8406 18.9 12.0004 18.9C13.1602 18.9 14.1004 17.9598 14.1004 16.8Z"
        fill="currentColor"
      />
    )}
  </Icon>
)

import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { ProfileScreenComponent } from './ProfileScreen.types'
import { useProfileScreen } from './useProfileScreen'

const ProfileScreenDesktop = lazy(() => import('./ProfileScreen.desktop'))

const ProfileScreenMobile = lazy(() => import('./ProfileScreen.mobile'))

export const ProfileScreen: ProfileScreenComponent = () => {
  const profileScreen = useProfileScreen()

  const Screen = profileScreen.isMobile ? ProfileScreenMobile : ProfileScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...profileScreen} />
    </Suspense>
  )
}

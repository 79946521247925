import { memo } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'
import { theme } from 'src/theme'

const SvgComponent = (props: IIconProps) => (
  <Icon fill="none" {...props}>
    <Path
      fill={theme.colors.red[500]}
      fillRule="evenodd"
      d="M2.473 4.666h11.055a2.476 2.476 0 0 1 2.382 1.812c.096.345.115.707.057 1.061v.003l-1.069 6.393A2.473 2.473 0 0 1 12.467 16H8a1 1 0 1 1 0-2h4.461a.473.473 0 0 0 .464-.395l1.069-6.39a.474.474 0 0 0-.467-.549H2.473a.473.473 0 0 0-.467.549l.33 1.977a1 1 0 0 1-1.972.33L.033 7.538a2.473 2.473 0 0 1 2.44-2.873Z"
      clipRule="evenodd"
    />
    <Path
      fill={theme.colors.red[500]}
      fillRule="evenodd"
      d="M8 1.999a2.89 2.89 0 0 0-2.89 2.89v.778a1 1 0 1 1-2 0v-.778a4.89 4.89 0 1 1 9.779 0v.778a1 1 0 1 1-2 0v-.778a2.89 2.89 0 0 0-2.89-2.89ZM6.707 11.292a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.197.165l-2-1.125a1 1 0 0 1 .98-1.743l1.34.753 2.463-2.464a1 1 0 0 1 1.414 0Z"
      clipRule="evenodd"
    />
  </Icon>
)

export const ConfirmedOrderIcon = memo(SvgComponent)

import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { CartScreenComponent } from './CartScreen.types'
import { useCartScreen } from './useCartScreen'

const CartScreenDesktop = lazy(() => import('./CartScreen.desktop'))

const CartScreenMobile = lazy(() => import('./CartScreen.mobile'))

export const CartScreen: CartScreenComponent = ({ navigation }) => {
  const cartScreen = useCartScreen({ navigation })

  const Screen = cartScreen.isMobile ? CartScreenMobile : CartScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...cartScreen} />
    </Suspense>
  )
}

import { Suspense, lazy } from 'react'

import { LoadingIcon } from 'atoms/Icons/LoadingIcon'

import { NotificationsScreenComponent } from './NotificationsScreen.types'
import { useNotificationsScreen } from './useNotificationsScreen'

const NotificationsMobile = lazy(() => import('./NotificationsScreen.mobile'))

const NotificationsDesktop = lazy(() => import('./NotificationsScreen.desktop'))

export const NotificationsScreen: NotificationsScreenComponent = () => {
  const props = useNotificationsScreen()

  const Screen = props.isMobile ? NotificationsMobile : NotificationsDesktop

  return (
    <Suspense fallback={<LoadingIcon />}>
      <Screen {...props} />
    </Suspense>
  )
}

import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react'

import { Icons, ImageMask } from 'atoms'
import { BannerImageProps } from 'integration/resources/banner-images'
import { AnimatedSlideDot } from 'molecules'
import { Box, Container, HStack, IconButton, Image, useBreakpointValue, VStack } from 'native-base'
import { Platform, useWindowDimensions } from 'react-native'
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated'

type Props = {
  products: BannerImageProps[]
}

const CAROUSEL_INTERVAL = 4000

const mock = [
  {
    item: 1,
    file: 'home-banner-image-1.jpg'
  },
  {
    item: 2,
    file: 'home-banner-image-2.jpg'
  }
]

const switchFile = (index: number) => {
  switch (index) {
    case 1:
      return require('assets/home-banner-image-1.jpg')

    case 2:
      return require('assets/home-banner-image-2.jpg')
  }
}

export const FeaturedProducts: FunctionComponent<Props> = ({ products }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const windowDimensions = useWindowDimensions()

  const SLIDE_WIDTH = useMemo(() => windowDimensions.width, [windowDimensions.width])

  const SLIDE_PADDING_X = useBreakpointValue({ base: 6, lg: 0 })

  const refScrollView = useRef<Animated.ScrollView>(null)

  const scrollX = useSharedValue(0)

  const scrollHandler = useAnimatedScrollHandler((event) => {
    scrollX.value = event.contentOffset.x
  })

  const moveSlide = useCallback(
    (direction: 'left' | 'right') => {
      let nextX, x

      if (direction === 'left') {
        nextX = scrollX.value - SLIDE_WIDTH

        x = products.length
          ? nextX < 0
            ? (products.length - 1) * SLIDE_WIDTH
            : nextX
          : nextX < 0
          ? (mock.length - 1) * SLIDE_WIDTH
          : nextX
      } else {
        nextX = scrollX.value + SLIDE_WIDTH

        x = products.length
          ? nextX > (products.length - 1) * SLIDE_WIDTH
            ? 0
            : nextX
          : nextX > (mock.length - 1) * SLIDE_WIDTH
          ? 0
          : nextX
      }

      refScrollView.current?.scrollTo({
        animated: true,
        x
      })
    },
    [SLIDE_WIDTH, scrollX.value, products]
  )

  useEffect(() => {
    if (!isMobile) {
      const interval = setInterval(() => moveSlide('right'), CAROUSEL_INTERVAL)

      return () => {
        clearInterval(interval)
      }
    }
  }, [isMobile, moveSlide])

  if (!products.length && !isMobile) {
    return (
      <VStack space={{ base: 4, lg: 5 }}>
        <Box position="relative">
          <Animated.ScrollView
            horizontal
            onScroll={scrollHandler}
            pagingEnabled
            ref={refScrollView}
            scrollEventThrottle={32}
            showsHorizontalScrollIndicator={false}>
            {mock.map((item) => (
              <Box
                key={item.item}
                h={{ base: '426px', lg: '40rem', '2xl': '48rem' }}
                position="relative"
                px={SLIDE_PADDING_X}
                w={`${SLIDE_WIDTH}px`}>
                <Image
                  alt={`home-banner-image-${item}`}
                  borderWidth={{ base: 2, lg: 0 }}
                  borderColor="white"
                  h="full"
                  source={switchFile(item.item)}
                  resizeMode="cover"
                  w="full"
                />
              </Box>
            ))}
          </Animated.ScrollView>
          <Container
            flexDirection="row"
            justifyContent="space-between"
            left="50%"
            position="absolute"
            // @ts-ignore
            style={{ transform: [{ translateX: '-50%' }, { translateY: '-50%' }] }}
            top="50%">
            <IconButton
              _hover={{
                _icon: {
                  color: 'white'
                }
              }}
              _icon={{
                color: 'primary.500'
              }}
              _pressed={{
                _icon: {
                  color: 'white'
                },
                bg: 'primary.500'
              }}
              _focus={{
                _icon: {
                  color: 'primary.500'
                },
                bg: 'white'
              }}
              alignItems="center"
              borderRadius="full"
              bg="white"
              h={12}
              icon={<Icons.Chevron direction="left" size={5} />}
              justifyContent="center"
              onPress={() => moveSlide('left')}
              variant="solid"
              w={12}
            />
            <IconButton
              _hover={{
                _icon: {
                  color: 'white'
                }
              }}
              _icon={{
                color: 'primary.500'
              }}
              _pressed={{
                _icon: {
                  color: 'white'
                },
                bg: 'primary.500'
              }}
              _focus={{
                _icon: {
                  color: 'primary.500'
                },
                bg: 'white'
              }}
              alignItems="center"
              borderRadius="full"
              bg="white"
              h={12}
              icon={<Icons.Chevron direction="right" size={5} />}
              justifyContent="center"
              onPress={() => moveSlide('right')}
              variant="solid"
              w={12}
            />
          </Container>
        </Box>
        <Box alignItems={{ lg: 'center' }} px={{ base: SLIDE_PADDING_X, lg: 0 }}>
          <HStack alignItems="center" space={1}>
            {mock.map((item, index) => (
              <AnimatedSlideDot
                index={index}
                key={item.item}
                scrollX={scrollX}
                slideWidth={SLIDE_WIDTH}
              />
            ))}
          </HStack>
        </Box>
      </VStack>
    )
  }

  if ((products && products.length > 0) || !isMobile) {
    return (
      <VStack space={{ base: 4, lg: 5 }}>
        <Box position="relative">
          <Animated.ScrollView
            horizontal
            onScroll={scrollHandler}
            pagingEnabled
            ref={refScrollView}
            scrollEventThrottle={32}
            showsHorizontalScrollIndicator={false}>
            {isMobile
              ? products.map((item) => (
                  <Box
                    key={item.id}
                    h={{ base: '426px', lg: '970px', '2xl': '970px' }}
                    position="relative"
                    px={SLIDE_PADDING_X}
                    w={`${SLIDE_WIDTH}px`}>
                    <Image
                      alt={item.image_description}
                      borderWidth={{ base: 2, lg: 0 }}
                      borderColor="white"
                      h="full"
                      source={item.image ? { uri: item.image } : require('assets/not-found.png')}
                      resizeMode="cover"
                      w="full"
                    />
                    {isMobile && (
                      <Box
                        alignItems="center"
                        h="full"
                        ml={Platform.select({ web: 0, native: SLIDE_PADDING_X })}
                        position="absolute"
                        w={SLIDE_WIDTH - SLIDE_PADDING_X * 4 * 2}>
                        <ImageMask />
                      </Box>
                    )}
                  </Box>
                ))
              : products.map((item) => (
                  <Box
                    key={item.id}
                    h={{ base: '426px', lg: '40rem', '2xl': '48rem' }}
                    position="relative"
                    px={SLIDE_PADDING_X}
                    w={`${SLIDE_WIDTH}px`}>
                    <Image
                      alt={item.image_description}
                      borderWidth={{ base: 2, lg: 0 }}
                      borderColor="white"
                      h="full"
                      source={item.image ? { uri: item.image } : require('assets/not-found.png')}
                      resizeMode="cover"
                      w="full"
                    />
                  </Box>
                ))}
          </Animated.ScrollView>
          {!isMobile && (
            <Container
              flexDirection="row"
              justifyContent="space-between"
              left="50%"
              position="absolute"
              // @ts-ignore
              style={{ transform: [{ translateX: '-50%' }, { translateY: '-50%' }] }}
              top="50%">
              <IconButton
                _hover={{
                  _icon: {
                    color: 'white'
                  }
                }}
                _icon={{
                  color: 'primary.500'
                }}
                _pressed={{
                  _icon: {
                    color: 'white'
                  },
                  bg: 'primary.500'
                }}
                _focus={{
                  _icon: {
                    color: 'primary.500'
                  },
                  bg: 'white'
                }}
                alignItems="center"
                borderRadius="full"
                bg="white"
                h={12}
                icon={<Icons.Chevron direction="left" size={5} />}
                justifyContent="center"
                onPress={() => moveSlide('left')}
                variant="solid"
                w={12}
              />
              <IconButton
                _hover={{
                  _icon: {
                    color: 'white'
                  }
                }}
                _icon={{
                  color: 'primary.500'
                }}
                _pressed={{
                  _icon: {
                    color: 'white'
                  },
                  bg: 'primary.500'
                }}
                _focus={{
                  _icon: {
                    color: 'primary.500'
                  },
                  bg: 'white'
                }}
                alignItems="center"
                borderRadius="full"
                bg="white"
                h={12}
                icon={<Icons.Chevron direction="right" size={5} />}
                justifyContent="center"
                onPress={() => moveSlide('right')}
                variant="solid"
                w={12}
              />
            </Container>
          )}
        </Box>
        <Box alignItems={{ lg: 'center' }} px={{ base: SLIDE_PADDING_X, lg: 0 }}>
          <HStack alignItems="center" space={1}>
            {products.map((item, index) => (
              <AnimatedSlideDot
                index={index}
                key={item.id}
                scrollX={scrollX}
                slideWidth={SLIDE_WIDTH}
              />
            ))}
          </HStack>
        </Box>
      </VStack>
    )
  }

  return <></>
}

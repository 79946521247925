import { useBreakpointValue } from 'native-base'

import { UsePrivacyPolicyScreen } from './PrivacyPolicyScreen.types'

export const usePrivacyPolicyScreen: UsePrivacyPolicyScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return {
    isMobile
  }
}

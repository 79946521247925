import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { StatementsData } from './types'
import { ResponseError } from '../../types'

export const useGetAllStatementsQuery = () =>
  useQuery<AxiosResponse<StatementsData>, AxiosError<ResponseError>>(
    'getAllStatements',
    requests.getAllStatements
  )

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path, Rect } from 'react-native-svg'

export const DoneIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 12 12', ...props }}>
    <Rect width={12} height={12} rx={6} fill="#409565" />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.718 3.091a.6.6 0 0 1 .19.827l-3 4.8a.6.6 0 0 1-.932.106l-1.8-1.8a.6.6 0 0 1 .848-.848l1.267 1.266 2.6-4.16a.6.6 0 0 1 .827-.19Z"
      fill="#fff"
    />
  </Icon>
)

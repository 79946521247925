import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const AddIcon: FunctionComponent<IIconProps> = ({ ...props }) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2008 1.2C13.2008 0.537258 12.6635 0 12.0008 0C11.338 0 10.8008 0.537258 10.8008 1.2V10.8H1.2C0.537258 10.8 0 11.3372 0 12C0 12.6627 0.537258 13.2 1.2 13.2H10.8008V22.8C10.8008 23.4627 11.338 24 12.0008 24C12.6635 24 13.2008 23.4627 13.2008 22.8V13.2H22.8C23.4627 13.2 24 12.6627 24 12C24 11.3372 23.4627 10.8 22.8 10.8H13.2008V1.2Z"
      fill="currentColor"
    />
  </Icon>
)

import { FunctionComponent } from 'react'

import { useLinkTo } from '@react-navigation/native'
import {
  Address,
  useDeleteAddressMutation,
  useSaveAddressMutation
} from 'integration/resources/address'
import { HStack, Box, VStack, Text, Button } from 'native-base'
import { TouchableOpacity } from 'react-native'
import { useQueryClient } from 'react-query'

type Props = {
  address: Address
  collapseId?: string
  onPress: () => void
  selectedAddress?: Address
  isCheckoutFlow: boolean
}

export const AddressCard: FunctionComponent<Props> = ({
  address,
  onPress,
  collapseId,
  selectedAddress,
  isCheckoutFlow
}) => {
  const linkTo = useLinkTo()

  const queryClient = useQueryClient()

  const deleteAddressMutation = useDeleteAddressMutation()

  const updateAddressMutation = useSaveAddressMutation()

  const handleDeleteAddress = (address: Address) => {
    deleteAddressMutation.mutate(address, {
      onSuccess: () => {
        queryClient.invalidateQueries('getAddress')
      }
    })
  }

  const handleUpdateToMainAddress = (address: Address) => {
    updateAddressMutation.mutate({ ...address, main: true })
  }

  const renderAddresses = () => (
    <VStack space={1} w="full" ml={4}>
      <Text color="gray.800" fontSize="12px" lineHeight="18px" numberOfLines={1}>
        {address.street}, {address.number}, {address.complement ? `, ${address.complement}, ` : ''}
        {address.district}
      </Text>
      <Text color="gray.500" fontSize="12px" lineHeight="18px" numberOfLines={1}>
        CEP.:{address.postal_code}, {address.city}, {address.state}
      </Text>
      {address.main && (
        <HStack alignItems="center" space={1}>
          <Box bg="primary.500" borderRadius="full" h={1} w={1} />
          <Text color="primary.500" fontSize="12px" lineHeight="18px">
            Principal
          </Text>
        </HStack>
      )}
    </VStack>
  )

  return (
    <VStack space={2} w="full" mb={6}>
      <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
        <HStack
          bg="white"
          borderRadius="10px"
          borderColor="primary.500"
          borderWidth={isCheckoutFlow ? (selectedAddress?.id === address.id ? 1 : 0) : 0}
          px={4}
          py={6}
          space={2}
          style={{
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowOffset: {
              width: 0,
              height: 3
            },
            shadowOpacity: 0.29,
            shadowRadius: 4.65,
            elevation: 7
          }}>
          {renderAddresses()}
        </HStack>
      </TouchableOpacity>
      {collapseId === address.id && (
        <Box mt={4}>
          <VStack space={4}>
            {!address.main && (
              <Button
                onPress={() => handleUpdateToMainAddress(address)}
                isLoading={updateAddressMutation.isLoading}
                size="sm"
                _text={{
                  fontSize: '16px',
                  fontWeight: 'semibold',
                  lineHeight: '21px'
                }}>
                Tornar como principal
              </Button>
            )}
            <HStack space={4} alignItems="center">
              <Button
                onPress={() => linkTo(`/endereco/salvar/${address.id}`)}
                size="sm"
                flex={1}
                variant="outline"
                _text={{
                  fontSize: '16px',
                  fontWeight: 'semibold',
                  lineHeight: '21px'
                }}>
                Editar
              </Button>
              <Button
                onPress={() => handleDeleteAddress(address)}
                isLoading={deleteAddressMutation.isLoading}
                isDisabled={address.main}
                size="sm"
                flex={1}
                variant="outline"
                _text={{
                  fontSize: '16px',
                  fontWeight: 'semibold',
                  lineHeight: '21px'
                }}>
                Remover
              </Button>
            </HStack>
          </VStack>
        </Box>
      )}
    </VStack>
  )
}

import { FunctionComponent } from 'react'

import { Center, Flex, HStack, Skeleton, VStack } from 'native-base'

export const OrderItemPreview: FunctionComponent = () => {
  return (
    <VStack w="full" bg="white" borderRadius="10px" padding={4} shadow={1} mb={15}>
      <HStack w="full" justifyContent="space-between" pb={4}>
        <Skeleton.Text w={40} lines={1} />
        <Skeleton.Text w={20} lines={1} />
      </HStack>

      <HStack justifyContent="space-between" pb={4}>
        <Skeleton.Text w={30} lines={1} />
        <Skeleton.Text w={10} lines={1} />
      </HStack>

      <VStack
        textAlign="center"
        bg="white"
        borderColor="gray.100"
        borderTopWidth="1px"
        pt={4}
        mb={10}>
        {[0, 1].map((product, index) => (
          <HStack alignItems="center" mb={4} key={index}>
            <Skeleton w={12} h={12} />

            <Flex alignItems="flex-start" ml={4}>
              <HStack space={2} mb="5px">
                <Skeleton.Text w={30} lines={1} />
              </HStack>

              <Skeleton.Text w={40} lines={1} />
            </Flex>
          </HStack>
        ))}
      </VStack>

      <Center>
        <Skeleton mb="3" w="40" rounded="20" />
      </Center>
    </VStack>
  )
}

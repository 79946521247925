import { FunctionComponent, useCallback } from 'react'

import { Box, Button, Center, Heading } from 'native-base'
import { Alert, Linking } from 'react-native'

type Props = {
  segment?: string
}

export const DownloadBook: FunctionComponent<Props> = ({ segment }) => {
  const handleUrlBySegment = (segment = '') => {
    switch (segment) {
      case 'Veículos Santander':
        return 'https://store-laborit-api-prod.s3.amazonaws.com/_files/books/santander.pdf'

      case 'Consultores Bens e Serviços e SIM':
        return 'https://store-laborit-api-prod.s3.amazonaws.com/_files/books/bens-servicos.pdf'

      case 'Consultores Leves e Pesados':
        return 'https://store-laborit-api-prod.s3.amazonaws.com/_files/books/leves-pesados.pdf'

      default:
        break
    }
  }

  const handlePress = useCallback(async () => {
    const url = handleUrlBySegment(segment)

    if (url) {
      const supported = await Linking.canOpenURL(url)

      if (supported) {
        await Linking.openURL(url)
      } else {
        Alert.alert('Desculpe, não foi possível abrir o book!')
      }
    }
  }, [segment])

  return (
    <>
      {segment && (
        <Box background="background.base" p={10} borderRadius={10}>
          <Heading color="gray.900" fontSize="24px" fontWeight="bold" lineHeight="30px">
            Tudo certo até aqui?
          </Heading>
          <Heading fontSize="14px" lineHeight="30px" fontWeight="medium" color="gray.500">
            Baixe o nosso book e tenha uma nova experiência.
          </Heading>
          <Center>
            <Button
              variant="outline"
              onPress={handlePress}
              mt="38px"
              w={69}
              _stack={{ flex: 1, justifyContent: 'center' }}>
              Baixar book
            </Button>
          </Center>
        </Box>
      )}
    </>
  )
}

import { lazy, Suspense } from 'react'

import { Spinner } from 'native-base'

import { ErrorScreenComponent } from './ErrorScreen.types'
import { useErrorScreen } from './useErrorScreen'

const ErrorScreenDesktop = lazy(() => import('./ErrorScreen.desktop'))

const ErrorScreenMobile = lazy(() => import('./ErrorScreen.mobile'))

export const ErrorScreen: ErrorScreenComponent = ({ navigation, route }) => {
  const errorScreen = useErrorScreen({ navigation, route })

  const Screen = errorScreen.isMobile ? ErrorScreenMobile : ErrorScreenDesktop

  return (
    <Suspense fallback={<Spinner />}>
      <Screen {...errorScreen} />
    </Suspense>
  )
}

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

type SantanderIconProps = IIconProps & {
  useDefaultColor?: boolean
}

export const SantanderIcon: FunctionComponent<SantanderIconProps> = ({
  useDefaultColor,
  ...props
}) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.201 10.9929C17.1653 10.1281 16.9351 9.27017 16.5091 8.4947L12.7515 1.65401C12.465 1.13222 12.2671 0.573331 12.157 0L12.0002 0.28591C11.0699 1.97904 11.0699 4.06503 12 5.75816L15.0061 11.2307C15.9362 12.924 15.9362 15.0102 15.0061 16.7033L14.8491 16.9892C14.7392 16.4159 14.5411 15.857 14.2546 15.3354L9.74545 7.12643C9.45906 6.60481 9.26099 6.04575 9.1511 5.47259L8.99409 5.7585C8.06719 7.44592 8.06432 9.52351 8.98483 11.2136L8.98467 11.2138L12.0002 16.7033C12.9301 18.3965 12.9301 20.4826 12.0002 22.1759L11.843 22.4618C11.7331 21.8885 11.5352 21.3296 11.2486 20.8078L7.49106 13.9671C6.98712 13.0496 6.75679 12.0168 6.79896 10.9937C2.77654 12.0982 0 14.4438 0 17.1595C0 20.9374 5.37275 24 12.0002 24C18.6274 24 24 20.9374 24 17.1595C24 14.4436 21.2238 12.0975 17.201 10.9929Z"
      fill={useDefaultColor ? '#D8232A' : 'currentColor'}
    />
  </Icon>
)

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const TruckIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M22.945 2.055C22.27 1.38 21.355 1 20.4 1H12C11.045 1 10.13 1.38 9.455 2.055C8.78 2.73 8.4 3.645 8.4 4.6V5.8H6C4.41 5.8 2.885 6.43 1.755 7.555C0.63 8.685 0 10.21 0 11.8V16.6C0 17.265 0.535 17.8 1.2 17.8H22.8C23.465 17.8 24 17.265 24 16.6V4.6C24 3.645 23.62 2.73 22.945 2.055ZM8.4 15.4H2.4V11.8C2.4 10.845 2.78 9.93 3.455 9.255C4.13 8.58 5.045 8.2 6 8.2H8.4V15.4ZM21.6 15.4H10.8V4.6C10.8 4.28 10.925 3.975 11.15 3.75C11.375 3.525 11.68 3.4 12 3.4H20.4C20.72 3.4 21.025 3.525 21.25 3.75C21.475 3.975 21.6 4.28 21.6 4.6V15.4Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M9.6 18.55C8.935 18.55 8.4 19.09 8.4 19.75C8.4 20.07 8.275 20.37 8.05 20.6C7.595 21.055 6.805 21.055 6.355 20.6C6.13 20.375 6.005 20.07 6.005 19.75C6.005 19.085 5.47 18.55 4.805 18.55C4.14 18.55 3.605 19.09 3.605 19.75C3.605 20.71 3.98 21.615 4.66 22.295C5.34 22.975 6.245 23.35 7.205 23.35C8.165 23.35 9.07 22.975 9.75 22.295C10.43 21.615 10.805 20.71 10.805 19.75C10.805 19.085 10.27 18.55 9.605 18.55H9.6Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      d="M21.6 18.55C20.935 18.55 20.4 19.09 20.4 19.75C20.4 20.07 20.275 20.37 20.05 20.6C19.6 21.05 18.8 21.05 18.355 20.6C18.13 20.375 18.005 20.07 18.005 19.75C18.005 19.085 17.465 18.55 16.805 18.55C16.145 18.55 15.605 19.09 15.605 19.75C15.605 20.71 15.98 21.615 16.66 22.295C17.34 22.975 18.26 23.35 19.205 23.35C20.15 23.35 21.07 22.975 21.75 22.295C22.43 21.615 22.805 20.71 22.805 19.75C22.805 19.085 22.265 18.55 21.605 18.55H21.6Z"
      fill="currentColor"
    />
  </Icon>
)

import { AxiosError, AxiosResponse } from 'axios'
import { useMutation, useQueryClient } from 'react-query'

import * as requests from './requests'
import { CheckoutData, CheckoutPayload } from './types'
import { ResponseError } from '../../types'

export const useCheckoutMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<CheckoutData>, AxiosError<ResponseError>, CheckoutPayload>(
    requests.checkout,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getNotDeliveredOrders')
      }
    }
  )
}

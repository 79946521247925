import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const HomeAddressIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1515 0.351472C11.6201 -0.117157 12.3799 -0.117157 12.8485 0.351472L23.6485 11.1515C24.1172 11.6201 24.1172 12.3799 23.6485 12.8485C23.1799 13.3172 22.4201 13.3172 21.9515 12.8485L12 2.89706L2.04853 12.8485C1.5799 13.3172 0.820101 13.3172 0.351472 12.8485C-0.117157 12.3799 -0.117157 11.6201 0.351472 11.1515L11.1515 0.351472ZM3.6 14.4C3.6 13.7373 4.13726 13.2 4.8 13.2H19.2C19.8627 13.2 20.4 13.7373 20.4 14.4V22.8C20.4 23.4627 19.8627 24 19.2 24H4.8C4.13726 24 3.6 23.4627 3.6 22.8V14.4ZM6 15.6V21.6H18V15.6H6Z"
      fill="currentColor"
    />
  </Icon>
)

import { ProductOrder } from 'integration/resources/orders/types'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'

type OpenOrderEvaluateState = {
  isOpen: boolean
  products: ProductOrder[]
  responseType?: string
}

const isOpenOrderEvaluateAtom = atom<OpenOrderEvaluateState>({
  isOpen: false,
  products: []
})

export const useIsOpenOrderEvaluateAtom = () => useAtom(isOpenOrderEvaluateAtom)

export const useIsOpenOrderEvaluateAtomValue = () => useAtomValue(isOpenOrderEvaluateAtom)

export const useSetIsOpenOrderEvaluateAtom = () => useSetAtom(isOpenOrderEvaluateAtom)

import { AxiosError, AxiosResponse } from 'axios'
import { useQuery, useQueryClient } from 'react-query'

import * as requests from './requests'
import { Notification, NotificationsCount } from './types'
import { ResponseError } from '../../types'

export const useGetNotificationsQuery = () => {
  const queryClient = useQueryClient()

  return useQuery<AxiosResponse<{ results: Notification[] }>, AxiosError<ResponseError>>(
    'getNotifications',
    requests.getNotifications,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getNotificationsCount')
      }
    }
  )
}

export const useGetNotificationsCountQuery = () =>
  useQuery<AxiosResponse<NotificationsCount>, AxiosError<ResponseError>>(
    'getNotificationsCount',
    requests.getNotificationsCount
  )

import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { CEP } from './types'
import { ResponseError } from '../../types'

export const useGetByCEP = (cep: string) =>
  useQuery<AxiosResponse<CEP>, AxiosError<ResponseError>>(
    ['getByCEP', cep],
    ({ queryKey }) => requests.getByCEP(queryKey[1] as string),
    {
      enabled: false
    }
  )

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const GiftIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      d="M22.9 5.85464C22.2 5.15288 21.3 4.85213 20.4 4.85213H14.4L16.6 1.94486C17 1.44361 16.9 0.641604 16.4 0.240602C15.9 -0.160401 15 -0.0601504 14.6 0.541353L12 4.05013L9.4 0.541353C9 0.0401003 8.2 -0.160401 7.7 0.240602C7.1 0.641604 7 1.44361 7.4 1.94486L9.6 4.85213H3.6C2.6 4.85213 1.7 5.25313 1.1 5.85464C0.4 6.55639 0 7.45865 0 8.46115V12.0702C0 13.0727 0.4 13.8747 1.1 14.5764L1.2 14.6767V20.4912C1.2 21.4937 1.6 22.2957 2.3 22.9975C3 23.6992 3.9 24 4.8 24H12H19.2C20.2 24 21.1 23.599 21.7 22.9975C22.4 22.2957 22.8 21.3935 22.8 20.4912V14.6767L22.9 14.5764C23.6 13.8747 24 12.9724 24 12.0702V8.46115C24 7.45865 23.6 6.55639 22.9 5.85464ZM21.2 7.5589C21.4 7.7594 21.6 8.06015 21.6 8.3609V11.9699C21.6 12.2707 21.5 12.5714 21.2 12.7719C21 12.9724 20.7 13.0727 20.4 13.0727H13.2V7.05764H20.4C20.7 7.25815 21 7.3584 21.2 7.5589ZM2.8 7.5589C3 7.3584 3.3 7.25815 3.6 7.25815H10.8V13.2732H3.6C3.3 13.2732 3 13.1729 2.8 12.9724C2.6 12.7719 2.4 12.4712 2.4 12.1704V8.5614C2.4 8.1604 2.5 7.85965 2.8 7.5589ZM4 21.3935C3.8 21.193 3.6 20.8922 3.6 20.5915V15.6792H10.8V21.6942H4.8C4.5 21.6942 4.2 21.594 4 21.3935ZM20.4 20.4912C20.4 20.792 20.3 21.0927 20 21.2932C19.8 21.4937 19.5 21.594 19.2 21.594H13.2V15.5789H20.4V20.4912Z"
      fill="currentColor"
    />
  </Icon>
)

import { AddIcon as Add } from './AddIcon'
import { AddPlusIcon as AddPlus } from './AddPlusIcon'
import { AlertIcon as Alert } from './AlertIcon'
import { ArrowIcon as Arrow } from './ArrowIcon'
import { ArrowReload } from './ArrowReloadIcon'
import { BellIcon as Bell } from './BellIcon'
import { CanceledOrderIcon as CanceledOrder } from './CanceledOrderIcon'
import { CheckIcon as Check } from './CheckIcon'
import { ChevronIcon as Chevron } from './ChevronIcon'
import { CloseIcon as Close } from './CloseIcon'
import { ClothingIcon as Clothing } from './ClothingIcon'
import { CloudUploadIcon as CloudUpload } from './CloudUploadIcon'
import { ConfirmedOrderIcon as ConfirmedOrder } from './ConfirmedOrderIcon'
import { ConsultorMaisIcon as ConsultorMais } from './ConsultorMaisIcon'
import { DoneIcon as Done } from './DoneIcon'
import { GiftIcon as Gift } from './GiftIcon'
import { HeartIcon as Heart } from './HeartIcon'
import { HomeAddressIcon as HomeAddress } from './HomeAddressIcon'
import { HomeIcon as Home } from './HomeIcon'
import { InformationCicleIcon as InformationCicle } from './InformationCicleIcon'
import { KeyIcon as Key } from './KeyIcon'
import { LinkIcon as Link } from './LinkIcon'
import { LoadingIcon as Loading } from './LoadingIcon'
import { LocationIcon as Location } from './LocationIcon'
import { LockIcon as Lock } from './LockIcon'
import { LogoAppleIcon as LogoApple } from './LogoAppleIcon'
import { LogoSantanderIcon as LogoSantander } from './LogoSantanderIcon'
import { LogoStoreIcon as LogoStore } from './LogoStoreIcon'
import { LogoutIcon as Logout } from './LogoutIcon'
import { LojinhaFinanceiraLogo } from './LojinhaFinanceiraLogo'
import { MailIcon as Mail } from './MailIcon'
import { MaisVezesIcon as MaisVezes } from './MaisVezesIcon'
import { MenuVerticalIcon as MenuVertical } from './MenuVerticalIcon'
import { NewsIcon as News } from './NewsIcon'
import { OrderOnTheWayIcon as OrderOnTheWay } from './OrderOnTheWayIcon'
import { PinIcon as Pin } from './PinIcon'
import { ProfileIcon as Profile } from './ProfileIcon'
import { QuestionIcon as Question } from './QuestionIcon'
import { RechargeIcon as Recharge } from './RecargeIcon'
import { ReceiptIcon as Receipt } from './ReceiptIcon'
import { RemoveIcon as Remove } from './RemoveIcon'
import { SantanderIcon as Santander } from './SantanderIcon'
import { SearchIcon as Search } from './SearchIcon'
import { SettingsIcon as Settings } from './SettingsIcon'
import { ShoppingIcon as Shopping } from './ShoppingIcon'
import { StarIcon as Star } from './StarIcon'
import { StatementTypeIcon as StatementType } from './StatementTypeIcon'
import { StoreGradientIcon as StoreGradient } from './StoreGradientIcon'
import { StoreGradientLogo } from './StoreGradientLogo'
import { StoreIcon as Store } from './StoreIcon'
import { TrashIcon as Trash } from './TrashIcon'
import { TruckIcon as Truck } from './TruckIcon'
import { ViewIcon as View } from './ViewIcon'
import { ZoomInIcon as ZoomIn } from './ZoomInIcon'

export default {
  Add,
  AddPlus,
  Alert,
  Arrow,
  ArrowReload,
  CanceledOrder,
  Check,
  Chevron,
  Clothing,
  Close,
  CloudUpload,
  ConfirmedOrder,
  ConsultorMais,
  Done,
  Gift,
  Heart,
  HomeAddress,
  Home,
  InformationCicle,
  Key,
  Link,
  Loading,
  Location,
  Lock,
  LogoApple,
  LogoSantander,
  LogoStore,
  Logout,
  Mail,
  MaisVezes,
  MenuVertical,
  News,
  OrderOnTheWay,
  Pin,
  Profile,
  Question,
  Recharge,
  Receipt,
  Remove,
  Santander,
  Search,
  Settings,
  Shopping,
  Star,
  Store,
  StatementType,
  StoreGradient,
  LojinhaFinanceiraLogo,
  StoreGradientLogo,
  Trash,
  Truck,
  View,
  ZoomIn,
  Bell
}

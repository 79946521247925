import { AxiosError, AxiosResponse } from 'axios'
import { useQuery } from 'react-query'

import * as requests from './requests'
import { TrackOrderType } from './types'
import { ResponseError } from '../../types'

export const useGetTrackOrderQuery = (orderId: string) =>
  useQuery<AxiosResponse<TrackOrderType>, AxiosError<ResponseError>>(
    ['getTrackOrder', orderId],
    () => requests.getTrackOrder(orderId)
  )

import { FunctionComponent } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

export const ProfileIcon: FunctionComponent<IIconProps> = (props) => (
  <Icon {...{ viewBox: '0 0 24 24', ...props }}>
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.47468 0.502395C10.2754 0.170714 11.1337 0 12.0004 0C12.8671 0 13.7254 0.170714 14.5261 0.502395C15.3269 0.834076 16.0544 1.32023 16.6673 1.9331C17.2802 2.54596 17.7663 3.27354 18.098 4.07429C18.4297 4.87504 18.6004 5.73328 18.6004 6.6C18.6004 7.46672 18.4297 8.32496 18.098 9.12571C17.7663 9.92646 17.2802 10.654 16.6673 11.2669C16.0544 11.8798 15.3269 12.3659 14.5261 12.6976C13.7254 13.0293 12.8671 13.2 12.0004 13.2C11.1337 13.2 10.2754 13.0293 9.47468 12.6976C8.67393 12.3659 7.94635 11.8798 7.33349 11.2669C6.72062 10.654 6.23447 9.92646 5.90279 9.12571C5.5711 8.32496 5.40039 7.46672 5.40039 6.6C5.40039 5.73328 5.5711 4.87504 5.90279 4.07429C6.23447 3.27354 6.72062 2.54596 7.33349 1.9331C7.94635 1.32023 8.67393 0.834076 9.47468 0.502395ZM12.0004 2.4C11.4488 2.4 10.9027 2.50864 10.3931 2.71971C9.88355 2.93078 9.42055 3.24015 9.03054 3.63015C8.64054 4.02016 8.33117 4.48316 8.1201 4.99273C7.90903 5.5023 7.80039 6.04845 7.80039 6.6C7.80039 7.15155 7.90903 7.6977 8.1201 8.20727C8.33117 8.71684 8.64054 9.17984 9.03054 9.56985C9.42055 9.95986 9.88355 10.2692 10.3931 10.4803C10.9027 10.6914 11.4488 10.8 12.0004 10.8C12.5519 10.8 13.0981 10.6914 13.6077 10.4803C14.1172 10.2692 14.5802 9.95986 14.9702 9.56985C15.3602 9.17984 15.6696 8.71684 15.8807 8.20727C16.0918 7.6977 16.2004 7.15155 16.2004 6.6C16.2004 6.04845 16.0918 5.5023 15.8807 4.99273C15.6696 4.48316 15.3602 4.02016 14.9702 3.63015C14.5802 3.24015 14.1172 2.93078 13.6077 2.71971C13.0981 2.50864 12.5519 2.4 12.0004 2.4Z"
      fill="currentColor"
    />
    <Path
      key={Math.random()}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.06819 16.5959C7.09523 15.1612 9.51745 14.3907 12.0008 14.3907C14.4842 14.3907 16.9065 15.1612 18.9335 16.5959C20.9605 18.0306 22.4925 20.0589 23.3182 22.401C23.4476 22.7682 23.3909 23.1754 23.1659 23.4933C22.941 23.8111 22.5758 24 22.1864 24H1.81524C1.42587 24 1.06071 23.8111 0.835761 23.4933C0.610814 23.1754 0.554052 22.7682 0.683509 22.401C1.50918 20.0589 3.04116 18.0306 5.06819 16.5959ZM12.0008 16.7907C10.0141 16.7907 8.07635 17.4071 6.45472 18.5549C5.3153 19.3614 4.3714 20.4023 3.68166 21.6H20.32C19.6303 20.4023 18.6864 19.3614 17.547 18.5549C15.9253 17.4071 13.9876 16.7907 12.0008 16.7907Z"
      fill="currentColor"
    />
  </Icon>
)

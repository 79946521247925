export const spacing = {
  9: 36,
  11: 44,
  12: 48,
  13: 52,
  14: 56,
  15: 60,
  17: 68,
  18: 72,
  20: 80,
  23: 92,
  25: 100,
  27: 108,
  29: 116,
  30: 120,
  31: 124,
  35: 140,
  37: 148,
  39: 156,
  45: 180,
  46: 184,
  47: 188,
  50: 200,
  55: 220,
  69: 278,
  74: 296,
  84: 336,
  100: 400,
  120: 480,
  130: 520,
  160: 640,
  210: 840
}

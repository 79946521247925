import { memo } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'

const SvgComponent = (props: IIconProps) => (
  <Icon {...props}>
    <Path
      fill="#409565"
      d="M14.55 5.4v-2c0-.63-.26-1.25-.7-1.7-.45-.45-1.06-.7-1.7-.7h-9.6c-.64 0-1.24.25-1.7.7-.45.45-.7 1.06-.7 1.7v5.35c0 .44.36.8.8.8.44 0 .8-.36.8-.8V3.4c0-.21.08-.42.23-.57.15-.15.35-.23.57-.23h9.6c.21 0 .42.08.57.23.15.15.23.36.23.57v2h-2c-.88 0-1.6.72-1.6 1.6v3.2c0 .88.72 1.6 1.6 1.6h2V13c0 .21-.09.42-.23.57a.81.81 0 0 1-.57.23H7.98c-.44 0-.8.36-.8.8 0 .44.36.8.8.8h4.17c.63 0 1.25-.26 1.7-.7.45-.45.7-1.06.7-1.7v-1.2c.88 0 1.6-.72 1.6-1.6V7c0-.88-.72-1.6-1.6-1.6Zm0 4.8h-3.6V7h3.6v3.2Z"
    />
    <Path
      fill="#409565"
      d="M5.92 12.953c.15-.14.23-.34.23-.55 0-.21-.09-.42-.23-.57l-2.4-2.4a.803.803 0 0 0-1.13 0c-.16.16-.23.36-.23.57 0 .21.08.41.23.57l1.03 1.03H.8c-.44 0-.8.36-.8.8 0 .44.36.8.8.8h2.62l-1.03 1.04c-.31.31-.31.82 0 1.13.31.31.82.31 1.13 0l2.4-2.4v-.02Z"
    />
  </Icon>
)

export const RechargeIcon = memo(SvgComponent)

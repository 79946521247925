import { memo } from 'react'

import { Icon, IIconProps } from 'native-base'
import { Path } from 'react-native-svg'
import { theme } from 'src/theme'

const SvgComponent = (props: IIconProps) => (
  <Icon fill="none" {...props}>
    <Path
      fill={theme.colors.red[500]}
      fillRule="evenodd"
      d="M2.472 4.667h11.056a2.476 2.476 0 0 1 2.382 1.811c.096.345.115.708.057 1.061v.004l-1.069 6.391A2.473 2.473 0 0 1 12.467 16H8a1 1 0 1 1 0-2h4.461a.473.473 0 0 0 .464-.395l1.069-6.389v-.001a.472.472 0 0 0-.467-.548H2.473a.473.473 0 0 0-.467.548l.33 1.977a1 1 0 0 1-1.972.33L.032 7.538a2.473 2.473 0 0 1 2.44-2.872Z"
      clipRule="evenodd"
    />
    <Path
      fill={theme.colors.red[500]}
      fillRule="evenodd"
      d="M8 2a2.89 2.89 0 0 0-2.89 2.89v.777a1 1 0 0 1-2 0v-.778a4.889 4.889 0 0 1 9.779 0v.778a1 1 0 0 1-2 0v-.778A2.89 2.89 0 0 0 7.999 2ZM1.293 11.292a1 1 0 0 1 1.414 0l3 3a1 1 0 1 1-1.414 1.414l-3-3a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
    <Path
      fill={theme.colors.red[500]}
      fillRule="evenodd"
      d="M5.707 11.292a1 1 0 0 1 0 1.414l-3 3a1 1 0 1 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0Z"
      clipRule="evenodd"
    />
  </Icon>
)

export const CanceledOrderIcon = memo(SvgComponent)

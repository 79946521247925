import { forwardRef, useImperativeHandle, useState } from 'react'

import { Icons } from 'atoms'
import { Box, useBreakpointValue } from 'native-base'
import { Dimensions, ImageBackground } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'

import { RIGHT_BANNER_WIDTH, Slide, slides } from '../useOnboardingScreen'

export type AnimatedDesktopRightBannerHandle = {
  changeSlide(slide: Slide | null): void
}

export const AnimatedDesktopRightBanner = forwardRef<AnimatedDesktopRightBannerHandle>((_, ref) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const height = Dimensions.get('window').height

  const bannerMinHeight = height < 1024 ? '1024px' : `${height}px`

  const [slide, setSlide] = useState<Slide | null>(slides[0])

  useImperativeHandle(ref, () => ({
    changeSlide(newSlide) {
      setSlide(newSlide)
    }
  }))

  if (isMobile) {
    return null
  }

  return (
    <Box flex={1} maxW={`${RIGHT_BANNER_WIDTH}px`} position="relative">
      {!!slide && (
        <>
          <Animated.View entering={FadeIn} exiting={FadeOut} key={slide.id} style={{ flex: 1 }}>
            <ImageBackground
              source={slide.imageDesktop}
              style={{
                minHeight: bannerMinHeight,
                width: RIGHT_BANNER_WIDTH,
                height: '100%',
                position: 'absolute',
                top: 0
              }}
              imageStyle={{
                resizeMode: 'cover',
                alignSelf: 'flex-end'
              }}
            />
          </Animated.View>
          <Box top={15} position="absolute" left={15}>
            <Icons.LogoStore size={136} />
          </Box>
        </>
      )}
    </Box>
  )
})

import { useBreakpointValue } from 'native-base'

import { UseDisconnectedScreen } from './DisconnectedScreen.types'

export const useDisconnectedScreen: UseDisconnectedScreen = ({ navigation }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  const handleGoBack = () => navigation.goBack()

  return {
    isMobile,
    handleGoBack
  }
}

import { useLinkTo } from '@react-navigation/native'
import { useGetAllBannerImagesQuery } from 'integration/resources/banner-images'
import { useGetNotificationsCountQuery } from 'integration/resources/notifications'
import { useGetAllProductsQuery, useGetFeaturedProductsQuery } from 'integration/resources/products'
import { useGetProfileQuery } from 'integration/resources/profile'
import { useBreakpointValue } from 'native-base'

import { UseHomeScreen } from './HomeScreen.types'

export const useHomeScreen: UseHomeScreen = () => {
  const profile = useGetProfileQuery()

  const featuredProducts = useGetFeaturedProductsQuery()

  const allProducts = useGetAllProductsQuery()

  const allBannerImages = useGetAllBannerImagesQuery()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const notificationsCount = useGetNotificationsCountQuery()

  const linkTo = useLinkTo()

  const handleOpenNotifications = () => linkTo('/notificacoes')

  return {
    allProducts: allProducts.data?.data,
    allBannerImages: allBannerImages.data?.data,
    allProductsLoading: allProducts.isLoading,
    featuredProducts: featuredProducts.data?.data,
    featuredProductsLoading: featuredProducts.isLoading,
    isMobile,
    notificationsCount: notificationsCount?.data?.data,
    profile: profile.data?.data,
    profileLoading: profile?.isLoading,
    handleOpenNotifications
  }
}

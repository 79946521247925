import axios from 'axios'

import { CEP } from './types'

const client = axios.create({
  baseURL: 'https://viacep.com.br/ws',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const getByCEP = async (cep: string) => client.get<CEP>(`/${cep}/json/`)
